<template>
  <modal-popup>
    <template #default="{ modalPopupId }">
      <div class="modal-view">
        <!-- Headings -->
        <p class="heading-one mt-5">Remember to change to a new password!</p>
        <p class="main-text--light mb-5">
          You can change your password by visiting your profile page!
        </p>

        <!-- Buttons -->
        <button
          type="button"
          class="button-primary mb-3 me-3"
          @click="onChangePassword(modalPopupId)">
          Change Now
        </button>

        <button type="button" class="button-outline" :data-close="modalPopupId">
          Maybe Later
        </button>
      </div>
    </template>
  </modal-popup>
</template>

<script>
import { closeModalPopup } from '@/services/utils/modal.js'
import ModalPopup from '@/components/ModalPopup.vue'

export default {
  name: 'ChangePasswordModal',

  components: {
    ModalPopup
  },

  methods: {
    onChangePassword(modalPopupId) {
      this.$router.push({
        name: 'UserProfile',
        params: { destinationTab: 'Settings' }
      })
      closeModalPopup(modalPopupId)
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/components/_modal.scss';
</style>
