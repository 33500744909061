<template>
  <modal-form
    :modalPopupId="modalPopupId"
    heading="Filter Tasks"
    @closeModalForm="onResetFilters">
    <template #form>
      <form class="form" novalidate>
        <div class="container-fluid">
          <!-- Department select menu -->
          <div class="row mb-3 mt-1">
            <div class="col-12 col-md-2">
              <p class="main-text my-2">Department:</p>
            </div>
            <div class="col-12 col-md-10">
              <select class="form-select select" v-model="departmentFilter">
                <option
                  v-for="option in departments"
                  :value="option"
                  :key="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>

          <!-- Project select menu -->
          <div class="row mb-3 mt-1">
            <div class="col-12 col-md-2">
              <p class="main-text my-2">Project:</p>
            </div>
            <div class="col-12 col-md-10">
              <select class="form-select select" v-model="projectFilter">
                <option
                  v-for="option in projects"
                  :value="option.name"
                  :key="option.name">
                  {{ option.name }} - {{ option.count }}
                </option>
              </select>
            </div>
          </div>

          <!-- Assignee select menu -->
          <div class="row mb-3">
            <div class="col-12 col-md-2">
              <p class="main-text my-2">Assignee:</p>
            </div>
            <div class="col-12 col-md-10">
              <select class="form-select select" v-model="assigneeFilter">
                <option
                  v-for="option in assignees"
                  :value="option.name"
                  :key="option.name">
                  {{ option.name }} - {{ option.count }}
                </option>
              </select>
            </div>
          </div>

          <!-- Status select menu -->
          <div class="row mb-3">
            <div class="col-12 col-md-2">
              <p class="main-text my-2">Status:</p>
            </div>
            <div class="col-12 col-md-4">
              <select class="form-select select" v-model="statusFilter">
                <option
                  v-for="option in statuses"
                  :value="option.name"
                  :key="option.name">
                  {{ option.name }} - {{ option.count }}
                </option>
              </select>
            </div>
          </div>

          <!-- Start and end date inputs -->
          <div v-show="!isCalendarView" class="row">
            <div class="col-12 col-md-2">
              <p class="main-text my-2">Start date:</p>
            </div>
            <div class="col-12 col-md-4">
              <input
                type="date"
                class="form-control input"
                id="filter__start-date"
                v-model="startDateFilter" />
            </div>

            <div class="col-12 col-md-2">
              <p class="main-text my-2">End date:</p>
            </div>
            <div class="col-12 col-md-4">
              <input
                type="date"
                class="form-control input"
                id="filter__end-date"
                v-model="endDateFilter" />
            </div>
          </div>

          <p v-if="formErrorMessage" class="error-message mt-2">
            {{ formErrorMessage }}
          </p>

          <!-- Apply and clear buttons -->
          <div class="mb-2 mt-4">
            <button
              type="button"
              class="button-primary mb-2 me-3"
              @click="onApplyFilters">
              Apply
            </button>

            <button
              type="button"
              class="button-outline"
              @click="onClearFilters">
              Clear
            </button>
          </div>
        </div>
      </form>
    </template>
  </modal-form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { formError } from '@/services/message/rpm.js'
import { isStartDatetimeBeforeEndDatetime } from '@/services/utils/date.js'
import {
  handleInvalidFormElement,
  handleValidFormElement
} from '@/services/utils/form.js'
import { closeModalPopup } from '@/services/utils/modal.js'
import ModalForm from '@/components/ModalForm.vue'

export default {
  name: 'FilterTasksModal',

  components: {
    ModalForm
  },

  props: {
    modalPopupId: {
      type: String,
      require: true
    },

    isCalendarView: {
      // If calendar view, hide date filters
      type: Boolean,
      require: true
    },

    departments: {
      type: Array,
      require: true
    },

    projects: {
      type: Array,
      require: true
    },

    assignees: {
      type: Array,
      require: true
    },

    statuses: {
      type: Array,
      require: true
    }
  },

  emits: ['applyFilters'],

  data() {
    return {
      departmentFilter: '',
      projectFilter: '',
      assigneeFilter: '',
      statusFilter: '',
      startDateFilter: '',
      endDateFilter: '',
      areValidDates: true,
      formErrorMessage: '',
      formError: formError
    }
  },

  computed: {
    ...mapGetters({
      tasksFilters: 'rpm/getTasksFilters'
    })
  },

  watch: {
    departmentFilter() {
      this.onSetFilters()
    },

    projectFilter() {
      this.onSetFilters()
    },

    assigneeFilter() {
      this.onSetFilters()
    },

    statusFilter() {
      this.onSetFilters()
    },

    startDateFilter() {
      if (!this.isCalendarView) {
        // Avoid emitting applyFilters event too many times
        // if TaskList shows calendar view (since calendar view
        // will always set start date and also end date filter)
        this.onSetFilters()
      }
    },

    endDateFilter() {
      if (
        (this.isCalendarView && this.endDateFilter !== '') ||
        !this.isCalendarView
      ) {
        // Avoid emitting applyFilters event too many times
        // if TaskList shows calendar view (since calendar view
        // will always set start date and also end date filter)
        this.onSetFilters()
      }
    }
  },

  mounted() {
    this.onResetFilters()
  },

  methods: {
    ...mapMutations('rpm', ['setTasksFilters']),

    resetDateInputsValidation() {
      // Reset filter error message and form validation
      this.areValidDates = true
      this.formErrorMessage = ''
      handleValidFormElement(document.getElementById('filter__start-date'))
      handleValidFormElement(document.getElementById('filter__end-date'))
    },

    onResetFilters() {
      // Reset filters to original values when user clicks on cross icon
      this.resetDateInputsValidation()
      this.departmentFilter = this.tasksFilters[0]
      this.projectFilter = this.tasksFilters[1]
      this.assigneeFilter = this.tasksFilters[2]
      this.statusFilter = this.tasksFilters[3]
      this.startDateFilter = this.tasksFilters[4]
      this.endDateFilter = this.tasksFilters[5]
      this.onSetFilters()
    },

    onSetFilters() {
      // Validate start and end dates
      this.resetDateInputsValidation()
      const startDateInputElement =
        document.getElementById('filter__start-date')
      const endDateInputElement = document.getElementById('filter__end-date')
      if (
        this.startDateFilter !== '' &&
        this.endDateFilter !== '' &&
        !isStartDatetimeBeforeEndDatetime(
          this.startDateFilter,
          '00:00',
          this.endDateFilter,
          '23:59'
        )
      ) {
        // Start date is after end date
        this.areValidDates = false
        handleInvalidFormElement(startDateInputElement)
        handleInvalidFormElement(endDateInputElement)
        this.formErrorMessage = formError.invalidStartAndEndDate
        return
      }

      // Save filters in Vuex RPM store
      this.setTasksFilters([
        this.departmentFilter,
        this.projectFilter,
        this.assigneeFilter,
        this.statusFilter,
        this.startDateFilter,
        this.endDateFilter
      ])

      // Emit apply filters event to TaskList component
      this.$emit('applyFilters')
    },

    onApplyFilters() {
      if (this.areValidDates) {
        closeModalPopup(this.modalPopupId)
      }
    },

    onClearFilters() {
      this.departmentFilter = ''
      this.projectFilter = ''
      this.assigneeFilter = ''
      this.statusFilter = ''
      this.startDateFilter = ''
      this.endDateFilter = ''
      this.resetDateInputsValidation()
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_rpm.scss';
</style>
