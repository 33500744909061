<template>
  <spinner />

  <!-- Heading -->
  <p class="back-nav main-heading mb-4" @click="onNavigateBackward">
    &lt; Meeting Logs
  </p>

  <!-- List logs error message -->
  <p v-if="listLogsErrorMessage" class="error-message">
    {{ listLogsErrorMessage }}
  </p>

  <!-- Meeting logs table -->
  <div v-if="!listLogsErrorMessage" class="logs scroll-view">
    <selectable-table
      :items="meetingLogs"
      @rowSelectedChange="onSelectMeetingLogs">
      <!-- Meeting logs table - Header row -->
      <template #header>
        <th class="logs-table__hide-col--narrow">Room name</th>
        <th class="logs-table__hide-col--narrow">Meeting name</th>
        <th class="logs-table__show-col--narrow">Name</th>
        <th>Date</th>
        <th>Time</th>
        <th class="logs-table__hide-col">Duration</th>
        <th class="logs-table__hide-col">Meeting Participants</th>
      </template>

      <!-- Meeting logs table - Meeting log row -->
      <template #item="{ item: meetingLog }">
        <!-- Meeting logs table - Meeting log row - Room and Meeting name -->
        <td class="logs-table__hide-col--narrow">{{ meetingLog.name }}</td>
        <td class="logs-table__hide-col--narrow">
          {{ meetingLog.meetingName }}
        </td>
        <td class="logs-table__show-col--narrow">
          <p class="main-text--light mb-0">Room:</p>
          <p class="mb-0">{{ meetingLog.name }}</p>
          <p v-if="meetingLog.meetingName" class="main-text--light mb-0 mt-2">
            Meeting:
          </p>
          <p v-if="meetingLog.meetingName" class="mb-0">
            {{ meetingLog.meetingName }}
          </p>
        </td>

        <!-- Meeting logs table - Meeting log row - Date, Time and Duration -->
        <td>{{ formatDate(meetingLog.startTime, meetingLog.endTime) }}</td>
        <td>{{ formatTime(meetingLog.startTime, meetingLog.endTime) }}</td>
        <td class="logs-table__hide-col">
          {{ formatDuration(meetingLog.duration) }}
        </td>

        <!-- Meeting logs table - Meeting log row - Participants -->
        <td class="logs-table__hide-col">
          <div
            v-for="participant in meetingLog.participants"
            :key="participant">
            <p v-if="participant.participantEmail" class="mb-0">
              {{ participant.participantEmail }}
            </p>
          </div>

          <p
            v-if="hasGuest(meetingLog.participants)"
            class="main-text--light mb-0 mt-2">
            Guest:
          </p>

          <div
            v-for="participant in meetingLog.participants"
            :key="participant">
            <p v-if="participant.participantID === 'Guest'" class="mb-0">
              {{ participant.participantName }}
            </p>
          </div>
        </td>
      </template>
    </selectable-table>
  </div>
</template>

<script>
import { getMeetingLogs } from '@/services/api/we-meet.js'
import {
  weMeetMessage,
  getNoRoomLogMessage
} from '@/services/message/we-meet.js'
import {
  getDateLocaleStr,
  getTimeLocaleStr,
  getDuration
} from '@/services/utils/date.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import SelectableTable from '@/components/SelectableTable.vue'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'MeetingLogs',

  components: {
    SelectableTable,
    Spinner
  },

  props: {
    roomId: {
      type: String,
      require: false
    },

    roomName: {
      type: String,
      require: false
    }
  },

  data() {
    return {
      meetingLogs: [],
      meetingLogsSelectedFlags: [],
      numOfSelectedMeetingLogs: 0,
      listLogsErrorMessage: ''
    }
  },

  async mounted() {
    // Reset list logs error message
    this.listLogsErrorMessage = ''
    try {
      // Call get meeting logs API
      showSpinner()
      const res = await getMeetingLogs(this.roomId)
      hideSpinner()
      this.meetingLogs = res.data.roomLogs

      // Check for no meeting log
      if (!this.meetingLogs) {
        this.listLogsErrorMessage = this.roomId
          ? getNoRoomLogMessage(this.roomName)
          : weMeetMessage.noLog
      }
    } catch (e) {
      hideSpinner()
      this.listLogsErrorMessage = e.response.data.message
    }
  },

  methods: {
    onNavigateBackward() {
      this.$router.push({ name: 'WeMeetLobby' })
    },

    onSelectMeetingLogs() {
      // TODO
    },

    formatDate(startDateStr, endDateStr) {
      const startDate = getDateLocaleStr(startDateStr)
      var endDate
      if (endDateStr === undefined) {
        endDate = 'Ongoing'
      } else {
        endDate = getDateLocaleStr(endDateStr)
      }
      return startDate === endDate ? startDate : startDate + ' - ' + endDate
    },

    formatTime(startDateStr, endDateStr) {
      const startTime = getTimeLocaleStr(startDateStr)
      var endTime
      if (endDateStr === undefined) {
        endTime = 'Ongoing'
      } else {
        endTime = getTimeLocaleStr(endDateStr)
      }
      return startTime + ' - ' + endTime
    },

    formatDuration(durationArr) {
      return getDuration(durationArr)
    },

    hasGuest(participantsArr) {
      for (var i = 0; i < participantsArr.length; i++) {
        if (participantsArr[i].participantID === 'Guest') {
          return true
        }
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
