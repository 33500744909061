// RPM messages

const formError = {
  emptyAssignee: 'Please select an assignee.',
  emptyDepartment: 'Please select a department.',
  emptyProject: 'Please select a project.',
  invalidStartAndEndDate: 'The end date has to be after the start date.'
}

export { formError }
