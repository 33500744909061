const state = () => ({
  applications: []
})

const getters = {
  // Get names of applications (excluding dashboard application)
  getNonDashboardApplicationNames: (state) => {
    return state.applications
      .filter((application, index) => index !== 0)
      .map((application) => application.name)
  },

  // Get accessible applications (excluding dashboard application)
  getAccessibleNonDashboardApplications: (state) => {
    return state.applications.filter(
      (application, index) => index != 0 && application.isAccessible
    )
  },

  // Get accessible applications (including dashboard application)
  getAccessibleApplications: (state) => {
    return state.applications.filter((application) => application.isAccessible)
  },

  // Get names of inaccessible applications
  getInaccessibleApplicationNames: (state) => {
    return state.applications
      .filter((application) => !application.isAccessible)
      .map((application) => application.name)
  }
}

const mutations = {
  // Array of applications' details
  // !!! NOTE: DASHBOARD APPLICATION IS ALWAYS FIRST.
  // !!! NOTE: OTHER APPLICATIONS ARE ARRANGED ALPHABETICALLY.
  setInitialApplications(state) {
    state.applications = [
      {
        name: 'Dashboard',
        routeName: 'Dashboard',
        isAccessible: true,
        icon: 'dashboard.svg',
        whiteIcon: 'dashboard-white.svg',
        dashboardIllustration: '',
        description: ''
      },
      {
        name: 'Admin',
        routeName: 'Admin',
        isAccessible: false,
        icon: 'admin.svg',
        whiteIcon: 'admin-white.svg',
        dashboardIllustration: 'admin-dashboard-illustration.svg',
        description: 'Manage users'
      },
      {
        name: 'RPM',
        routeName: 'RPM',
        isAccessible: false,
        icon: 'we-plan.svg',
        whiteIcon: 'we-plan-white.svg',
        dashboardIllustration: 'we-plan-dashboard-illustration.svg',
        description: 'Resource planning'
      },
      {
        name: 'weLEARN',
        routeName: 'WeLearn',
        isAccessible: false,
        icon: 'we-learn.svg',
        whiteIcon: 'we-learn-white.svg',
        dashboardIllustration: 'we-learn-dashboard-illustration.svg',
        description: 'e-Learning'
      },
      {
        name: 'weMEET',
        routeName: 'WeMeet',
        isAccessible: false,
        icon: 'we-meet.svg',
        whiteIcon: 'we-meet-white.svg',
        dashboardIllustration: 'we-meet-dashboard-illustration.svg',
        description: 'Video conferencing'
      },
      {
        name: 'wePLAN',
        routeName: 'WePlan',
        isAccessible: false,
        icon: 'we-plan.svg',
        whiteIcon: 'we-plan-white.svg',
        dashboardIllustration: 'we-plan-dashboard-illustration.svg',
        description: 'Project planning'
      },
      {
        name: 'weSHARE',
        routeName: 'WeShare',
        isAccessible: false,
        icon: 'we-share.svg',
        whiteIcon: 'we-share-white.svg',
        dashboardIllustration: 'we-share-dashboard-illustration.svg',
        description: 'Collaboration tool'
      }
    ]
  },

  toggleApplicationAccessibility(state, accessibilityArr) {
    // Order of application accessibility follows array of applications in `setInitialApplications` mutation
    for (var i = 0; i < state.applications.length; i++) {
      state.applications[i].isAccessible = accessibilityArr[i]
    }
  },

  clearApplicationStore(state) {
    state.applications = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
