<script>
import router from '@/router'
import store from '@/store/index.js'

export default {
  name: 'Logout',

  beforeRouteEnter() {
    // Clear all Vuex stores
    store.commit('clearStore')

    // Redirect to login page
    router.push({ name: 'Login' })
  }
}
</script>
