<template>
  <div class="d-flex justify-content-center">
    <p class="year__prev d-inline-block" @click="getPrevYear">&lt;</p>
    <p class="main-text mb-0">{{ year }}</p>
    <p class="year__next d-inline-block" @click="getNextYear">&gt;</p>
  </div>
</template>

<script>
export default {
  name: 'YearToggle',

  data() {
    return {
      year: ''
    }
  },

  watch: {
    year(newYear) {
      this.$emit('changeYear', newYear)
    }
  },

  mounted() {
    this.year = new Date().getFullYear() // current year
  },

  methods: {
    getPrevYear() {
      this.year--
    },

    getNextYear() {
      this.year++
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/components/_year-toggle.scss';
</style>
