// Admin messages

const adminMessage = {
  noUser: 'There is no user in 8eyond suite.'
}

const userInputError = {
  invalidFirstName:
    'The first name should contain only alphabetical letters with a maximum of one space between words.',
  invalidLastName:
    'The last name should contain only alphabetical letters with a maximum of one space between words.',
  invalidUsername:
    'The username needs to be at least 5 characters long and contain only lowercase letters.',
  invalidPassword:
    'The password needs to be at least 8 characters long and contain at least one uppercase character, one lowercase character, one number and one special symbol.',
  invalidEmail: 'Please provide a valid email.',
  differentPasswords: 'Please ensure that both passwords are the same.'
}

export { adminMessage, userInputError }
