<template>
  <spinner />

  <div class="assignee-data scroll-view">
    <!-- Header -->
    <p class="back-nav main-heading" @click="onBackNav">
      &lt; Data for {{ assignee }}
    </p>

    <!-- Get assignee data error message -->
    <p v-if="getAssigneeDataErrorMessage" class="error-message">
      {{ getAssigneeDataErrorMessage }}
    </p>

    <!-- Year toggle -->
    <year-toggle class="mb-2" @changeYear="onChangeYearOfAssigneeData" />

    <!-- Forecast and utilisation line chart -->
    <p class="heading-one mb-2">Forecast and Utilisation</p>
    <Line
      v-if="areChartsLoaded"
      class="mb-4"
      :chart-data="forecastAndUtilisationChartData"
      :chart-options="forecastAndUtilisationChartOptions" />

    <!-- Estimated and actual times bar chart -->
    <p class="heading-one mb-2">Estimated &amp; Actual times</p>
    <Bar
      v-if="areChartsLoaded"
      class="mb-2"
      :chart-data="estimatedAndActualTimesChartData"
      :chart-options="estimatedAndActualTimesChartOptions" />
  </div>
</template>

<script>
import { Bar, Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { getAssigneeData } from '@/services/api/rpm.js'
import { months } from '@/services/utils/date.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import Spinner from '@/components/Spinner.vue'
import YearToggle from '@/components/YearToggle.vue'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale
)

export default {
  name: 'AssigneeData',

  components: {
    Bar,
    Line,
    Spinner,
    YearToggle
  },

  props: {
    assignee: {
      type: String,
      require: true
    }
  },

  data() {
    return {
      assigneeData: null,
      yearOfAssigneeData: '',

      forecastAndUtilisationChartData: {},
      forecastAndUtilisationChartOptions: {},
      estimatedAndActualTimesChartData: {},
      estimatedAndActualTimesChartOptions: {},

      areChartsLoaded: false,
      months: months,
      getAssigneeDataErrorMessage: ''
    }
  },

  async mounted() {
    // Initialise charts options
    this.forecastAndUtilisationChartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        y: {
          min: 0,
          ticks: {
            callback: function (value) {
              return value + '%'
            }
          }
        }
      }
    }
    this.estimatedAndActualTimesChartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        y: {
          ticks: {
            stepSize: 5
          }
        }
      }
    }

    // Reset get assignee data error message
    this.getAssigneeDataErrorMessage = ''

    try {
      // Call get assignee data API
      showSpinner()
      const res = await getAssigneeData(this.assignee)
      this.assigneeData = res.data.result[0].jobTasks
      this.populateChartsData()

      // Set loaded status to true
      this.areChartsLoaded = true
      hideSpinner()
    } catch (e) {
      hideSpinner()
      this.getAssigneeDataErrorMessage = e.response.data.message
    }
  },

  methods: {
    onBackNav() {
      this.$router.push({ name: 'RPM' })
    },

    populateChartsData() {
      var forecasts = []
      var utilisations = []
      var estimatedTimes = []
      var actualTimes = []

      // Extract data
      var labels = months.map((m) => m.shortName)
      var loopMonths = months.map((m) => this.yearOfAssigneeData + '-' + m.num)
      loopMonths.forEach((month) => {
        const monthlyTasks = this.assigneeData[month]
        if (monthlyTasks) {
          forecasts.push(monthlyTasks.forecastUtilization)
          utilisations.push(monthlyTasks.utilization)

          // 3600 seconds = 1 hour, 8.5 hours = 1 working day
          // Round calculations to 1 dp
          const estimatedTime = monthlyTasks.estimatedTimeTotal / 3600 / 8.5
          const actualTime = monthlyTasks.spentTimeTotal / 3600 / 8.5
          estimatedTimes.push(Math.round(estimatedTime * 10) / 10)
          actualTimes.push(Math.round(actualTime * 10) / 10)
        } else {
          forecasts.push(0)
          utilisations.push(0)
          estimatedTimes.push(0)
          actualTimes.push(0)
        }
      })

      // Populate forecast and utilisation line chart
      this.forecastAndUtilisationChartData = {
        labels: labels,
        datasets: [
          {
            label: 'Forecast (%)',
            data: forecasts,
            borderColor: 'springgreen',
            tension: 0.3
          },
          {
            label: 'Utilisation (%)',
            data: utilisations,
            borderColor: 'seagreen',
            tension: 0.3
          }
        ]
      }

      // Populate estimated and actual times bar chart
      this.estimatedAndActualTimesChartData = {
        labels: labels,
        datasets: [
          {
            label: 'Estimated time (day)',
            data: estimatedTimes,
            backgroundColor: 'skyblue'
          },
          {
            label: 'Actual time (day)',
            data: actualTimes,
            backgroundColor: 'slateblue'
          }
        ]
      }
    },

    onChangeYearOfAssigneeData(year) {
      this.yearOfAssigneeData = year
      if (this.areChartsLoaded) {
        this.populateChartsData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_rpm.scss';
</style>
