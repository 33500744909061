<template>
  <spinner />

  <!-- Toast - Copy recording URL -->
  <toast-popup toastId="copyUrlToast" :toastMessage="toastMessage.copied" />

  <!-- Toast - Update recording visibility error message -->
  <toast-popup
    toastId="updateVisibilityToast"
    :toastMessage="updateVisibilityErrorMessage" />

  <!-- Heading -->
  <p class="back-nav main-heading mb-4" @click="onNavigateBackward">
    &lt; Saved Recordings
  </p>

  <div class="rooms-recordings scroll-view">
    <!-- List recordings error message -->
    <p v-if="listRecordingsErrorMessage" class="error-message">
      {{ listRecordingsErrorMessage }}
    </p>

    <div v-if="!listRecordingsErrorMessage">
      <div
        v-for="roomRecordings in roomsRecordings"
        :key="roomRecordings.roomName">
        <!-- Room recordings -->
        <div
          v-if="roomRecordings.recordingDetails.length"
          class="room-recordings mb-4 p-3">
          <!-- Room recordings - Room name -->
          <p class="heading-two">{{ roomRecordings.roomName }}</p>

          <div class="container-fluid">
            <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-3">
              <div
                v-for="meetingRecording in roomRecordings.recordingDetails"
                class="col"
                :key="meetingRecording.meetingLogDatabaseID">
                <!-- Room recordings - Meeting recording card -->
                <recording
                  :recording="meetingRecording"
                  @displayCopyUrlToast="onDisplayCopyUrlToast"
                  @displayUpdateVisibilityToast="
                    onDisplayUpdateVisibilityToast
                  " />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'bootstrap'
import { getMeetingRecordings } from '@/services/api/we-meet.js'
import { toastMessage } from '@/services/message/general.js'
import {
  weMeetMessage,
  getNoRoomRecordingMessage
} from '@/services/message/we-meet.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import Recording from '@/views/we-meet/cards/Recording.vue'
import Spinner from '@/components/Spinner.vue'
import ToastPopup from '@/components/ToastPopup.vue'

export default {
  name: 'MeetingRecordings',

  components: {
    Recording,
    Spinner,
    ToastPopup
  },

  props: {
    roomId: {
      type: String,
      require: false
    },

    roomName: {
      type: String,
      require: false
    }
  },

  data() {
    return {
      roomsRecordings: [],
      listRecordingsErrorMessage: '',
      updateVisibilityErrorMessage: '',
      toastMessage: toastMessage,
      weMeetMessage: weMeetMessage
    }
  },

  async mounted() {
    await this.getRecordings()
  },

  methods: {
    async getRecordings() {
      // Reset list recordings error message
      this.listRecordingsErrorMessage = ''

      try {
        // Call get meeting recordings API
        showSpinner()
        const res = await getMeetingRecordings(this.roomId)
        hideSpinner()
        this.roomsRecordings = res.data.recordingsArray

        // Check for no recording
        if (!this.roomsRecordings) {
          this.listRecordingsErrorMessage = this.roomId
            ? getNoRoomRecordingMessage(this.roomName)
            : weMeetMessage.noRecording
        }
      } catch (e) {
        hideSpinner()
        this.listRecordingsErrorMessage = e.response.data.message
      }
    },

    onDisplayCopyUrlToast() {
      var toast = new Toast(document.getElementById('copyUrlToast'))
      toast.show()
    },

    onDisplayUpdateVisibilityToast(errorMessage) {
      this.updateVisibilityErrorMessage = errorMessage
      var toast = new Toast(document.getElementById('updateVisibilityToast'))
      toast.show()
    },

    onNavigateBackward() {
      this.$router.push({ name: 'WeMeetLobby' })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
