<template>
  <spinner />

  <div class="container-fluid px-3">
    <div class="row vh-100">
      <div class="forget-password__form-col col-12 col-lg-6 m-auto">
        <!-- Headings -->
        <p class="forget-password__header main-heading m-0">
          Forgot your password?
        </p>
        <p class="main-text--light mb-5">
          Enter your registered email below and we will send you a temporary
          password.
        </p>

        <!-- Form -->
        <form @submit.prevent="onForgetPassword">
          <!-- Form - Email input -->
          <div class="mb-3">
            <input
              type="email"
              class="form-control input"
              v-model="email"
              placeholder="Email" />
          </div>

          <!-- Form - Input error message -->
          <p v-if="inputErrorMessage" class="error-message mt-3">
            {{ inputErrorMessage }}
          </p>

          <!-- Form - Submit and cancel buttons -->
          <button type="submit" class="button-primary mb-3 me-3 mt-5">
            Submit
          </button>
          <button type="button" @click="onCancel" class="button-outline">
            Cancel
          </button>
        </form>
      </div>

      <div class="col-lg-6" />
    </div>
  </div>
</template>

<script>
import { forgetPassword } from '@/services/api/auth.js'
import { inputError } from '@/services/message/auth.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'ForgetPassword',

  components: {
    Spinner
  },

  data() {
    return {
      email: '',
      inputErrorMessage: '',
      inputError: inputError
    }
  },

  methods: {
    async onForgetPassword() {
      // Reset input error message
      this.inputErrorMessage = ''

      // Input error message: Empty email
      if (!this.email) {
        this.inputErrorMessage = inputError.emptyEmail
        return
      }

      try {
        // Call forget password API
        showSpinner()
        await forgetPassword(this.email)
        hideSpinner()

        // Redirect to enter password page
        this.$router.push({
          name: 'EnterPassword',
          params: { email: this.email }
        })
      } catch (e) {
        hideSpinner()
        this.inputErrorMessage = e.response.data.message
      }
    },

    onCancel() {
      this.$router.replace({ name: 'Login' })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_auth.scss';
</style>
