import { createRouter, createWebHistory } from 'vue-router'
import { getCurrentUser } from '@/services/api/admin.js'
import store from '@/store/index.js'

import Login from '@/views/auth/Login.vue'
import ForgetPassword from '@/views/auth/ForgetPassword.vue'
import EnterPassword from '@/views/auth/EnterPassword.vue'
import Logout from '@/views/auth/Logout.vue'

import Dashboard from '@/views/user/Dashboard.vue'
import UserProfile from '@/views/user/UserProfile.vue'

import Admin from '@/views/admin/Admin.vue'
import UserList from '@/views/admin/UserList.vue'
import UserCreate from '@/views/admin/UserCreate.vue'
import UserUpdate from '@/views/admin/UserUpdate.vue'

import RPM from '@/views/rpm/RPM.vue'
import RPMDashboard from '@/views/rpm/RPMDashboard.vue'
import AssigneeData from '@/views/rpm/AssigneeData.vue'
import TaskList from '@/views/rpm/TaskList.vue'

import WeLearn from '@/views/we-learn/WeLearn.vue'

import WeMeet from '@/views/we-meet/WeMeet.vue'
import WeMeetLobby from '@/views/we-meet/WeMeetLobby.vue'
import MeetingLogs from '@/views/we-meet/MeetingLogs.vue'
import MeetingRecordings from '@/views/we-meet/MeetingRecordings.vue'
import MeetingInvite from '@/views/we-meet/MeetingInvite.vue'
import MeetingLobby from '@/views/we-meet/MeetingLobby.vue'

import WePlan from '@/views/we-plan/WePlan.vue'
import WeShare from '@/views/we-share/WeShare.vue'

import NotAuthorized from '@/views/error/NotAuthorized.vue'
import NotFound from '@/views/error/NotFound.vue'

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Login'
    }
  },

  // Authentication routes
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: ForgetPassword,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/enter-password',
    name: 'EnterPassword',
    component: EnterPassword,
    props: true,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: {
      requiresAuth: false
    }
  },

  // User routes
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: UserProfile,
    props: true,
    meta: {
      requiresAuth: true
    }
  },

  // Admin routes
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    redirect: {
      name: 'UserList'
    },
    children: [
      {
        path: '',
        name: 'UserList',
        component: UserList
      },
      {
        path: 'user-create',
        name: 'UserCreate',
        component: UserCreate
      },
      {
        path: 'user-update/:userId',
        name: 'UserUpdate',
        component: UserUpdate,
        props: true
      }
    ],
    meta: {
      requiresAuth: true
    }
  },

  // RPM routes
  {
    path: '/rpm',
    name: 'RPM',
    component: RPM,
    redirect: {
      name: 'RPMDashboard'
    },
    children: [
      {
        path: '',
        name: 'RPMDashboard',
        component: RPMDashboard
      },
      {
        path: ':assignee',
        name: 'AssigneeData',
        component: AssigneeData,
        props: true
      },
      {
        path: 'tasks',
        name: 'TaskList',
        component: TaskList
      }
    ],
    meta: {
      requiresAuth: true
    }
  },

  // weLEARN routes
  {
    path: '/we-learn',
    name: 'WeLearn',
    component: WeLearn,
    meta: {
      requiresAuth: true
    }
  },

  // weMEET routes
  {
    path: '/we-meet',
    name: 'WeMeet',
    component: WeMeet,
    redirect: {
      name: 'WeMeetLobby'
    },
    children: [
      {
        path: '',
        name: 'WeMeetLobby',
        component: WeMeetLobby
      },
      {
        path: 'meeting-logs/:roomId?',
        name: 'MeetingLogs',
        component: MeetingLogs,
        props: true
      },
      {
        path: 'meeting-recordings/:roomId?',
        name: 'MeetingRecordings',
        component: MeetingRecordings,
        props: true
      }
    ],
    meta: {
      requiresAuth: true
    }
  },

  // weMEET meeting invite & lobby routes
  {
    path: '/we-meet/room-invite/:roomId',
    name: 'MeetingInvite',
    component: MeetingInvite,
    props: true,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/we-meet/meeting-lobby/:roomId',
    name: 'MeetingLobby',
    component: MeetingLobby,
    props: true,
    meta: {
      requiresAuth: false
    }
  },

  // wePLAN routes
  {
    path: '/we-plan',
    name: 'WePlan',
    component: WePlan,
    meta: {
      requiresAuth: true
    }
  },

  // weSHARE routes
  {
    path: '/we-share',
    name: 'WeShare',
    component: WeShare,
    meta: {
      requiresAuth: true
    }
  },

  // Error routes
  {
    path: '/not-authorized',
    name: 'NotAuthorized',
    component: NotAuthorized,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      requiresAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Global before guard
router.beforeEach(async (to) => {
  // Check if user has logged in before redirecting to
  // destination page which requires authentication
  const isLoggedIn = store.getters['auth/getLoginFlag']
  if (to.meta.requiresAuth && !isLoggedIn) {
    if (to.name === 'Dashboard') {
      return { name: 'Login' }
    } else {
      return {
        name: 'Login',
        query: { redirect: to.fullPath }
      }
    }
  }

  // Check if user's beyond token is invalid or expired
  // when accessing a page requiring authentication
  if (to.meta.requiresAuth) {
    try {
      await getCurrentUser()
    } catch (e) {
      store.commit('clearStore')
      return { name: 'Login' }
    }
  }

  // Check if user is already logged in when trying to access login page
  const beyondToken = store.getters['auth/getBeyondToken']
  if (to.name === 'Login' && beyondToken) {
    return { name: 'Dashboard' }
  }

  // Check if user is authorized to visit protected application
  const inaccessibleApplicationNames =
    store.getters['application/getInaccessibleApplicationNames']
  if (
    to.matched.some(({ name }) => inaccessibleApplicationNames.includes(name))
  ) {
    return { name: 'NotAuthorized' }
  }
})

export default router
