<template>
  <div
    class="nav-bar"
    :class="{
      'nav-bar--expand': isNavBarExpanded,
      'nav-bar--collapse': !isNavBarExpanded
    }">
    <!-- Logo button (redirect to dashboard page) -->
    <img
      src="@/assets/images/tds-logo-white.png"
      class="nav-bar__logo nav-bar__button img-fluid mb-5 pt-4 px-2"
      :class="{
        'nav-bar__button--show': isNavBarExpanded,
        'nav-bar__button--hide': !isNavBarExpanded
      }"
      @click="onLogoClick" />

    <!-- Applications buttons -->
    <div class="d-flex flex-column">
      <div
        v-for="application in accessibleApplications"
        :key="application.name">
        <router-link
          :to="{ name: application.routeName }"
          active-class="icon--selected">
          <div
            class="nav-bar__button icon__container mx-auto mb-3"
            :class="{
              'nav-bar__button--show': isNavBarExpanded,
              'nav-bar__button--hide': !isNavBarExpanded
            }"
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            :title="application.name">
            <img
              :src="
                getApplicationIcon(
                  application.routeName,
                  application.icon,
                  application.whiteIcon
                )
              "
              class="img-fluid icon icon--expand" />
          </div>
        </router-link>
      </div>
    </div>

    <!-- Toggle button -->
    <div
      class="nav-bar__toggle icon__container"
      :class="{
        'nav-bar__toggle--expand': isNavBarExpanded,
        'nav-bar__toggle--collapse': !isNavBarExpanded
      }"
      @click="onToggleNavBar">
      <img :src="getToggleIcon()" class="img-fluid icon icon--expand" />
    </div>
  </div>
</template>

<script>
import { Tooltip } from 'bootstrap'
import { mapGetters } from 'vuex'

export default {
  name: 'NavBar',

  data() {
    return {
      isNavBarExpanded: true
    }
  },

  computed: {
    ...mapGetters({
      accessibleApplications: 'application/getAccessibleApplications'
    })
  },

  mounted() {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    tooltipTriggerList.map((tooltipTriggerEl) => {
      return Tooltip.getOrCreateInstance(tooltipTriggerEl)
    })
  },

  beforeUnmount() {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    tooltipTriggerList.map((tooltipTriggerEl) => {
      const tooltip = Tooltip.getOrCreateInstance(tooltipTriggerEl)
      tooltip.dispose()
    })
  },

  methods: {
    onLogoClick() {
      this.$router.push({ name: 'Dashboard' })
    },

    getApplicationIcon(routeName, icon, whiteIcon) {
      if (this.$route.matched.some(({ name }) => routeName === name)) {
        return require('@/assets/icons/' + icon) // selected application
      } else {
        return require('@/assets/icons/' + whiteIcon) // unselected application
      }
    },

    onToggleNavBar() {
      this.isNavBarExpanded = !this.isNavBarExpanded
    },

    getToggleIcon() {
      if (this.isNavBarExpanded) {
        return require('@/assets/icons/toggle-collapse.svg')
      } else {
        return require('@/assets/icons/toggle-expand.svg')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/components/_nav-bar.scss';
</style>
