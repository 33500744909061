<template>
  <spinner />

  <!-- Header and Toggle view buttons -->
  <p class="back-nav main-heading d-inline-block mb-0 me-3" @click="onBackNav">
    &lt; Tasks
  </p>
  <div
    v-for="tasksView in tasksViews"
    class="d-inline-block"
    :key="tasksView.view">
    <div
      class="toggle-view icon__container mb-2 me-3"
      :class="{
        'toggle-view--selected': selectedTasksView === tasksView.view
      }"
      @click="onChangeTasksView(tasksView.view)">
      <img :src="getTasksViewIcon(tasksView)" class="img-fluid icon" />
    </div>
  </div>

  <br class="tasks--narrow" />

  <!-- Heading buttons -->
  <div class="tasks__heading-buttons d-inline-block">
    <!-- Per page select menu -->
    <select
      class="tasks__per-page form-select select--dark d-inline-block mb-4 me-2 px-2 py-1"
      v-if="selectedTasksView === tasksViews[1].view"
      v-model="perPage">
      <option v-for="option in perPageOptions" :value="option" :key="option">
        {{ option }}
      </option>
    </select>
    <p
      v-if="selectedTasksView === tasksViews[1].view"
      class="main-text d-inline-block mb-4 me-3">
      per page
    </p>

    <br
      v-if="selectedTasksView === tasksViews[1].view"
      class="tasks--very-narrow" />

    <!-- Export button -->
    <button
      v-if="selectedTasksView === tasksViews[1].view"
      type="button"
      class="button-primary mb-4 me-3"
      @click="onExportTasks">
      Export
    </button>

    <!-- Filter button -->
    <button
      type="button"
      class="button-primary mb-4"
      data-open="filterTasksModal">
      Filter
    </button>
  </div>

  <!-- Modal - Filter tasks -->
  <filter-tasks-modal
    modalPopupId="filterTasksModal"
    :isCalendarView="selectedTasksView === tasksViews[0].view"
    :departments="departments"
    :projects="projects"
    :assignees="assignees"
    :statuses="statuses"
    @applyFilters="onApplyFilters" />

  <!-- List tasks error message -->
  <p v-if="listTasksErrorMessage" class="error-message">
    {{ listTasksErrorMessage }}
  </p>

  <div
    v-if="!listTasksErrorMessage"
    class="tasks scroll-view"
    :class="{
      'tasks--table': selectedTasksView === tasksViews[1].view
    }">
    <!-- Calendar view -->
    <div v-if="selectedTasksView === tasksViews[0].view">
      <month-toggle class="mb-3" @changeMonth="onChangeCalendarMonth" />
      <FullCalendar ref="calendar" :options="calendarOptions" />
    </div>

    <!-- Table view -->
    <div v-else>
      <table class="table">
        <tbody>
          <!-- Table view - Header row -->
          <tr>
            <th>Project</th>
            <th>Task</th>
            <th>Status</th>
            <th>Assignee</th>
            <th>Responsible</th>
            <th>Start date</th>
            <th>End date</th>
            <th>Estimated time</th>
            <th>Actual time</th>
          </tr>

          <!-- Table view - Task row -->
          <tr v-for="task in tasks" :key="task._id">
            <td>{{ task.project }}</td>
            <td>{{ task.subject }}</td>
            <td>{{ task.status }}</td>
            <td>{{ task.assignee }}</td>
            <td>{{ task.responsible }}</td>
            <td>{{ formatDate(task.startDate) }}</td>
            <td>{{ formatDate(task.dueDate) }}</td>
            <td>{{ task.estimatedTime }}</td>
            <td>{{ task.spentTime }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Table view - Pagination -->
      <pagination
        class="float-end"
        :currentPage="currentPage"
        :totalNumOfPages="numOfPages"
        @pageChange="onDisplayPage" />
    </div>
  </div>
</template>

<script>
import '@fullcalendar/core/vdom' // solve problem with Vite
import dayGridPlugin from '@fullcalendar/daygrid'
import { mapGetters, mapMutations } from 'vuex'
import { listTasks, exportTasks } from '@/services/api/rpm.js'
import {
  getDateLocaleStr,
  getLastDayOfHtmlMonth
} from '@/services/utils/date.js'
import {
  initialiseModalPopups,
  cleanUpModalPopups
} from '@/services/utils/modal.js'
import { tasksViews } from '@/services/utils/rpm.js'
import { perPageOptions } from '@/services/utils/select-menu.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import FilterTasksModal from '@/views/rpm/modal/FilterTasksModal.vue'
import FullCalendar from '@fullcalendar/vue3'
import MonthToggle from '@/components/MonthToggle.vue'
import Pagination from '@/components/Pagination.vue'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'TaskList',

  components: {
    FilterTasksModal,
    FullCalendar,
    MonthToggle,
    Pagination,
    Spinner
  },

  data() {
    return {
      tasksViews: tasksViews,
      selectedTasksView: tasksViews[0].view,

      // Calendar view
      calendarMonth: '',
      calendarOptions: {
        eventSources: [],
        fixedWeekCount: false,
        headerToolbar: false,
        initialView: 'dayGridMonth',
        plugins: [dayGridPlugin],
        weekends: false
      },

      // List view
      perPage: perPageOptions[perPageOptions.length - 1],
      perPageOptions: perPageOptions,
      currentPage: 1,
      numOfPages: 1,

      tasks: [],
      departments: [],
      projects: [],
      assignees: [],
      statuses: [],
      listTasksErrorMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      tasksFilters: 'rpm/getTasksFilters'
    })
  },

  watch: {
    async perPage() {
      if (this.selectedTasksView === tasksViews[1].view) {
        // Table view
        this.currentPage = 1
        await this.listTableTasks()
      }
    }
  },

  async mounted() {
    initialiseModalPopups()
  },

  beforeUnmount() {
    cleanUpModalPopups()
  },

  methods: {
    ...mapMutations('rpm', ['setTasksFilters']),

    onBackNav() {
      this.$router.push({ name: 'RPM' })
    },

    async listCalendarTasks() {
      // Reset list tasks error message
      this.listTasksErrorMessage = ''

      try {
        // Call list tasks API
        showSpinner()
        const res = await listTasks('', '', this.tasksFilters)
        this.departments = res.data.departments
        this.projects = res.data.projects
        this.assignees = res.data.assignees
        this.statuses = res.data.jobTasksStatus

        // Populate calendar
        this.calendarOptions.eventSources = []
        const plannedEvents = []
        res.data.jobTasks.forEach((task) => {
          plannedEvents.push({
            title: task.subject,
            start: task.startDate,
            end: task.dueDate
          })
        })
        this.calendarOptions.eventSources.push({
          events: plannedEvents,
          color: 'skyblue',
          textColor: 'black'
        })
        hideSpinner()
      } catch (e) {
        hideSpinner()
        this.listTasksErrorMessage = e.response.data.message
      }
    },

    async listTableTasks() {
      // Reset list tasks error message
      this.listTasksErrorMessage = ''

      try {
        // Call list tasks API
        showSpinner()
        const res = await listTasks(
          this.perPage,
          this.currentPage,
          this.tasksFilters
        )
        this.tasks = res.data.jobTasks
        this.departments = res.data.departments
        this.projects = res.data.projects
        this.assignees = res.data.assignees
        this.statuses = res.data.jobTasksStatus
        this.numOfPages = res.data.totalPages
        hideSpinner()
      } catch (e) {
        hideSpinner()
        this.listTasksErrorMessage = e.response.data.message
      }
    },

    async onChangeTasksView(view) {
      this.selectedTasksView = view

      if (view === tasksViews[1].view) {
        // If switching from calendar to table view,
        // clear date filters set by listCalendarTasks method
        this.tasksFilters[4] = ''
        this.tasksFilters[5] = ''
        this.setTasksFilters(this.tasksFilters)
      }
      await this.onApplyFilters()
    },

    getTasksViewIcon(tasksView) {
      if (tasksView.view === this.selectedTasksView) {
        return require('@/assets/icons/' + tasksView.whiteIcon) // selected view
      } else {
        return require('@/assets/icons/' + tasksView.icon) // unselected view
      }
    },

    async onExportTasks() {
      try {
        showSpinner()
        const res = await exportTasks(this.tasksFilters)
        window.open(res.request.responseURL, '_self')
        hideSpinner()
      } catch (e) {
        hideSpinner()
      }
    },

    async onApplyFilters() {
      if (
        this.selectedTasksView === tasksViews[0].view &&
        this.calendarMonth !== ''
      ) {
        // Calendar view
        await this.listCalendarTasks()
      }

      if (this.selectedTasksView === tasksViews[1].view) {
        // Table view
        this.currentPage = 1
        await this.listTableTasks()
      }
    },

    async onChangeCalendarMonth(month) {
      this.calendarMonth = month

      // Set date filters
      this.tasksFilters[4] = month + '-01'
      this.tasksFilters[5] = getLastDayOfHtmlMonth(month)
      this.setTasksFilters(this.tasksFilters)
      await this.listCalendarTasks()

      // Render calendar view for new month
      const calendarApi = this.$refs.calendar.getApi()
      calendarApi.changeView('dayGridMonth', month)
    },

    formatDate(dateStr) {
      return dateStr === null ? '' : getDateLocaleStr(dateStr)
    },

    async onDisplayPage(newPage) {
      if (this.selectedTasksView === tasksViews[1].view) {
        this.currentPage = newPage
        await this.listTableTasks()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_rpm.scss';
</style>
