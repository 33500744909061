// NOTE: Form element can be an input element or a select element.

/************************
 * FORM ELEMENT methods *
 ************************/

// Handle an invalid form element
function handleInvalidFormElement(formElement) {
  formElement.setCustomValidity('error')

  // Toggle input's border color red
  formElement.classList.add('input--invalid')

  // Display input's error message
  if (formElement.nextElementSibling) {
    formElement.nextElementSibling.classList.add('show')
  }
}

// Handle a valid form element
function handleValidFormElement(formElement) {
  formElement.setCustomValidity('')

  // Toggle input's border color non-red
  formElement.classList.remove('input--invalid')

  // Hide input's error message
  if (formElement.nextElementSibling) {
    formElement.nextElementSibling.classList.remove('show')
  }
}

// Handle and return whether a form element is valid or not
function handleFormElementValidity(formElement) {
  formElement.setCustomValidity('') // reset any custom validity
  if (!formElement.checkValidity()) {
    handleInvalidFormElement(formElement)
  } else {
    handleValidFormElement(formElement)
  }
  return formElement.checkValidity()
}

/*************************
 * INPUT ELEMENT methods *
 *************************/
// NOTE: Methods CANNOT be used on select elements.

// Input event listener for input element
function inputEventListener(event) {
  const form = document.querySelector('.form')
  const inputElement = event.srcElement

  // Only validate if form was validated before
  if (form.classList.contains('validated')) {
    handleFormElementValidity(inputElement)
  }
}

// Add input event listener to all inputs in all forms in the document
function initialiseInputElements() {
  const forms = document.querySelectorAll('.form')
  forms.forEach((form) => {
    const inputElements = form.getElementsByTagName('input')
    for (var i = 0; i < inputElements.length; i++) {
      const inputElement = inputElements[i]
      inputElement.addEventListener('input', inputEventListener)
    }
  })
}

// Remove input event listener from all inputs in a form
function cleanUpInputElements() {
  const forms = document.querySelectorAll('.form')
  forms.forEach((form) => {
    const inputElements = form.getElementsByTagName('input')
    for (var i = 0; i < inputElements.length; i++) {
      const inputElement = inputElements[i]
      inputElement.removeEventListener('input', inputEventListener)
    }
  })
}

// Start validating input SEPARATELY from validating form
function startValidatingInputElement(inputElement) {
  handleFormElementValidity(inputElement) // start validating!
  inputElement.addEventListener('input', () => {
    handleFormElementValidity(inputElement)
  })
}

// Stop validating input SEPARATELY from validating form
function stopValidatingInputElement(inputElement) {
  inputElement.removeEventListener('input', () => {
    handleFormElementValidity(inputElement)
  })
}

// Validate input elements in a form and return if form is valid
function areValidInputs(formContainerId) {
  const form = document.getElementById(formContainerId).querySelector('.form')
  form.classList.add('validated')

  var isValidForm = true
  const inputElements = form.getElementsByTagName('input')
  for (var i = 0; i < inputElements.length; i++) {
    const inputElement = inputElements[i]
    const isInputValid = handleFormElementValidity(inputElement)
    if (!isInputValid) {
      isValidForm = false
    }
  }

  return isValidForm
}

// Reset validation of all input elements in a form
function resetInputsValidation(formContainerId) {
  const form = document.getElementById(formContainerId).querySelector('.form')
  const inputElements = form.getElementsByTagName('input')
  for (var i = 0; i < inputElements.length; i++) {
    const inputElement = inputElements[i]
    handleValidFormElement(inputElement) // treat input element as valid
  }
}

export {
  handleInvalidFormElement,
  handleValidFormElement,
  handleFormElementValidity,
  initialiseInputElements,
  cleanUpInputElements,
  startValidatingInputElement,
  stopValidatingInputElement,
  areValidInputs,
  resetInputsValidation
}
