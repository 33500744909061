import { instance } from './global.js'
import store from '@/store/index.js'

function getAssigneeData(assignee) {
  return instance.get('/jobtasks/aggregated', {
    params: {
      assignee: assignee,
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

function getAggregatedData(filters) {
  return instance.get('/jobtasks/aggregated', {
    params: {
      department: filters[0],
      project: filters[1],
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

function listTasks(perPage, currentPage, filters) {
  return instance.get('/jobtasks', {
    params: {
      perPage: perPage,
      currentPage: currentPage,
      department: filters[0],
      project: filters[1],
      assignee: filters[2],
      status: filters[3],
      startDate: filters[4],
      endDate: filters[5],
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

function exportTasks(filters) {
  return instance.get('/jobtasks', {
    params: {
      department: filters[0],
      project: filters[1],
      assignee: filters[2],
      status: filters[3],
      startDate: filters[4],
      endDate: filters[5],
      exportFile: 'csv',
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

export { getAssigneeData, getAggregatedData, listTasks, exportTasks }
