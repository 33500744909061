<template>
  <div>
    <change-password-modal modalPopupId="changePasswordModal" />

    <nav-bar />

    <div class="page-view">
      <top-bar />

      <div class="dashboard-scroll scroll-view">
        <!-- Application cards -->
        <div class="container-fluid py-3">
          <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 gx-3 gy-5">
            <div
              class="col"
              v-for="application in nonDashboardApplications"
              :key="application.name">
              <!-- Application card -->
              <div
                class="dashboard card"
                @click="onApplicationCardClick(application.routeName)">
                <div class="card-body">
                  <div class="mb-3">
                    <!-- Application card - Icon -->
                    <div class="d-inline-block">
                      <img
                        :src="getApplicationIcon(application.whiteIcon)"
                        class="img-fluid icon" />
                    </div>

                    <!-- Application card - Header -->
                    <p class="card__header heading-one d-inline-block">
                      {{ application.name }}
                    </p>
                  </div>

                  <!-- Application card - Description -->
                  <p class="card__description main-text">
                    {{ application.description }}
                  </p>
                </div>

                <!-- Application card - Illustration (at rightmost side) -->
                <img
                  src="@/assets/images/dashboard-card-illustration-bg.svg"
                  class="card__illustration-bg" />

                <img
                  :src="
                    getApplicationIllustration(
                      application.dashboardIllustration
                    )
                  "
                  class="card__illustration" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  initialiseModalPopups,
  cleanUpModalPopups,
  openModalPopup
} from '@/services/utils/modal.js'
import ChangePasswordModal from '@/views/user/ChangePasswordModal.vue'
import NavBar from '@/components/NavBar.vue'
import TopBar from '@/components/TopBar.vue'

export default {
  name: 'Dashboard',

  components: {
    ChangePasswordModal,
    NavBar,
    TopBar
  },

  props: {
    // Boolean values are in strings as Vue programmatic navigation
    // passes parameters as String
    displayChangePasswordPrompt: {
      type: String,
      require: false,
      default: 'false'
    }
  },

  computed: {
    ...mapGetters({
      nonDashboardApplications:
        'application/getAccessibleNonDashboardApplications'
    })
  },

  mounted() {
    initialiseModalPopups()
    if (this.displayChangePasswordPrompt === 'true') {
      openModalPopup('changePasswordModal')
    }
  },

  beforeUnmount() {
    cleanUpModalPopups()
  },

  methods: {
    onApplicationCardClick(routeName) {
      this.$router.push({ name: routeName })
    },

    getApplicationIcon(icon) {
      return require('@/assets/icons/' + icon)
    },

    getApplicationIllustration(illustration) {
      return require('@/assets/images/' + illustration)
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_user.scss';
</style>
