function showModalPopup() {
  const modalPopupId = this.dataset.open
  document.getElementById(modalPopupId).classList.add('show')
}

function hideModalPopup() {
  const modalPopupId = this.dataset.close
  document.getElementById(modalPopupId).classList.remove('show')
}

function initialiseModalPopups() {
  // Add event listener for opening a modal popup
  const openElems = document.querySelectorAll('[data-open]')
  for (const elem of openElems) {
    elem.addEventListener('click', showModalPopup)
  }

  // Add event listener for closing a modal popup
  const closeElems = document.querySelectorAll('[data-close]')
  for (const elem of closeElems) {
    elem.addEventListener('click', hideModalPopup)
  }
}

function cleanUpModalPopups() {
  // Remove event listener for opening a modal popup
  const openElems = document.querySelectorAll('[data-open]')
  for (const elem of openElems) {
    elem.removeEventListener('click', showModalPopup)
  }

  // Remove event listener for closing a modal popup
  const closeElems = document.querySelectorAll('[data-close]')
  for (const elem of closeElems) {
    elem.removeEventListener('click', hideModalPopup)
  }
}

function openModalPopup(modalPopupId) {
  document.getElementById(modalPopupId).classList.add('show')
}

function closeModalPopup(modalPopupId) {
  document.getElementById(modalPopupId).classList.remove('show')
}

export {
  initialiseModalPopups,
  cleanUpModalPopups,
  openModalPopup,
  closeModalPopup
}
