// Authentication messages

const inputError = {
  emptyEmail: 'Please provide an email.',
  emptyPassword: 'Please provide a password.',
  invalidEmailOrPassword: 'The email or password is invalid.', // used in login page
  invalidPassword: 'The password is invalid.' // used in enter password page
}

export { inputError }
