import { meetingInputError } from '@/services/message/we-meet.js'
import {
  getTodayDateObj,
  getTomorrowDateObj,
  getEndOfWeekDateObj,
  getEndOfMonthDateObj,
  getStartOfDayIsoStr,
  getEndOfDayIsoStr,
  isStartDatetimeAfterCurrentDatetime,
  isStartDatetimeBeforeEndDatetime
} from '@/services/utils/date.js'
import {
  handleInvalidFormElement,
  handleValidFormElement,
  startValidatingInputElement,
  stopValidatingInputElement
} from '@/services/utils/form.js'

/********************
 * weMEET constants *
 ********************/

const upcomingMeetingDateFilters = [
  {
    option: 'Today',
    startDateStr: getTodayDateObj().toISOString(),
    endDateStr: getEndOfDayIsoStr(getTodayDateObj())
  },
  {
    option: 'Tomorrow',
    startDateStr: getStartOfDayIsoStr(getTomorrowDateObj()),
    endDateStr: getEndOfDayIsoStr(getTomorrowDateObj())
  },
  {
    option: 'This week',
    startDateStr: getTodayDateObj().toISOString(),
    endDateStr: getEndOfDayIsoStr(getEndOfWeekDateObj())
  },
  {
    option: 'This month',
    startDateStr: getTodayDateObj().toISOString(),
    endDateStr: getEndOfDayIsoStr(getEndOfMonthDateObj())
  }
]

/**************************
 * weMEET forms functions *
 **************************/

// Return whether participant email is valid
function validateParticipantEmail(event, modalPopupId) {
  // Prevent form submission
  event.stopImmediatePropagation()

  const participantInputElement = document
    .getElementById(modalPopupId)
    .querySelector('#we-meet-form__participants-input')

  if (!participantInputElement.checkValidity()) {
    startValidatingInputElement(participantInputElement)
    return false
  } else {
    stopValidatingInputElement(participantInputElement)
    return true
  }
}

// Validate start and end date and time and return any error message
function validateStartAndEndDateAndTime(
  modalPopupId,
  startHtmlDate,
  startHtmlTime,
  endHtmlDate,
  endHtmlTime
) {
  var errorMessage = ''

  if (
    startHtmlDate === '' ||
    startHtmlTime === '' ||
    endHtmlDate === '' ||
    endHtmlTime === ''
  ) {
    // Do not validate if all the dates and times are not provided
    return errorMessage
  }

  const startDateInputElement = document
    .getElementById(modalPopupId)
    .querySelector('#we-meet-form__start-date-input')
  const startTimeInputElement = document
    .getElementById(modalPopupId)
    .querySelector('#we-meet-form__start-time-input')
  const endDateInputElement = document
    .getElementById(modalPopupId)
    .querySelector('#we-meet-form__end-date-input')
  const endTimeInputElement = document
    .getElementById(modalPopupId)
    .querySelector('#we-meet-form__end-time-input')

  const checkStart = isStartDatetimeAfterCurrentDatetime(
    startHtmlDate,
    startHtmlTime
  )
  const checkEnd = isStartDatetimeBeforeEndDatetime(
    startHtmlDate,
    startHtmlTime,
    endHtmlDate,
    endHtmlTime
  )
  const isValid = checkStart && checkEnd

  if (!isValid) {
    // Set input elements as invalid
    handleInvalidFormElement(startDateInputElement)
    handleInvalidFormElement(startTimeInputElement)
    handleInvalidFormElement(endDateInputElement)
    handleInvalidFormElement(endTimeInputElement)
  } else {
    // Set input elements as valid
    handleValidFormElement(startDateInputElement)
    handleValidFormElement(startTimeInputElement)
    handleValidFormElement(endDateInputElement)
    handleValidFormElement(endTimeInputElement)
  }

  if (!checkStart) {
    return meetingInputError.invalidStartDateAndTime
  }
  if (!checkEnd) {
    return meetingInputError.invalidEndDateAndTime
  }

  return ''
}

export {
  upcomingMeetingDateFilters,
  validateParticipantEmail,
  validateStartAndEndDateAndTime
}
