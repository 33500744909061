<template>
  <nav v-if="totalNumOfPages > 1" aria-label="Page navigation">
    <ul class="pagination justify-content-center">
      <!-- '<<' button -->
      <li class="page-item">
        <button
          v-show="currentPage > 3"
          type="button"
          class="page-link"
          @click="$emit('pageChange', 1)">
          &lt;&lt;
        </button>
      </li>

      <!-- '<' button -->
      <li class="page-item">
        <button
          v-show="currentPage != 1"
          type="button"
          class="page-link"
          @click="$emit('pageChange', currentPage - 1)">
          &lt;
        </button>
      </li>

      <!-- Page buttons for at most five pages -->
      <li
        v-for="pageNum in displayedPageRange"
        class="page-item"
        :class="{ active: currentPage === pageNum }"
        :key="pageNum">
        <button
          type="button"
          class="page-link"
          @click="$emit('pageChange', pageNum)">
          {{ pageNum }}
        </button>
      </li>

      <!-- '>' button -->
      <li class="page-item">
        <button
          v-show="currentPage != totalNumOfPages"
          type="button"
          class="page-link"
          @click="$emit('pageChange', currentPage + 1)">
          &gt;
        </button>
      </li>

      <!-- '>>' button -->
      <li class="page-item">
        <button
          v-show="currentPage < totalNumOfPages - 2"
          type="button"
          class="page-link"
          @click="$emit('pageChange', totalNumOfPages)">
          &gt;&gt;
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    currentPage: {
      type: Number
    },

    totalNumOfPages: {
      type: Number
    }
  },

  emits: ['pageChange'],

  computed: {
    displayedPageRange() {
      // Display at most 5 pages in the pagination bar
      const pageRange = []
      let first
      let last
      if (this.currentPage < 3) {
        first = 1
        last = Math.min(this.totalNumOfPages, first + 4)
      } else if (this.currentPage > this.totalNumOfPages - 2) {
        last = this.totalNumOfPages
        first = Math.max(1, last - 4)
      } else {
        first = this.currentPage - 2
        last = this.currentPage + 2
      }
      for (let i = first; i <= last; i++) {
        pageRange.push(i)
      }
      return pageRange
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/components/_pagination.scss';
</style>
