<template>
  <table class="table align-middle">
    <tbody>
      <!-- Header row -->
      <tr>
        <!-- Header row - Select all items checkbox -->
        <th>
          <input
            type="checkbox"
            class="form-check-input checkbox"
            v-model="areAllRowsSelected"
            @click="onToggleAllRowsSelected(!areAllRowsSelected)" />
        </th>

        <!-- Header row - Header value -->
        <slot name="header" />
      </tr>

      <!-- Item row -->
      <tr v-for="(item, index) in items" :key="item">
        <!-- Item row - Select item checkbox -->
        <td>
          <input
            type="checkbox"
            class="form-check-input checkbox"
            v-model="rowsSelectedFlags[index]" />
        </td>

        <!-- Item row - Column value -->
        <slot name="item" :item="item" :index="index" />
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'SelectableTable',

  props: {
    items: {
      type: Array,
      require: true
    }
  },

  emits: ['rowsSelectedChange'],

  data() {
    return {
      numOfRows: 0,
      rowsSelectedFlags: [],
      areAllRowsSelected: false
    }
  },

  watch: {
    items: {
      handler() {
        this.numOfRows = this.items.length
        this.rowsSelectedFlags = Array(this.numOfRows).fill(false)
        this.areAllRowsSelected = false
      },
      deep: true
    },

    rowsSelectedFlags: {
      handler(newArr) {
        const count = newArr.reduce((prev, curr) => {
          if (curr) {
            return prev + 1
          }
          return prev
        }, 0)
        this.areAllRowsSelected = count === this.numOfRows
        this.$emit('rowsSelectedChange', newArr, count)
      },
      deep: true
    }
  },

  created() {
    this.numOfRows = this.items.length
    this.rowsSelectedFlags = Array(this.numOfRows).fill(false)
    this.areAllRowsSelected = false
  },

  methods: {
    onToggleAllRowsSelected(newBool) {
      this.rowsSelectedFlags = Array(this.numOfRows).fill(newBool)
    }
  }
}
</script>
