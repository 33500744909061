import axios from 'axios'
import router from '../../router'

const instance = axios.create({
  baseURL: 'https://api-suite.8eyond.sg/api/v1'
})

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      router.replace({ name: 'NotAuthorized' })
    }

    if (error.response.status === 404) {
      if (
        !error.response.request.responseURL.includes('/user/forgetPassword')
      ) {
        router.replace({ name: 'NotFound' })
      }
    }

    return Promise.reject(error)
  }
)

export { instance }
