import { instance } from './global.js'
import store from '@/store/index.js'

function getWeLearnUrl() {
  return instance.get('/user/WeLearn', {
    params: {
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

export { getWeLearnUrl }
