<template>
  <spinner />

  <div class="container-fluid px-3">
    <div class="row vh-100">
      <div class="enter-password__form-col col-12 col-lg-6 m-auto">
        <!-- Headings -->
        <p class="enter-password__header main-heading m-0">Enter Password</p>
        <p class="main-text--light mb-0">
          A temporary password has been sent to your registered email.
        </p>
        <p class="main-text--light mb-5">Enter the password below to login.</p>

        <!-- Form -->
        <form @submit.prevent="onLogin">
          <!-- Form - Email input -->
          <div class="mb-3">
            <input
              type="email"
              class="form-control input"
              v-model="emailInput"
              disabled />
          </div>

          <!-- Form - Password input -->
          <div class="mb-3">
            <input
              type="password"
              class="form-control input"
              v-model="passwordInput"
              placeholder="Password"
              aria-label="Password" />
          </div>

          <!-- Form - Input error message -->
          <p v-if="inputErrorMessage" class="error-message mt-3">
            {{ inputErrorMessage }}
          </p>

          <!-- Form - Login and cancel buttons -->
          <button type="submit" class="button-primary mb-3 me-3 mt-5">
            Login
          </button>
          <button type="button" @click="onCancel" class="button-outline">
            Cancel
          </button>
        </form>
      </div>

      <div class="col-lg-6" />
    </div>
  </div>
</template>

<script>
import router from '@/router'
import { mapMutations } from 'vuex'
import { loginUser } from '@/services/api/auth.js'
import { inputError } from '@/services/message/auth.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'EnterPassword',

  components: {
    Spinner
  },

  props: {
    email: {
      type: String,
      require: true
    }
  },

  data() {
    return {
      emailInput: this.email,
      passwordInput: '',
      inputErrorMessage: '',
      inputError: inputError
    }
  },

  beforeRouteEnter(to, from) {
    if (from.name !== 'ForgetPassword' && from.name !== 'EnterPassword') {
      // Redirect to NotAuthorized page
      // if user does not come from forget password page
      router.replace({ name: 'NotAuthorized' })
    }
  },

  methods: {
    ...mapMutations('auth', ['setUserId', 'setBeyondToken', 'setLoginFlag']),

    ...mapMutations('application', [
      'setInitialApplications',
      'toggleApplicationAccessibility'
    ]),

    ...mapMutations('user', ['setUsername', 'setFirstName', 'setLastName']),

    async onLogin() {
      // Reset input error message
      this.inputErrorMessage = ''

      // Input error message: Empty password
      if (!this.passwordInput) {
        this.inputErrorMessage = inputError.emptyPassword
        return
      }

      try {
        // Call login user API
        showSpinner()
        const res = await loginUser(this.emailInput, this.passwordInput)
        hideSpinner()

        // Store user information in Vuex auth and user stores
        this.setUserId(res.data.user._id)
        this.setBeyondToken(res.data.user.beyondToken)
        this.setLoginFlag(true)
        this.setUsername(res.data.user.username)
        this.setFirstName(res.data.user.firstName)
        this.setLastName(res.data.user.lastName)

        // Toggle applications accessibility in Vuex application store
        this.setInitialApplications()
        const accessibilityArr = [
          true, // dashboard application accessibility
          res.data.user.admin,
          res.data.user.WeLearn,
          res.data.user.WeMeet,
          res.data.user.WePlan,
          res.data.user.WeShare
        ]
        this.toggleApplicationAccessibility(accessibilityArr)

        // Redirect to dashboard with change password prompt
        this.$router.push({
          name: 'Dashboard',
          params: { displayChangePasswordPrompt: true }
        })
      } catch (e) {
        hideSpinner()

        // Input error message: Invalid password
        this.inputErrorMessage = inputError.invalidPassword
      }
    },

    onCancel() {
      this.$router.replace({ name: 'Login' })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_auth.scss';
</style>
