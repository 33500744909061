<template>
  <modal-form
    :modalPopupId="modalPopupId"
    heading="Room Settings"
    @closeModalForm="onCloseModalForm">
    <template #form>
      <form
        class="form"
        @keypress.enter.prevent
        @keyup.enter.prevent
        @submit.prevent="onEditRoom($event)"
        novalidate>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-6">
              <!-- Room name input  -->
              <div class="mb-4">
                <label
                  for="we-meet-form__room-name-input"
                  class="form-label sub-text--light">
                  Room Name
                </label>
                <input
                  type="text"
                  class="form-control input--dark"
                  id="we-meet-form__room-name-input"
                  v-model="roomNameStr" />
                <div class="form__invalid-message">
                  {{ roomInputError.emptyRoomName }}
                </div>
              </div>

              <!-- Welcome message input-->
              <div>
                <label
                  for="we-meet-form__welcome-message-input"
                  class="form-label sub-text--light">
                  Welcome Message (optional)
                </label>
                <textarea
                  type="text"
                  class="form-control input mb-4"
                  id="we-meet-form__welcome-message-input"
                  v-model="welcomeMessageStr"
                  placeholder="Type a message..."
                  rows="5" />
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <p class="sub-text--light mb-2">Settings</p>

              <!-- Settings container -->
              <div class="we-meet-form__settings-container mb-4 p-3">
                <!-- Settings container - Access select menu -->
                <label
                  for="we-meet-form__access-value"
                  class="form-label sub-text--light mb-2 me-2">
                  Access
                </label>
                <select
                  class="form-select select d-inline-block mb-2 py-1"
                  id="we-meet-form__access-value"
                  v-model="isRoomLockedBool">
                  <option
                    v-for="option in accessOptions"
                    :value="option.boolean"
                    :key="option.name">
                    {{ option.name }}
                  </option>
                </select>

                <!-- Settings container - Require approval switch -->
                <div class="form-check form-switch mb-2">
                  <input
                    type="checkbox"
                    role="switch"
                    class="form-check-input"
                    id="we-meet-form__require-approval-switch"
                    v-model="isApprovalRequiredBool" />
                  <label
                    class="form-check-label sub-text"
                    for="we-meet-form__require-approval-switch">
                    Require approval to join
                  </label>
                </div>

                <!-- Settings container - Mute users switch -->
                <div class="form-check form-switch mb-2">
                  <input
                    type="checkbox"
                    role="switch"
                    class="form-check-input"
                    id="we-meet-form__mute-users-switch"
                    v-model="areUsersMutedBool" />
                  <label
                    class="form-check-label sub-text"
                    for="we-meet-form__mute-users-switch">
                    Mute all users
                  </label>
                </div>

                <!-- Settings container - Record meetings switch -->
                <div class="form-check form-switch">
                  <input
                    type="checkbox"
                    role="switch"
                    class="form-check-input"
                    id="we-meet-form__record-meetings-switch"
                    v-model="isMeetingRecordedBool" />
                  <label
                    class="form-check-label sub-text"
                    for="we-meet-form__record-meetings-switch">
                    Record Meetings
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- Edit room error message -->
          <p v-if="editRoomErrorMessage" class="error-message mt-4">
            {{ editRoomErrorMessage }}
          </p>
        </div>

        <!-- Confirm and cancel buttons -->
        <div class="mb-2">
          <button type="submit" class="button-primary mb-2 me-3">
            Confirm
          </button>

          <button
            type="button"
            class="button-outline"
            :data-close="modalPopupId"
            @click="onCloseModalForm">
            Cancel
          </button>
        </div>
      </form>
    </template>
  </modal-form>
</template>

<script>
import { updateRoom } from '@/services/api/we-meet.js'
import { roomInputError } from '@/services/message/we-meet.js'
import { areValidInputs, resetInputsValidation } from '@/services/utils/form.js'
import { closeModalPopup } from '@/services/utils/modal.js'
import { accessOptions } from '@/services/utils/select-menu.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import ModalForm from '@/components/ModalForm.vue'

export default {
  name: 'RoomSettings',

  components: {
    ModalForm
  },

  props: {
    modalPopupId: {
      type: String,
      require: true
    },

    roomId: {
      type: String
    },

    roomName: {
      type: String
    },

    welcomeMessage: {
      type: String
    },

    isRoomLocked: {
      type: Boolean
    },

    isApprovalRequired: {
      type: Boolean
    },

    areUsersMuted: {
      type: Boolean
    },

    isMeetingRecorded: {
      type: Boolean
    }
  },

  emits: ['confirmModalForm'],

  data() {
    return {
      roomNameStr: this.roomName,
      welcomeMessageStr: this.welcomeMessage,
      isRoomLockedBool: this.isRoomLocked,
      accessOptions: accessOptions,
      isApprovalRequiredBool: this.isApprovalRequired,
      areUsersMutedBool: this.areUsersMuted,
      isMeetingRecordedBool: this.isMeetingRecorded,
      editRoomErrorMessage: '',
      roomInputError: roomInputError
    }
  },

  methods: {
    async onEditRoom(event) {
      // Reset edit room error message
      this.editRoomErrorMessage = ''

      // Validate form
      if (!areValidInputs(this.modalPopupId)) {
        event.stopPropagation()
        return
      }

      try {
        // Call update room API
        showSpinner()
        await updateRoom(
          this.roomId,
          this.roomNameStr,
          this.welcomeMessageStr,
          this.isRoomLockedBool,
          this.isApprovalRequiredBool,
          this.areUsersMutedBool,
          this.isMeetingRecordedBool
        )
        hideSpinner()

        // Close modal, remove validated class from form
        // and emit event to WeMeetLobby component
        closeModalPopup(this.modalPopupId)
        document
          .getElementById(this.modalPopupId)
          .querySelector('.form')
          .classList.remove('validated')
        this.$emit('confirmModalForm')
      } catch (e) {
        hideSpinner()
        this.editRoomErrorMessage = e.response.data.message
      }
    },

    onCloseModalForm() {
      // Reset form when user clicks on cross icon or "Cancel" button
      resetInputsValidation(this.modalPopupId)
      this.roomNameStr = this.roomName
      this.welcomeMessageStr = this.welcomeMessage
      this.isRoomLockedBool = this.isRoomLocked
      this.isApprovalRequiredBool = this.isApprovalRequired
      this.areUsersMutedBool = this.areUsersMuted
      this.isMeetingRecordedBool = this.isMeetingRecorded
      this.editRoomErrorMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
