<template>
  <div class="time-quota card">
    <div class="card-body">
      <!-- Header -->
      <p class="main-text--bold mb-2">Meet Time Quota</p>

      <!-- Progress bar -->
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          :style="{ width: timeQuotaPercentage + '%' }"
          :aria-valuenow="timeQuotaPercentage"
          aria-valuemin="0"
          aria-valuemax="100" />
      </div>

      <!-- Statistics -->
      <div class="row gx-2">
        <div class="col col-12 col-sm-6">
          <p class="sub-text--light d-inline-block mb-0">Used:&#160;</p>
          <p class="sub-text--light d-inline-block mb-0">
            {{ formatDuration(usedTimeQuota) }}
          </p>
        </div>
        <div class="col col-12 col-sm-6">
          <div class="time-quota__remaining d-inline-block">
            <p class="sub-text--light d-inline-block mb-0">Remaining:&#160;</p>
            <p class="sub-text--light d-inline-block mb-0">
              {{ formatDuration(remainingTimeQuota) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDuration, getDurationInSec } from '@/services/utils/date.js'

export default {
  name: 'TimeQuota',

  props: {
    usedTimeQuota: {
      type: Array,
      require: true
    },

    remainingTimeQuota: {
      type: Array,
      require: true
    }
  },

  computed: {
    timeQuotaPercentage() {
      const used = getDurationInSec(this.usedTimeQuota)
      const remaining = getDurationInSec(this.remainingTimeQuota)
      const total = used + remaining
      if (!total) {
        // Total time quota is 0
        return 0
      }
      return Math.floor((used / total) * 100)
    }
  },

  methods: {
    formatDuration(dateStr) {
      return getDuration(dateStr)
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
