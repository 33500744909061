<template>
  <modal-form
    :modalPopupId="modalPopupId"
    heading="Select assignee"
    @closeModalForm="onResetAssignee">
    <template #form>
      <form class="form" @submit.prevent="onSelectAssignee($event)" novalidate>
        <div class="container-fluid">
          <!-- Assignee select menu -->
          <div class="row mb-3 mt-1">
            <div class="col-12 col-md-2">
              <p class="main-text my-2">Assignee:</p>
            </div>
            <div class="col-12 col-md-10">
              <select
                class="form-select select"
                id="select__assignee"
                v-model="assignee"
                required>
                <option
                  v-for="option in assignees"
                  :value="option"
                  :key="option">
                  {{ option }}
                </option>
              </select>
              <div class="form__invalid-message">
                {{ formError.emptyAssignee }}
              </div>
            </div>
          </div>

          <!-- Confirm and cancel buttons -->
          <div class="mb-2 mt-4">
            <button type="submit" class="button-primary mb-2 me-3">
              Confirm
            </button>

            <button
              type="button"
              class="button-outline"
              :data-close="modalPopupId"
              @click="onResetAssignee">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </template>
  </modal-form>
</template>

<script>
import { formError } from '@/services/message/rpm.js'
import {
  handleInvalidFormElement,
  handleValidFormElement
} from '@/services/utils/form.js'
import ModalForm from '@/components/ModalForm.vue'

export default {
  name: 'SelectAssigneeModal',

  components: {
    ModalForm
  },

  props: {
    modalPopupId: {
      type: String,
      require: true
    },

    assignees: {
      type: Array,
      require: true
    }
  },

  data() {
    return {
      assignee: '',
      formError: formError
    }
  },

  methods: {
    onSelectAssignee(event) {
      // Validate form
      if (this.assignee == '') {
        // No assignee is selected
        event.stopPropagation()
        handleInvalidFormElement(document.getElementById('select__assignee'))
        return
      }
      handleValidFormElement(document.getElementById('select__assignee'))

      // Redirect to assignee data page
      this.$router.push({
        name: 'AssigneeData',
        params: { assignee: this.assignee }
      })
    },

    onResetAssignee() {
      handleValidFormElement(document.getElementById('select__assignee'))
      this.assignee = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_rpm.scss';
</style>
