<script>
import router from '@/router'

export default {
  name: 'WeShare',

  async beforeRouteEnter() {
    window.open('https://dev.weshare.8eyond.sg/index.php/apps/dashboard/')
    router.push({ name: 'Dashboard' })
  }
}
</script>
