const months = [
  {
    name: 'January',
    shortName: 'Jan',
    num: '01'
  },
  {
    name: 'February',
    shortName: 'Feb',
    num: '02'
  },
  {
    name: 'March',
    shortName: 'Mar',
    num: '03'
  },
  {
    name: 'April',
    shortName: 'Apr',
    num: '04'
  },
  {
    name: 'May',
    shortName: 'May',
    num: '05'
  },
  {
    name: 'June',
    shortName: 'Jun',
    num: '06'
  },
  {
    name: 'July',
    shortName: 'Jul',
    num: '07'
  },
  {
    name: 'August',
    shortName: 'Aug',
    num: '08'
  },
  {
    name: 'September',
    shortName: 'Sep',
    num: '09'
  },
  {
    name: 'Ocotber',
    shortName: 'Oct',
    num: '10'
  },
  {
    name: 'November',
    shortName: 'Nov',
    num: '11'
  },
  {
    name: 'December',
    shortName: 'Dec',
    num: '12'
  }
]

/**********************
 * Return date object *
 **********************/

function getTodayDateObj() {
  return new Date()
}

function getTomorrowDateObj() {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  return tomorrow
}

function getEndOfWeekDateObj() {
  const curr = new Date()
  const endOfWeek = new Date()
  endOfWeek.setDate(curr.getDate() - (curr.getDay() - 1) + 6)
  return endOfWeek
}

function getEndOfMonthDateObj() {
  const endOfMonth = new Date()
  endOfMonth.setMonth(endOfMonth.getMonth() + 1)
  endOfMonth.setDate(0)
  return endOfMonth
}

/*********************
 * Return ISO string *
 *********************/

function getIsoStr(htmlDate, htmlTime) {
  // Arguments: "2022-12-31", "23:59"
  // Return: "2022-12-31T15:59:00.000Z"
  // Note that string returned is GMT+0, SG is GMT+8 (11:59pm)
  return new Date(htmlDate + ' ' + htmlTime).toISOString()
}

function getStartOfDayIsoStr(dateObj) {
  // Argument type: Javascript date object
  // Return: "2022-12-30T16:00:00.000Z"
  // Note that string returned is GMT+0, SG is GMT+8 (12:00am)
  dateObj.setHours(0, 0, 0, 0)
  return dateObj.toISOString()
}

function getEndOfDayIsoStr(dateObj) {
  // Argument type: Javascript date object
  // Return: "2022-12-31T15:59:59.999Z"
  // Note that string returned is GMT+0, SG is GMT+8 (11:59pm)
  dateObj.setHours(23, 59, 59, 999)
  return dateObj.toISOString()
}

/************************
 * Return locale string *
 ************************/

function getDateLocaleStr(isoStr) {
  // Argument: "2022-12-31T16:00:00.000Z" (GMT+0)
  // Return: "1 Jan 2023"
  return new Date(isoStr).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  })
}

function getTimeLocaleStr(isoStr) {
  // Argument: "2022-12-31T04:00:00.000Z" (GMT+0)
  // Return: "12:00 PM"
  return new Date(isoStr).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
}

/**********************
 * Return HTML object *
 **********************/

function getHtmlMonth(isoStr) {
  // Argument: "2022-12-31T16:00:00.000Z" (GMT+0)
  // Return: "2023-01"
  const dateObj = new Date(isoStr)
  const year = dateObj.getFullYear()
  var month = dateObj.getMonth() + 1
  if (month < 10) {
    month = '0' + month
  }
  return year + '-' + month
}

function getHtmlDate(isoStr) {
  // Argument: "2022-12-31T16:00:00.000Z" (GMT+0)
  // Return: "2023-01-01"
  const dateObj = new Date(isoStr)
  var dayOfMonth = dateObj.getDate()
  if (dayOfMonth < 10) {
    dayOfMonth = '0' + dayOfMonth
  }
  return getHtmlMonth(isoStr) + '-' + dayOfMonth
}

function getHtmlTime(isoStr) {
  // Argument: "2022-12-31T16:00:00.000Z" (GMT+0)
  // Return: "00:00"
  return new Date(isoStr).toLocaleTimeString('en-US', {
    timeStyle: 'short',
    hour12: false
  })
}

function getCurrentHtmlMonth() {
  return getHtmlMonth(new Date().toISOString())
}

function getCurrentHtmlDate() {
  return getHtmlDate(new Date().toISOString())
}

function getCurrentHtmlTime() {
  return getHtmlTime(new Date().toISOString())
}

function getLastDayOfHtmlMonth(htmlDate) {
  // Argument: "2022-12"
  // Return: "2022-12-31"
  const dateObj = new Date(htmlDate)
  const newDateObj = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0)
  return getHtmlDate(newDateObj.toISOString())
}

/************************
 * HTML time comparison *
 ************************/

function isStartDatetimeAfterCurrentDatetime(startHtmlDate, startHtmlTime) {
  const start = new Date(startHtmlDate + ' ' + startHtmlTime)
  const current = new Date()
  return current.getTime() < start.getTime()
}

function isStartDatetimeBeforeEndDatetime(
  startHtmlDate,
  startHtmlTime,
  endHtmlDate,
  endHtmlTime
) {
  const start = new Date(startHtmlDate + ' ' + startHtmlTime)
  const end = new Date(endHtmlDate + ' ' + endHtmlTime)
  return start.getTime() < end.getTime()
}

/******************************
 * Return duration from array *
 ******************************/

function getDuration(durationArr) {
  // Example: [1, 30, 10.23] -> 1 hr 30 min 10 sec
  // Example: [1, 0, 5.67] -> 1 hr 5 sec
  const hour = durationArr[0]
  const minute = durationArr[1]
  const second = Math.floor(durationArr[2])

  if (hour == 0 && minute == 0 && second == 0) {
    return '0 hr'
  }
  var hourStr = hour > 0 ? hour + ' hr ' : ''
  var minuteStr = minute > 0 ? minute + ' min ' : ''
  var secondStr = second > 0 ? second + ' sec' : ''
  return hourStr + minuteStr + secondStr
}

function getDurationInSec(durationArr) {
  // Example: [1, 30, 10.23] -> 5410.23 sec
  return (
    durationArr[0] * 3600 + durationArr[1] * 60 + Math.floor(durationArr[2])
  )
}

export {
  months,
  getTodayDateObj,
  getTomorrowDateObj,
  getEndOfWeekDateObj,
  getEndOfMonthDateObj,
  getIsoStr,
  getStartOfDayIsoStr,
  getEndOfDayIsoStr,
  getDateLocaleStr,
  getTimeLocaleStr,
  getHtmlMonth,
  getHtmlDate,
  getHtmlTime,
  getCurrentHtmlMonth,
  getCurrentHtmlDate,
  getCurrentHtmlTime,
  getLastDayOfHtmlMonth,
  isStartDatetimeAfterCurrentDatetime,
  isStartDatetimeBeforeEndDatetime,
  getDuration,
  getDurationInSec
}
