// weMEET messages

const weMeetMessage = {
  noRoom:
    'You do not have any meeting room available. Please schedule a meeting to create a room.',
  noRoomLastMeeting: 'No meeting occurred for this room.',
  noRoomUpcomingMeeting: 'There is no upcoming meeting for this room.',
  noParticipants:
    "You have not invited anyone yet. Add a participant's email to start inviting!",
  noRecording: 'You do not have any recordings available.',
  noLog: 'You have not joined any meeting before.'
}

function getNoUpcomingMeetingMessage(periodStr) {
  return 'You do not have any upcoming meetings for ' + periodStr + '.'
}

function getNoRoomRecordingMessage(roomStr) {
  return 'You do not have any recording available for ' + roomStr + '.'
}

function getNoRoomLogMessage(roomStr) {
  return 'You have not joined any meeting from ' + roomStr + '.'
}

const roomInputError = {
  emptyRoomName: 'Please enter a room name.'
}

const meetingInputError = {
  emptyMeetingName: 'Please enter a meeting name.',
  emptyRecurringValue: 'Please select a recurring value.',
  emptyDate: 'Please enter a valid date.',
  emptyTime: 'Please enter a valid time.',
  invalidStartDateAndTime:
    'The meeting start date and time should be after the current date and time.',
  invalidEndDateAndTime:
    'The meeting end date and time should be after the start date and time.',
  invalidParticipantEmail: "Please enter a valid participant's email."
}

const inviteMeetingError = {
  invalidDisplayName:
    'The display name should contain only alphabetical letters with a maximum of one space between words.',
  meetingNotStarted: 'Waiting for host to start meeting...',
  invalidAccessCode: 'Invalid access code',
  joinMeetingError: 'Unable to start meeting'
}

export {
  weMeetMessage,
  getNoUpcomingMeetingMessage,
  getNoRoomRecordingMessage,
  getNoRoomLogMessage,
  roomInputError,
  meetingInputError,
  inviteMeetingError
}
