<template>
  <spinner />

  <div class="container-fluid">
    <div class="row vh-100 vw-100 m-0">
      <div class="we-meet-invite-form__col col-12 m-auto">
        <!-- weMEEt Logo -->
        <img
          class="d-block mb-4 mx-auto"
          src="@/assets/images/we-meet-logo.svg" />

        <!-- Meeting not started message  -->
        <div v-if="!hasMeetingStartedBool">
          <p class="heading-one text-center mb-4">
            {{ inviteMeetingError.meetingNotStarted }}
          </p>
        </div>

        <!-- Other errors message -->
        <div v-if="hasOtherErrorsBool">
          <p class="heading-one text-center mb-4">
            &#10007;&#160;&#160;&#160;{{ inviteMeetingError.joinMeetingError }}
          </p>
        </div>

        <div
          v-show="displayFormContainer"
          class="we-meet-invite-form__container p-5"
          id="we-meet-invite-form__container">
          <!-- Meeting details -->
          <div v-show="hasMeetingDetails" class="mb-3">
            <p class="main-text text-center mx-auto">
              Join {{ meetingHostNameStr }}'s Meeting
            </p>
            <hr class="we-meet-invite-form__divider my-4" />
            <p class="heading-one text-center mb-1">{{ meetingNameStr }}</p>
            <p class="main-text text-center mb-0">
              {{ meetingDateAndTimeStr }}
            </p>
          </div>

          <!-- Form -->
          <form class="form" @submit.prevent="onJoinMeeting($event)" novalidate>
            <!-- Form - Access code input -->
            <div v-if="isAccessCodeRequiredBool" class="mb-4">
              <label
                for="we-meet-invite-form__access-code-input"
                class="form-label sub-text--light">
                Access Code
              </label>
              <input
                type="text"
                class="form-control input"
                id="we-meet-invite-form__access-code-input"
                v-model="accessCode"
                placeholder="Enter Meeting Access Code"
                required />
              <div class="form__invalid-message">
                {{ inviteMeetingError.invalidAccessCode }}
              </div>
            </div>

            <div
              v-if="isAccessCodeRequiredBool"
              class="we-meet-invite-form__join-meeting-container">
              <!-- Form - Join meeting button -->
              <button type="submit" class="input-group button-primary">
                Join Meeting
              </button>
            </div>
          </form>
        </div>

        <p
          v-if="hasOtherErrorsBool"
          class="back-nav main-text text-center mb-0 mt-4"
          @click="onNavigateBackward">
          &#8592;&#160;&#160;&#160;Back
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getInviteDetails, joinMeetingAsGuest } from '@/services/api/we-meet.js'
import { inviteMeetingError } from '@/services/message/we-meet.js'
import { getDateLocaleStr, getTimeLocaleStr } from '@/services/utils/date.js'
import {
  handleInvalidFormElement,
  initialiseInputElements,
  cleanUpInputElements,
  areValidInputs
} from '@/services/utils/form.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'MeetingLobby',

  components: {
    Spinner
  },

  props: {
    roomId: {
      type: String,
      require: true
    },

    userId: {
      type: String,
      require: false,
      default: ''
    },

    displayName: {
      type: String,
      require: true
    },

    meetingHostName: {
      type: String,
      require: false
    },

    meetingName: {
      type: String,
      require: false
    },

    meetingDateAndTime: {
      type: String,
      require: false
    },

    // Boolean values are in strings as Vue programmatic navigation
    // passes parameters as String

    hasMeetingStarted: {
      type: String,
      require: false,
      default: 'false'
    },

    isAccessCodeRequired: {
      type: String,
      require: false,
      default: 'false'
    },

    hasOtherErrors: {
      type: String,
      require: false,
      default: 'false'
    }
  },

  data() {
    return {
      hasMeetingDetails: false,
      meetingHostNameStr: this.meetingHostName,
      meetingNameStr: this.meetingName,
      meetingDateAndTimeStr: this.meetingDateAndTime,
      hasMeetingStartedBool: this.hasMeetingStarted === 'true',
      isAccessCodeRequiredBool: this.isAccessCodeRequired === 'true',
      accessCode: '',
      isAccessCodeValid: true,
      hasOtherErrorsBool: this.hasOtherErrors === 'true',
      inviteMeetingError: inviteMeetingError
    }
  },

  computed: {
    displayFormContainer() {
      return this.hasMeetingDetails || this.isAccessCodeRequiredBool
    }
  },

  async mounted() {
    initialiseInputElements()
    if (
      !this.meetingHostNameStr ||
      !this.meetingNameStr.length ||
      !this.meetingDateAndTimeStr.length
    ) {
      // Call get meeting invite details API
      // if no meeting details are provided by MeetingInvite component
      try {
        showSpinner()
        const res = await getInviteDetails(this.roomId, this.userId)
        hideSpinner()
        this.meetingHostNameStr = res.data.host
        this.meetingNameStr = res.data.meetingName
        this.meetingDateAndTimeStr =
          getDateLocaleStr(res.data.startTime) +
          ', ' +
          getTimeLocaleStr(res.data.startTime) +
          ' - ' +
          getTimeLocaleStr(res.data.endTime)
        if (
          this.meetingHostNameStr &&
          this.meetingNameStr &&
          this.meetingDateAndTimeStr
        ) {
          this.hasMeetingDetails = true
        }
      } catch (e) {
        hideSpinner()
        this.hasMeetingDetails = false
      }
    } else {
      this.hasMeetingDetails = true
    }

    // Try joining meeting again if meeting has not started
    if (!this.hasMeetingStartedBool) {
      await this.onJoinMeetingAsGuest()
    }
  },

  beforeUnmount() {
    cleanUpInputElements()
  },

  methods: {
    async onJoinMeeting(event) {
      // Reset access code input validity
      this.isAccessCodeValid = true
      document
        .getElementById('we-meet-invite-form__access-code-input')
        .setCustomValidity('')

      // Validate form
      if (!areValidInputs('we-meet-invite-form__container')) {
        event.stopPropagation()
        return
      }

      // Call join meeting as guest API
      await this.onJoinMeetingAsGuest()
    },

    async onJoinMeetingAsGuest() {
      // Call join meeting as guest API
      try {
        showSpinner()
        const res = await joinMeetingAsGuest(
          this.roomId,
          this.userId,
          this.displayName,
          this.accessCode
        )
        hideSpinner()

        // Open meeting URL
        window.location.replace(res.data.WeMeetRoomURL)
      } catch (e) {
        hideSpinner()
        const statusCode = e.response.status
        if (statusCode === 406) {
          this.hasMeetingStartedBool = false
          this.isAccessCodeValid = true
          this.isAccessCodeRequiredBool = false
          this.hasOtherErrorsBool = false

          // Wait for 5 seconds before trying to join meeting as guest again
          await new Promise((res) => setTimeout(res, 5000))
          this.onJoinMeetingAsGuest()
        } else if (statusCode === 403) {
          this.hasMeetingStartedBool = true
          this.isAccessCodeValid = false
          this.isAccessCodeRequiredBool = true
          this.hasOtherErrorsBool = false

          const accessCodeInputElement = document.getElementById(
            'we-meet-invite-form__access-code-input'
          )
          handleInvalidFormElement(accessCodeInputElement)
        } else {
          this.hasMeetingStartedBool = true
          this.isAccessCodeValid = true
          this.isAccessCodeRequiredBool = false
          this.hasOtherErrorsBool = true
        }
      }
    },

    onNavigateBackward() {
      this.$router.push({
        name: 'MeetingInvite',
        params: { roomId: this.roomId }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
