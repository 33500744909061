<template>
  <div class="modal-popup" :id="`${modalPopupId}`">
    <div class="modal-popup__content p-4">
      <!-- Close button -->
      <div>
        <img
          src="@/assets/icons/close.svg"
          class="modal-popup__close img-fluid icon"
          :data-close="modalPopupId"
          @click="$emit('closeModalPopup')" />
      </div>

      <slot :modalPopupId="modalPopupId" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalPopup',

  props: {
    modalPopupId: {
      type: String,
      require: true
    }
  },

  emits: ['closeModalPopup']
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/components/_modal.scss';
</style>
