<script>
import router from '@/router'
import { getWeLearnUrl } from '@/services/api/we-learn.js'

export default {
  name: 'WeLearn',

  async beforeRouteEnter() {
    try {
      // Call get weLEARN URL API
      const res = await getWeLearnUrl()

      // Open weLEARN URL in new tab
      window.open(res.data.loginURL)

      // Redirect 8eyond suite (current tab) to dashboard page
      router.push({ name: 'Dashboard' })
    } catch (e) {
      console.log(e) // TODO: error handling
    }
  }
}
</script>
