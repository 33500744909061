<template>
  <spinner />

  <!-- Heading -->
  <p class="main-heading d-inline-block mb-4">Users</p>

  <br class="users-table--narrow" />

  <!-- Create and delete users buttons -->
  <div class="users-table__buttons d-inline-block">
    <button
      type="button"
      class="button-primary mb-4 me-3"
      :class="{ 'd-none': numOfSelectedUsers <= 1 }"
      data-open="deleteBatchModal">
      &#45; Delete Users
    </button>

    <button type="button" class="button-primary mb-4" @click="onCreateUser">
      &#43; Create User
    </button>
  </div>

  <!-- Modal - Delete a batch of users -->
  <delete-batch-modal
    modalPopupId="deleteBatchModal"
    :usersIds="selectedUsersIds"
    :numOfUsers="numOfSelectedUsers"
    :usersIndices="selectedUsersIndices"
    @confirmDelete="onConfirmDelete" />

  <!-- List user error message -->
  <p v-if="listUserErrorMessage" class="error-message">
    {{ listUserErrorMessage }}
  </p>

  <div
    v-if="!listUserErrorMessage"
    class="users scroll-view"
    :class="{ 'users--short': numOfSelectedUsers > 1 }">
    <!-- Users table -->
    <selectable-table :items="users" @rowsSelectedChange="onSelectUsers">
      <!-- Users table - Header row -->
      <template #header>
        <th class="users-table__hide-col">Username</th>
        <th>Full name</th>
        <th class="users-table__hide-col">Email</th>
        <th class="users-table__hide-col--narrow">Access</th>
        <th>Action</th>
      </template>

      <!-- Users table - User row -->
      <template #item="{ item: user, index }">
        <!-- Users table - User row - Username, Name and Email -->
        <td class="users-table__hide-col">{{ user.username }}</td>
        <td>{{ user.firstName }} {{ user.lastName }}</td>
        <td class="users-table__hide-col">{{ user.email }}</td>

        <!-- Users table - User row - Application access -->
        <td class="users-table__hide-col--narrow">
          <div
            v-for="(applicationName, index) in nonDashboardApplicationNames"
            :key="applicationName">
            <div v-if="getApplicationAccessibility(user)[index]">
              <p class="users-table__application-access">
                {{ applicationName }}
              </p>
            </div>
          </div>
        </td>

        <td>
          <div class="d-flex">
            <!-- Users table - User row - Update user button -->
            <div class="icon__container me-3" @click="onUpdateUser(user)">
              <img
                src="@/assets/icons/edit.svg"
                class="img-fluid icon icon--expand" />
            </div>

            <!-- Users table - User row - Delete user button -->
            <div
              class="icon__container"
              :data-open="`deleteIndividualModal${user._id}`">
              <img
                src="@/assets/icons/trash.svg"
                class="img-fluid icon icon--expand" />
            </div>

            <!-- Modal - Delete an individual -->
            <delete-individual-modal
              :modalPopupId="`deleteIndividualModal${user._id}`"
              :userId="user._id"
              :userName="user.firstName + ' ' + user.lastName"
              :userIndex="index"
              @confirmDelete="onConfirmDelete" />
          </div>
        </td>
      </template>
    </selectable-table>

    <!-- Pagination -->
    <pagination
      class="float-end"
      :currentPage="currentPage"
      :totalNumOfPages="numOfPages"
      @pageChange="onDisplayPage" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { listUsers } from '@/services/api/admin.js'
import { adminMessage } from '@/services/message/admin.js'
import {
  initialiseModalPopups,
  cleanUpModalPopups
} from '@/services/utils/modal.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import DeleteBatchModal from '@/views/admin/delete-modals/DeleteBatchModal.vue'
import DeleteIndividualModal from '@/views/admin/delete-modals/DeleteIndividualModal.vue'
import Pagination from '@/components/Pagination.vue'
import SelectableTable from '@/components/SelectableTable.vue'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'UserList',

  components: {
    DeleteBatchModal,
    DeleteIndividualModal,
    Pagination,
    SelectableTable,
    Spinner
  },

  data() {
    return {
      perPage: 10,
      currentPage: 1,
      numOfPages: 1,
      numOfUsers: 0, // number of users for CURRENT page
      numOfSelectedUsers: 0,
      users: [],
      usersSelectedFlags: [],
      listUserErrorMessage: '',
      adminMessage: adminMessage
    }
  },

  computed: {
    ...mapGetters({
      nonDashboardApplicationNames:
        'application/getNonDashboardApplicationNames'
    }),

    selectedUsersIds() {
      var usersIds = []
      for (var i = 0; i < this.numOfUsers; i++) {
        if (this.usersSelectedFlags[i]) {
          usersIds.push(this.users[i]._id)
        }
      }
      return usersIds
    },

    selectedUsersIndices() {
      var usersIndices = []
      for (var i = 0; i < this.numOfUsers; i++) {
        if (this.usersSelectedFlags[i]) {
          usersIndices.push(i)
        }
      }
      return usersIndices
    }
  },

  async mounted() {
    await this.listAllUsers()
    initialiseModalPopups()
  },

  beforeUnmount() {
    cleanUpModalPopups()
  },

  methods: {
    async listAllUsers() {
      // Reset list user error message
      this.listUserErrorMessage = ''

      try {
        // Call list users API
        showSpinner()
        const res = await listUsers(this.perPage, this.currentPage)
        hideSpinner()
        this.numOfPages = res.data.totalPages
        this.users = res.data.users
        this.numOfUsers = this.users.length

        // Check for no user
        if (this.numOfUsers) {
          this.numOfSelectedUsers = 0
          this.usersSelectedFlags = Array(this.numOfUsers).fill(false)
        } else {
          this.listUserErrorMessage = adminMessage.noUser
        }
      } catch (e) {
        hideSpinner()
        this.listUserErrorMessage = e.response.data.message
      }
    },

    onCreateUser() {
      this.$router.push({ name: 'UserCreate' })
    },

    onSelectUsers(usersSelectedFlags, numOfSelectedUsers) {
      this.usersSelectedFlags = usersSelectedFlags
      this.numOfSelectedUsers = numOfSelectedUsers
    },

    getApplicationAccessibility(user) {
      return [
        user.admin,
        user.rpm,
        user.WeLearn,
        user.WeMeet,
        user.WePlan,
        user.WeShare
      ]
    },

    onUpdateUser(user) {
      this.$router.push({
        name: 'UserUpdate',
        params: {
          userId: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          email: user.email,
          applicationAccessFlags: this.getApplicationAccessibility(user)
        }
      })
    },

    onConfirmDelete(numOfUsersDeleted, selectedUsersIndices) {
      // Remove users from page starting from highest index
      for (var i = numOfUsersDeleted; i > 0; i--) {
        this.users.splice(selectedUsersIndices[i], 1)
      }

      // Display remaining users if any
      this.numOfUsers -= numOfUsersDeleted
      if (this.numOfUsers) {
        this.onDisplayPage(this.currentPage)
      } else {
        // All users in current page have been deleted
        this.onDisplayPage(1)
      }
    },

    async onDisplayPage(newPage) {
      this.currentPage = newPage
      await this.listAllUsers()
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_admin.scss';
</style>
