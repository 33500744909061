import { instance } from './global.js'
import store from '@/store/index.js'

function listRooms() {
  return instance.get('/weMEET/allRooms', {
    params: {
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

function createRoom(
  roomName,
  welcomeMessage,
  isRoomLocked,
  isApprovalRequired,
  areUsersMuted,
  isMeetingRecorded
) {
  return instance.post('/weMEET/createRoom', {
    name: roomName,
    ...(welcomeMessage ? { welcome: welcomeMessage } : {}),
    lockedRoom: isRoomLocked,
    approvalRequired: isApprovalRequired,
    muteOnStart: areUsersMuted,
    autoStartRecording: isMeetingRecorded,
    beyondToken: store.getters['auth/getBeyondToken']
  })
}

function scheduleOneTimeMeeting(
  meetingName,
  participants,
  requireEmailInvitation,
  startDateStr,
  endDateStr,
  welcomeMessage,
  isRoomLocked,
  isApprovalRequired,
  areUsersMuted,
  isMeetingRecorded
) {
  return instance.post('/schedule', {
    meetingName: meetingName,
    participants: participants,
    sendEmail: requireEmailInvitation,
    subject: '',
    description: '',
    startTime: startDateStr,
    endTime: endDateStr,
    oneOff: true,
    name: meetingName,
    ...(welcomeMessage ? { welcome: welcomeMessage } : {}),
    lockedRoom: isRoomLocked,
    approvalRequired: isApprovalRequired,
    muteOnStart: areUsersMuted,
    autoStartRecording: isMeetingRecorded,
    beyondToken: store.getters['auth/getBeyondToken']
  })
}

function scheduleMeetingUnderRoom(
  meetingName,
  participants,
  requireEmailInvitation,
  isRecurring,
  recurringValuesOneHotEncoding,
  startDateStr,
  endDateStr,
  roomId
) {
  return instance.post('/schedule', {
    meetingName: meetingName,
    participants: participants,
    sendEmail: requireEmailInvitation,
    subject: '',
    description: '',
    recurring: isRecurring,
    recurringType: recurringValuesOneHotEncoding,
    startTime: startDateStr,
    endTime: endDateStr,
    oneOff: false,
    roomID: roomId,
    beyondToken: store.getters['auth/getBeyondToken']
  })
}

function getMeetingLogs(roomId) {
  return instance.get('/weMEET/roomLogs', {
    params: {
      ...(roomId ? { roomID: roomId } : {}),
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

function getMeetingRecordings(roomId) {
  return instance.get('/weMEET/recordings', {
    params: {
      ...(roomId ? { roomID: roomId } : {}),
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

function getUpcomingMeetingsBetweenDates(startDateStr, endDateStr) {
  return instance.get('/schedule', {
    params: {
      start: startDateStr,
      end: endDateStr,
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

function getInviteDetails(roomId, userId) {
  return instance.get('/weMEET/inviteDetails', {
    params: {
      roomID: roomId,
      ...(userId ? { userID: userId } : {})
    }
  })
}

function joinMeeting(roomId, meetingId, isOneTimeMeeting) {
  return instance.get('/weMEET/joinRoom', {
    params: {
      roomID: roomId,
      meetingID: meetingId,
      oneOff: isOneTimeMeeting,
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

function joinMeetingAsGuest(roomId, userId, displayName, accessCode) {
  return instance.get('/weMEET/guestJoin', {
    params: {
      roomID: roomId,
      ...(userId ? { userID: userId } : {}),
      userName: displayName,
      accessCode: accessCode
    }
  })
}

function updateRoom(
  roomId,
  roomName,
  welcomeMessage,
  isRoomLocked,
  isApprovalRequired,
  areUsersMuted,
  isMeetingRecorded
) {
  return instance.put(`/weMEET/room/${roomId}`, {
    name: roomName,
    ...(welcomeMessage ? { welcome: welcomeMessage } : {}),
    lockedRoom: isRoomLocked,
    approvalRequired: isApprovalRequired,
    muteOnStart: areUsersMuted,
    autoStartRecording: isMeetingRecorded,
    beyondToken: store.getters['auth/getBeyondToken']
  })
}

function updateOneTimeMeeting(
  meetingId,
  meetingName,
  startDateStr,
  endDateStr,
  isRoomLocked,
  isApprovalRequired,
  areUsersMuted,
  isMeetingRecorded
) {
  return instance.put(`/schedule/${meetingId}`, {
    meetingName: meetingName,
    startTime: startDateStr,
    endTime: endDateStr,
    lockedRoom: isRoomLocked,
    approvalRequired: isApprovalRequired,
    muteOnStart: areUsersMuted,
    autoStartRecording: isMeetingRecorded,
    beyondToken: store.getters['auth/getBeyondToken']
  })
}

function updateMeeting(meetingId, meetingName, startDateStr, endDateStr) {
  return instance.put(`/schedule/${meetingId}`, {
    meetingName: meetingName,
    startTime: startDateStr,
    endTime: endDateStr,
    beyondToken: store.getters['auth/getBeyondToken']
  })
}

function updateRecordingVisibility(meetingId, newVisibility) {
  return instance.put('/weMEET/visibility', {
    meetingLogDatabaseID: meetingId,
    visibility: newVisibility,
    beyondToken: store.getters['auth/getBeyondToken']
  })
}

function sendEmailInvitation(roomId, participants, requireEmailInvitation) {
  return instance.put(`/schedule/${roomId}`, {
    isRoomID: true,
    participants: participants,
    resend: requireEmailInvitation,
    beyondToken: store.getters['auth/getBeyondToken']
  })
}

function deleteRoom(roomId) {
  return instance.delete(`/weMEET/room/${roomId}`, {
    params: {
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

function deleteMeeting(meetingId, isDeleteRecurring) {
  return instance.delete(`/schedule/${meetingId}`, {
    params: {
      deleteRecurringMeetings: isDeleteRecurring,
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

export {
  listRooms,
  createRoom,
  scheduleOneTimeMeeting,
  scheduleMeetingUnderRoom,
  getMeetingLogs,
  getMeetingRecordings,
  getUpcomingMeetingsBetweenDates,
  getInviteDetails,
  joinMeeting,
  joinMeetingAsGuest,
  updateRoom,
  updateOneTimeMeeting,
  updateMeeting,
  updateRecordingVisibility,
  sendEmailInvitation,
  deleteRoom,
  deleteMeeting
}
