<template>
  <modal-view
    :modalPopupId="modalPopupId"
    image="trash-bin.svg"
    mainHeading="Confirm Delete?"
    :subHeading="subHeading"
    :errorMessage="deleteUsersErrorMessage" />
</template>

<script>
import { deleteUser } from '@/services/api/admin.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import ModalView from '@/components/ModalView.vue'

export default {
  name: 'DeleteBatchModal',

  components: {
    ModalView
  },

  props: {
    modalPopupId: {
      type: String,
      require: true
    },

    usersIds: {
      type: Array,
      require: true
    },

    numOfUsers: {
      type: Number,
      require: true
    },

    usersIndices: {
      type: Array,
      require: true
    }
  },

  emits: ['confirmDelete'],

  data() {
    return {
      deleteUsersErrorMessage: ''
    }
  },

  computed: {
    subHeading() {
      // React to changes in number of selected users to be deleted
      return 'You are deleting ' + this.numOfUsers + ' user profiles.'
    }
  },

  mounted() {
    document
      .getElementById('deleteBatchModal')
      .querySelector('.modal-view__confirm')
      .addEventListener('click', () => {
        this.onDeleteUsers()
      })
  },

  beforeUnmount() {
    document
      .getElementById('deleteBatchModal')
      .querySelector('.modal-view__confirm')
      .removeEventListener('click', () => {
        this.onDeleteUsers()
      })
  },

  methods: {
    async onDeleteUsers() {
      try {
        // Delete users starting from highest index
        showSpinner()
        var numOfUsersDeleted = 0
        for (var i = this.numOfUsers - 1; i >= 0; i--) {
          // Call delete user API
          await deleteUser(this.usersIds[i])
          numOfUsersDeleted++
        }
        hideSpinner()

        // Emit event to UserList component
        this.$emit('confirmDelete', numOfUsersDeleted, this.usersIndices)
      } catch (e) {
        hideSpinner()
        this.deleteUsersErrorMessage = e.response.data.message
      }
    }
  }
}
</script>
