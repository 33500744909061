<template>
  <modal-popup>
    <template #default="{ modalPopupId }">
      <div class="modal-view">
        <!-- Image -->
        <img :src="getModalImage()" class="modal-view__image img-fluid mb-3" />

        <!-- Headings -->
        <p class="heading-one">{{ mainHeading }}</p>
        <p class="main-text--light">{{ subHeading }}</p>

        <!-- Error message -->
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>

        <!-- Confirm and cancel buttons -->
        <button
          type="button"
          class="modal-view__confirm button-primary mb-3"
          @click="onConfirmModalView(modalPopupId)">
          Confirm
        </button>

        <br />

        <button type="button" class="button-outline" :data-close="modalPopupId">
          Cancel
        </button>
      </div>
    </template>
  </modal-popup>
</template>

<script>
import { closeModalPopup } from '@/services/utils/modal.js'
import ModalPopup from '@/components/ModalPopup.vue'

export default {
  name: 'ModalView',

  components: {
    ModalPopup
  },

  props: {
    image: {
      type: String,
      require: false
    },

    mainHeading: {
      type: String,
      require: false
    },

    subHeading: {
      type: String,
      require: false
    },

    errorMessage: {
      type: String,
      require: false
    }
  },

  methods: {
    getModalImage() {
      return require('@/assets/images/' + this.image)
    },

    onConfirmModalView(modalPopupId) {
      // Logic for confirming a particular modal view
      // is implemented in components that use ModalView.
      closeModalPopup(modalPopupId)
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/components/_modal.scss';
</style>
