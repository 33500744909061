<template>
  <modal-popup @closeModalPopup="$emit('closeModalForm')">
    <template #default>
      <div class="modal-form">
        <!-- Heading -->
        <p class="heading-one">{{ heading }}</p>

        <div class="scroll-view">
          <!-- Form slot -->
          <slot name="form" />
        </div>
      </div>
    </template>
  </modal-popup>
</template>

<script>
import ModalPopup from '@/components/ModalPopup.vue'

export default {
  name: 'ModalForm',

  components: {
    ModalPopup
  },

  props: {
    heading: {
      type: String,
      require: false
    }
  },

  emits: ['closeModalForm']
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/components/_modal.scss';
</style>
