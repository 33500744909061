<template>
  <modal-form
    :modalPopupId="modalPopupId"
    heading="Edit Meeting"
    @closeModalForm="onCloseModalForm">
    <template #form>
      <form
        class="form"
        @keypress.enter.prevent
        @keyup.enter.prevent
        @submit.prevent="onEditMeeting($event)"
        novalidate>
        <div class="container-fluid">
          <div class="row mb-4">
            <div class="col">
              <label
                for="we-meet-form__meeting-name-input"
                class="form-label sub-text--light">
                Meeting Name
              </label>
              <input
                type="text"
                class="form-control input"
                id="we-meet-form__meeting-name-input"
                v-model="meetingNameStr"
                required />
              <div class="form__invalid-message">
                {{ meetingInputError.emptyMeetingName }}
              </div>
            </div>
          </div>

          <div class="row row-cols-1 row-cols-lg-2">
            <!-- Meeting start date and time inputs -->
            <div class="col">
              <label
                for="we-meet-form__start-date-input"
                class="form-label sub-text--light">
                Meeting Start
              </label>

              <div class="row">
                <div class="col-12 col-sm-6 mb-2">
                  <input
                    type="date"
                    class="form-control input--dark"
                    id="we-meet-form__start-date-input"
                    v-model="startDate"
                    :disabled="isRecurring"
                    required />
                  <div class="form__invalid-message">
                    {{ meetingInputError.emptyDate }}
                  </div>
                </div>

                <div class="col-12 col-sm-6 mb-2">
                  <input
                    type="time"
                    class="form-control input--dark"
                    id="we-meet-form__start-time-input"
                    v-model="startTime"
                    :disabled="isRecurring"
                    required />
                  <div class="form__invalid-message">
                    {{ meetingInputError.emptyTime }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Meeting end date and time inputs -->
            <div class="col">
              <label
                for="we-meet-form__end-date-input"
                class="form-label sub-text--light">
                Meeting End
              </label>

              <div class="row">
                <div class="col-12 col-sm-6 mb-2">
                  <input
                    type="date"
                    class="form-control input--dark"
                    id="we-meet-form__end-date-input"
                    v-model="endDate"
                    :disabled="isRecurring"
                    required />
                  <div class="form__invalid-message">
                    {{ meetingInputError.emptyDate }}
                  </div>
                </div>

                <div class="col-12 col-sm-6 mb-2">
                  <input
                    type="time"
                    class="form-control input--dark"
                    id="we-meet-form__end-time-input"
                    v-model="endTime"
                    :disabled="isRecurring"
                    required />
                  <div class="form__invalid-message">
                    {{ meetingInputError.emptyTime }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Start and end datetime error message -->
            <p v-if="dateAndTimeErrorMessage" class="error-message mt-2">
              {{ dateAndTimeErrorMessage }}
            </p>
          </div>

          <!-- Settings for one-time meeting -->
          <div v-if="isOneTimeMeeting" class="row mt-4">
            <div class="col">
              <p class="sub-text mb-2">Settings</p>

              <!-- Settings container -->
              <div class="we-meet-form__settings-container p-3">
                <!-- Settings container - Access select menu -->
                <label
                  for="we-meet-form__access-value"
                  class="form-label sub-text--light mb-2 me-2">
                  Access
                </label>
                <select
                  class="form-select select d-inline-block mb-2 py-1"
                  id="we-meet-form__access-value"
                  v-model="isRoomLockedBool">
                  <option
                    v-for="option in accessOptions"
                    :value="option.boolean"
                    :key="option.name">
                    {{ option.name }}
                  </option>
                </select>

                <!-- Settings container - Require approval switch -->
                <div class="form-check form-switch mb-2">
                  <input
                    type="checkbox"
                    role="switch"
                    class="form-check-input"
                    id="we-meet-form__require-approval-switch"
                    v-model="isApprovalRequiredBool" />
                  <label
                    class="form-check-label sub-text"
                    for="we-meet-form__require-approval-switch">
                    Require approval to join
                  </label>
                </div>

                <!-- Settings container - Mute users switch -->
                <div class="form-check form-switch mb-2">
                  <input
                    type="checkbox"
                    role="switch"
                    class="form-check-input"
                    id="we-meet-form__mute-users-switch"
                    v-model="areUsersMutedBool" />
                  <label
                    class="form-check-label sub-text"
                    for="we-meet-form__mute-users-switch">
                    Mute all users
                  </label>
                </div>

                <!-- Settings container - Record meetings switch -->
                <div class="form-check form-switch">
                  <input
                    type="checkbox"
                    role="switch"
                    class="form-check-input"
                    id="we-meet-form__record-meetings-switch"
                    v-model="isMeetingRecordedBool" />
                  <label
                    class="form-check-label sub-text"
                    for="we-meet-form__record-meetings-switch">
                    Record Meetings
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- Edit meeting error message -->
          <p v-if="editMeetingErrorMessage" class="error-message mt-4">
            {{ editMeetingErrorMessage }}
          </p>
        </div>

        <!-- Confirm and cancel buttons -->
        <div class="mb-2 mt-4">
          <button type="submit" class="button-primary mb-2 me-3">
            Confirm
          </button>

          <button
            type="button"
            class="button-outline"
            :data-close="modalPopupId"
            @click="onCloseModalForm">
            Cancel
          </button>
        </div>
      </form>
    </template>
  </modal-form>
</template>

<script>
import { updateOneTimeMeeting, updateMeeting } from '@/services/api/we-meet.js'
import { meetingInputError } from '@/services/message/we-meet.js'
import { getIsoStr, getHtmlDate, getHtmlTime } from '@/services/utils/date.js'
import { areValidInputs, resetInputsValidation } from '@/services/utils/form.js'
import { closeModalPopup } from '@/services/utils/modal.js'
import { accessOptions } from '@/services/utils/select-menu.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import { validateStartAndEndDateAndTime } from '@/services/utils/we-meet.js'
import ModalForm from '@/components/ModalForm.vue'

export default {
  name: 'MeetingEdit',

  components: {
    ModalForm
  },

  props: {
    modalPopupId: {
      type: String,
      require: true
    },

    isOneTimeMeeting: {
      type: Boolean,
      require: true
    },

    isRecurring: {
      type: Boolean,
      require: true
    },

    roomId: {
      type: String,
      require: true
    },

    meetingId: {
      type: String,
      require: true
    },

    meetingName: {
      type: String,
      require: true
    },

    meetingStartDateStr: {
      type: String,
      require: true
    },

    meetingEndDateStr: {
      type: String,
      require: true
    },

    isRoomLocked: {
      type: Boolean,
      require: false,
      default: false
    },

    isApprovalRequired: {
      type: Boolean,
      require: false,
      default: false
    },

    areUsersMuted: {
      type: Boolean,
      require: false,
      default: false
    },

    isMeetingRecorded: {
      type: Boolean,
      require: false,
      default: false
    }
  },

  emits: ['confirmModalForm'],

  data() {
    return {
      meetingNameStr: this.meetingName,
      startDate: getHtmlDate(this.meetingStartDateStr),
      startTime: getHtmlTime(this.meetingStartDateStr),
      endDate: getHtmlDate(this.meetingEndDateStr),
      endTime: getHtmlTime(this.meetingEndDateStr),
      isRoomLockedBool: this.isRoomLocked,
      accessOptions: accessOptions,
      isApprovalRequiredBool: this.isApprovalRequired,
      areUsersMutedBool: this.areUsersMuted,
      isMeetingRecordedBool: this.isMeetingRecorded,
      dateAndTimeErrorMessage: '',
      editMeetingErrorMessage: '',
      meetingInputError: meetingInputError
    }
  },

  watch: {
    startDate: {
      handler(newDate) {
        this.endDate = newDate
        this.onValidateStartAndEndDateAndTime()
      }
    },

    startTime() {
      this.onValidateStartAndEndDateAndTime()
    },

    endDate: {
      handler(newDate) {
        this.startDate = newDate
        this.onValidateStartAndEndDateAndTime()
      }
    },

    endTime() {
      this.onValidateStartAndEndDateAndTime()
    }
  },

  methods: {
    onValidateStartAndEndDateAndTime() {
      this.dateAndTimeErrorMessage = validateStartAndEndDateAndTime(
        this.modalPopupId,
        this.startDate,
        this.startTime,
        this.endDate,
        this.endTime
      )
    },

    async onEditMeeting(event) {
      // Reset edit meeting error message
      this.editMeetingErrorMessage = ''

      // Validate form
      if (!areValidInputs(this.modalPopupId)) {
        event.stopPropagation()
        return
      }

      try {
        showSpinner()
        // Call edit meeting API
        if (this.isOneTimeMeetingBool) {
          await updateOneTimeMeeting(
            this.meetingId,
            this.meetingNameStr,
            getIsoStr(this.startDate, this.startTime),
            getIsoStr(this.endDate, this.endTime),
            this.isRoomLockedBool,
            this.isApprovalRequiredBool,
            this.areUsersMutedBool,
            this.isMeetingRecordedBool
          )
        } else {
          await updateMeeting(
            this.meetingId,
            this.meetingNameStr,
            getIsoStr(this.startDate, this.startTime),
            getIsoStr(this.endDate, this.endTime)
          )
        }
        hideSpinner()

        // Close modal, remove validated class from form
        // and emit event to WeMeetLobby component
        closeModalPopup(this.modalPopupId)
        document
          .getElementById(this.modalPopupId)
          .querySelector('.form')
          .classList.remove('validated')
        this.$emit('confirmModalForm')
      } catch (e) {
        hideSpinner()
        this.editMeetingErrorMessage = e.response.data.message
      }
    },

    onCloseModalForm() {
      // Reset form when user clicks on cross icon or "Cancel" button
      resetInputsValidation(this.modalPopupId)
      this.meetingNameStr = this.meetingName
      this.startDate = getHtmlDate(this.meetingStartDateStr)
      this.startTime = getHtmlTime(this.meetingStartDateStr)
      this.endDate = getHtmlDate(this.meetingEndDateStr)
      this.endTime = getHtmlTime(this.meetingEndDateStr)
      this.isRoomLockedBool = this.isRoomLocked
      this.isApprovalRequiredBool = this.isApprovalRequired
      this.areUsersMutedBool = this.areUsersMuted
      this.isMeetingRecordedBool = this.isMeetingRecorded
      this.dateAndTimeErrorMessage = ''
      this.editMeetingErrorMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
