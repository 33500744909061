<template>
  <spinner />

  <div class="dashboard scroll-view">
    <div class="dashboard__page-buttons">
      <!-- View assignee data button -->
      <button
        type="button"
        class="button-primary mb-4 me-3"
        data-open="selectAssigneeModal">
        View assignee data &gt;
      </button>

      <!-- View tasks button -->
      <button type="button" class="button-primary mb-4" @click="onViewTasks">
        View tasks &gt;
      </button>
    </div>

    <!-- Modal - Select assignee -->
    <select-assignee-modal
      modalPopupId="selectAssigneeModal"
      :assignees="assignees" />

    <!-- Get assignees error message -->
    <p v-if="getAssigneesErrorMessage" class="error-message">
      {{ getAssigneesErrorMessage }}
    </p>

    <!-- Header -->
    <div class="d-inline-block">
      <p v-if="aggregatedDataFilters[0] !== ''" class="main-heading me-3">
        Aggregated Data for {{ aggregatedDataFilters[0] }}
      </p>
      <p v-else-if="aggregatedDataFilters[1] !== ''" class="main-heading me-3">
        Aggregated Data for {{ aggregatedDataFilters[1] }}
      </p>
      <p v-else class="main-heading me-3">Aggregated Data</p>
    </div>

    <!-- Filter button -->
    <button
      type="button"
      class="dashboard__filter button-secondary d-inline-block mb-3"
      data-open="filterAggregatedDataModal">
      Filter
    </button>

    <!-- Modal - Filter aggregated data -->
    <filter-aggregated-data-modal
      modalPopupId="filterAggregatedDataModal"
      :departments="departments"
      :projects="projects"
      @applyFilters="getAggregatedData" />

    <!-- Get aggregated data error message -->
    <p v-if="getAggregatedDataErrorMessage" class="error-message">
      {{ getAggregatedDataErrorMessage }}
    </p>

    <div v-if="!getAggregatedDataErrorMessage">
      <!-- Manpower time resource - Header and Month toggle -->
      <p class="heading-one mb-2">Manpower time resource</p>
      <month-toggle
        class="mb-2"
        @changeMonth="onChangeManpowerTimeResourceMonth" />

      <!-- Manpower time resource - Estimated and actual times bar chart -->
      <Bar
        v-if="areChartsLoaded"
        class="mb-2"
        :chart-data="estimatedAndActualTimesChartData"
        :chart-options="estimatedAndActualTimesChartOptions" />

      <!-- Manpower time resource - Utilisation bar chart -->
      <Bar
        v-if="areChartsLoaded"
        class="mb-4"
        :chart-data="utilisationChartData"
        :chart-options="utilisationChartOptions" />

      <!-- Forecast - Header and Toggle view buttons -->
      <p class="heading-one d-inline-block mb-0 me-3">Estimated forecast</p>
      <div
        v-for="forecastView in forecastViews"
        class="d-inline-block"
        :key="forecastView.view">
        <div
          class="toggle-view icon__container me-3"
          :class="{
            'toggle-view--selected': selectedForecastView === forecastView.view
          }"
          @click="onChangeForecastView(forecastView.view)">
          <img
            :src="getForecastViewIcon(forecastView)"
            class="img-fluid icon" />
        </div>
      </div>

      <!-- Forecast - Year toggle -->
      <year-toggle class="mb-2" @changeYear="onChangeForecastYear" />

      <!-- Forecast - Graph view -->
      <Line
        v-if="areChartsLoaded && selectedForecastView === forecastViews[0].view"
        :chart-data="forecastChartData"
        :chart-options="forecastChartOptions" />

      <!-- Forecast - Table view -->
      <table v-else class="table">
        <tbody>
          <!-- Forecast - Table view - Header row -->
          <tr>
            <th>Name</th>
            <th v-for="month in months" :key="month.num">
              {{ month.shortName }}
              <br />
              {{ month.num }}
            </th>
          </tr>

          <!-- Forecast - Table view - Person row -->
          <tr v-for="person in aggregatedData" :key="person.assignee">
            <td>{{ person.assignee }}</td>
            <td v-for="month in months" :key="month.num">
              <p
                :class="{
                  'mb-0': displayForecastAccuracy(month.num),
                  'forecast--no-accuracy': !displayForecastAccuracy(month.num),
                  'forecast--blue': getForecast(person, month.num) <= 50,
                  'forecast--red': getForecast(person, month.num) >= 100
                }">
                {{ getForecast(person, month.num) }}%
              </p>

              <div v-if="displayForecastAccuracy(month.num)">
                <p class="main-text--light mb-0">
                  Acc: {{ getForecastAccuracy(person, month.num) }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { Bar, Line } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale
} from 'chart.js'
import { mapGetters } from 'vuex'
import { getAggregatedData } from '@/services/api/rpm.js'
import { colorScheme } from '@/services/utils/chart.js'
import { months } from '@/services/utils/date.js'
import {
  initialiseModalPopups,
  cleanUpModalPopups
} from '@/services/utils/modal.js'
import { forecastViews } from '@/services/utils/rpm.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import FilterAggregatedDataModal from '@/views/rpm/modal/FilterAggregatedDataModal.vue'
import MonthToggle from '@/components/MonthToggle.vue'
import SelectAssigneeModal from '@/views/rpm/modal/SelectAssigneeModal.vue'
import Spinner from '@/components/Spinner.vue'
import YearToggle from '@/components/YearToggle.vue'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale
)

export default {
  name: 'RPMDashboard',

  components: {
    Bar,
    Line,
    FilterAggregatedDataModal,
    MonthToggle,
    SelectAssigneeModal,
    Spinner,
    YearToggle
  },

  data() {
    return {
      assignees: [],
      aggregatedData: null,
      departments: [],
      projects: [],

      // Manpower time resource section
      manpowerTimeResourceMonth: '',
      estimatedAndActualTimesChartData: {},
      estimatedAndActualTimesChartOptions: {},
      utilisationChartData: {},
      utilisationChartOptions: {},

      // Estimated forecast section
      forecastViews: forecastViews,
      selectedForecastView: forecastViews[0].view,
      forecastYear: '',
      forecastChartData: {},
      forecastChartOptions: {},

      // General utilities and error messages
      areChartsLoaded: false,
      colorScheme: colorScheme,
      months: months,
      getAssigneesErrorMessage: '',
      getAggregatedDataErrorMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      aggregatedDataFilters: 'rpm/getAggregatedDataFilters'
    })
  },

  async mounted() {
    // Initialise charts options
    this.estimatedAndActualTimesChartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        y: {
          ticks: {
            stepSize: 5
          }
        }
      }
    }
    this.utilisationChartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        y: {
          ticks: {
            callback: function (value) {
              return value + '%'
            }
          }
        }
      }
    }
    this.forecastChartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        y: {
          min: 0,
          ticks: {
            callback: function (value) {
              return value + '%'
            }
          }
        }
      }
    }

    // Call API
    await this.getAggregatedData()

    // Initialise modals
    initialiseModalPopups()
  },

  beforeUnmount() {
    cleanUpModalPopups()
  },

  methods: {
    async getAggregatedData() {
      // Reset get aggregated data error message
      this.getAggregatedDataErrorMessage = ''

      try {
        // Call get aggregated data API
        showSpinner()
        const res = await getAggregatedData(this.aggregatedDataFilters)
        this.assignees = res.data.result.map((person) => person.assignee)
        this.aggregatedData = res.data.result
        this.departments = res.data.departments
        this.projects = res.data.projects
        this.populateManpowerTimeResourceCharts()
        this.populateForecastChart()

        // Set loaded status to true
        this.areChartsLoaded = true
        hideSpinner()
      } catch (e) {
        hideSpinner()
        this.getAggregatedDataErrorMessage = e.response.data.message
      }
    },

    populateManpowerTimeResourceCharts() {
      var labels = []
      var estimatedTimes = []
      var actualTimes = []
      var utilisations = []

      // Extract data
      this.aggregatedData.forEach((person) => {
        labels.push(person.assignee)
        const monthlyTasks = person.jobTasks[this.manpowerTimeResourceMonth]
        if (monthlyTasks) {
          // 3600 seconds = 1 hour, 8.5 hours = 1 working day
          // Round calculations to 1 dp
          const estimatedTime = monthlyTasks.estimatedTimeTotal / 3600 / 8.5
          const actualTime = monthlyTasks.spentTimeTotal / 3600 / 8.5
          estimatedTimes.push(Math.round(estimatedTime * 10) / 10)
          actualTimes.push(Math.round(actualTime * 10) / 10)
          utilisations.push(monthlyTasks.utilization)
        } else {
          estimatedTimes.push(0)
          actualTimes.push(0)
          utilisations.push(0)
        }
      })

      // Populate estimated and actual times bar chart
      this.estimatedAndActualTimesChartData = {
        labels: labels,
        datasets: [
          {
            label: 'Estimated time (day)',
            data: estimatedTimes,
            backgroundColor: 'skyblue'
          },
          {
            label: 'Actual time (day)',
            data: actualTimes,
            backgroundColor: 'slateblue'
          }
        ]
      }

      // Populate utilisation bar chart
      this.utilisationChartData = {
        labels: labels,
        datasets: [
          {
            label: 'Utilisation (%)',
            data: utilisations,
            backgroundColor: 'seagreen'
          }
        ]
      }
    },

    populateForecastChart() {
      // Extract data
      var forecastDatasets = []
      this.aggregatedData.forEach((person, i) => {
        const label = person.assignee
        const data = []
        for (var j = 0; j < months.length; j++) {
          data.push(this.getForecast(person, months[j].num))
        }
        forecastDatasets.push({
          label: label,
          data: data,
          borderColor: colorScheme[i % 20],
          tension: 0.3
        })
      })

      // Populate forecast line chart
      this.forecastChartData = {
        labels: months.map((m) => m.name),
        datasets: forecastDatasets
      }
    },

    onViewTasks() {
      this.$router.push({ name: 'TaskList' })
    },

    onChangeManpowerTimeResourceMonth(month) {
      this.manpowerTimeResourceMonth = month
      if (this.areChartsLoaded) {
        this.populateManpowerTimeResourceCharts()
      }
    },

    onChangeForecastView(view) {
      this.selectedForecastView = view
    },

    getForecastViewIcon(forecastView) {
      if (forecastView.view === this.selectedForecastView) {
        return require('@/assets/icons/' + forecastView.whiteIcon) // selected view
      } else {
        return require('@/assets/icons/' + forecastView.icon) // unselected view
      }
    },

    onChangeForecastYear(year) {
      this.forecastYear = year
      if (this.areChartsLoaded) {
        this.populateForecastChart()
      }
    },

    getForecast(person, month) {
      const monthYear = this.forecastYear + '-' + month
      return person.jobTasks[monthYear]
        ? person.jobTasks[monthYear].forecastUtilization
        : 0
    },

    displayForecastAccuracy(month) {
      return (
        new Date(this.forecastYear + '-' + month + '-01') <
        new Date().setDate(0)
      )
    },

    getForecastAccuracy(person, month) {
      const monthYear = this.forecastYear + '-' + month
      if (person.jobTasks[monthYear]) {
        const acc = person.jobTasks[monthYear].forecastAccuracy
        if (acc) {
          return acc + '%'
        }
      }
      return '-'
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_rpm.scss';
</style>
