const state = () => ({
  aggregatedDataFilterCategories: ['None', 'Department', 'Project'],

  // None by default
  aggregatedDataFilterCategory: 'None',

  // [department, project]
  aggregatedDataFilters: ['', ''],

  // [department, project, assignee, status, start date, end date]
  tasksFilters: ['', '', '', '', '', '']
})

const getters = {
  getAggregatedDataFilterCategories: (state) =>
    state.aggregatedDataFilterCategories,

  getAggregatedDataFilterCategory: (state) =>
    state.aggregatedDataFilterCategory,

  getAggregatedDataFilters: (state) => state.aggregatedDataFilters,

  getTasksFilters: (state) => state.tasksFilters
}

const mutations = {
  setAggregatedDataFilterCategory(state, aggregatedDataFilterCategory) {
    state.aggregatedDataFilterCategory = aggregatedDataFilterCategory
  },

  setAggregatedDataFilters(state, aggregatedDataFilters) {
    state.aggregatedDataFilters = aggregatedDataFilters
  },

  setTasksFilters(state, tasksFilters) {
    state.tasksFilters = tasksFilters
  },

  clearRpmStore(state) {
    state.aggregatedDataFilterCategory = 'None'
    state.aggregatedDataFilters = ['', '']
    state.tasksFilters = ['', '', '', '', '', '']
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
