import { instance } from './global.js'
import store from '@/store/index.js'

function updateUserProfile(userId, firstName, lastName, email) {
  return instance.put(`/user/${userId}`, {
    firstName: firstName,
    lastName: lastName,
    email: email,
    beyondToken: store.getters['auth/getBeyondToken']
  })
}

function updateUserPassword(userId, password) {
  return instance.put(`/user/${userId}`, {
    password: password,
    beyondToken: store.getters['auth/getBeyondToken']
  })
}

export { updateUserProfile, updateUserPassword }
