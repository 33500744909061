<template>
  <modal-form
    :modalPopupId="modalPopupId"
    heading="Schedule A Meeting"
    @closeModalForm="onCloseModalForm">
    <template #form>
      <form
        class="form"
        @keypress.enter.prevent
        @keyup.enter.prevent
        @submit.prevent="onScheduleMeeting($event)"
        novalidate>
        <div class="container-fluid">
          <!-- Meeting name input -->
          <div class="row">
            <div class="col mb-4">
              <label
                for="we-meet-form__meeting-name-input"
                class="form-label sub-text--light">
                Meeting Name
              </label>
              <input
                type="text"
                class="form-control input"
                id="we-meet-form__meeting-name-input"
                v-model="meetingName"
                required />
              <div class="form__invalid-message">
                {{ meetingInputError.emptyMeetingName }}
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Welcome message input-->
            <div class="col-12 col-lg-6 mb-4">
              <label
                for="we-meet-form__welcome-message-input"
                class="form-label sub-text--light">
                Welcome Message (optional)
              </label>
              <textarea
                type="text"
                class="form-control input"
                id="we-meet-form__welcome-message-input"
                v-model="welcomeMessage"
                placeholder="Type a message..."
                rows="10" />
            </div>

            <div class="col-12 col-lg-6 mb-4">
              <!-- Invite participants - Header -->
              <label
                for="we-meet-form__participants-input"
                class="form-label sub-text--light">
                Invite Participants
              </label>

              <!-- Invite participants container -->
              <div class="we-meet-form__participants-container p-2">
                <!-- Invite participants container - Input -->
                <input
                  type="email"
                  class="form-control input--dark mb-2"
                  id="we-meet-form__participants-input"
                  v-model="participant"
                  pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
                  placeholder="Add more participants"
                  @keyup.enter.capture="onAddParticipant($event)" />
                <div class="form__invalid-message">
                  {{ meetingInputError.invalidParticipantEmail }}
                </div>

                <div class="we-meet-form__participants-list scroll-view">
                  <!-- No participants message -->
                  <p v-if="!participants.length" class="message">
                    {{ weMeetMessage.noParticipants }}
                  </p>

                  <!-- Invite participants container - Participants list -->
                  <div
                    v-for="(participant, index) in participants"
                    :key="participant">
                    <div>
                      <p class="main-text d-inline-block mb-0">
                        {{ participant }}
                      </p>
                      <img
                        src="@/assets/icons/close.svg"
                        class="we-meet-form__delete-participant img-fluid icon d-inline-block float-end"
                        @click="onDeleteParticipant(index)" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Invite participants - Send invite switch -->
              <div
                v-if="participants.length"
                class="form-check form-switch mb-0 mt-2">
                <input
                  type="checkbox"
                  role="switch"
                  class="form-check-input"
                  id="we-meet-form__invite-participants-switch"
                  v-model="requireEmailInvitation" />
                <label
                  class="form-check-label sub-text"
                  for="we-meet-form__invite-participants-switch">
                  Send email invite to participants
                </label>
              </div>
            </div>
          </div>

          <p class="sub-text--light mb-2">Meeting Information</p>

          <!-- Meeting information container -->
          <div class="we-meet-form__meeting-information-container p-3 mb-4">
            <div class="row row-cols-1 row-cols-lg-2">
              <!-- Meeting information container - Room name input -->
              <div class="col mb-4" :class="{ 'd-none': !isCreateRoom }">
                <label
                  for="we-meet-form__room-name-input"
                  class="form-label sub-text--light">
                  Room Name
                </label>
                <input
                  type="text"
                  class="form-control input--dark"
                  id="we-meet-form__room-name-input"
                  v-model="roomName"
                  :required="isCreateRoom" />
                <div class="form__invalid-message">
                  {{ roomInputError.emptyRoomName }}
                </div>
              </div>

              <!-- Meeting information container - Set recurring switch and select menu -->
              <div class="col mb-4" :class="{ 'd-none': !isCreateRoom }">
                <div class="form-check form-switch mb-2">
                  <input
                    type="checkbox"
                    role="switch"
                    class="form-check-input"
                    id="we-meet-form__recurring-switch"
                    v-model="isRecurring" />
                  <label
                    class="form-check-label"
                    :class="{
                      'sub-text': isRecurring,
                      'sub-text--light': !isRecurring
                    }"
                    for="we-meet-form__recurring-switch">
                    Set Recurring
                  </label>
                </div>

                <select
                  class="form-select select--dark"
                  id="we-meet-form__recurring-value"
                  v-model="recurring"
                  :disabled="!isRecurring">
                  <option
                    v-for="option in recurringOptions"
                    :value="option"
                    :key="option">
                    {{ option }}
                  </option>
                </select>
                <div class="form__invalid-message">
                  {{ meetingInputError.emptyRecurringValue }}
                </div>
              </div>

              <!-- Meeting information container - Meeting start date and time inputs -->
              <div class="col">
                <label
                  for="we-meet-form__start-date-input"
                  class="form-label sub-text--light">
                  Meeting Start
                </label>

                <div class="row">
                  <div class="col-12 col-sm-6 mb-2">
                    <input
                      type="date"
                      class="form-control input--dark"
                      id="we-meet-form__start-date-input"
                      v-model="startDate"
                      required />
                    <div class="form__invalid-message">
                      {{ meetingInputError.emptyDate }}
                    </div>
                  </div>

                  <div class="col-12 col-sm-6 mb-2">
                    <input
                      type="time"
                      class="form-control input--dark"
                      id="we-meet-form__start-time-input"
                      v-model="startTime"
                      required />
                    <div class="form__invalid-message">
                      {{ meetingInputError.emptyTime }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Meeting information container - Meeting end date and time inputs -->
              <div class="col">
                <label
                  for="we-meet-form__end-date-input"
                  class="form-label sub-text--light">
                  Meeting End
                </label>

                <div class="row">
                  <div class="col-12 col-sm-6 mb-2">
                    <input
                      type="date"
                      class="form-control input--dark"
                      id="we-meet-form__end-date-input"
                      v-model="endDate"
                      required />
                    <div class="form__invalid-message">
                      {{ meetingInputError.emptyDate }}
                    </div>
                  </div>

                  <div class="col-12 col-sm-6 mb-2">
                    <input
                      type="time"
                      class="form-control input--dark"
                      id="we-meet-form__end-time-input"
                      v-model="endTime"
                      aria-label="Meeting end time"
                      required />
                    <div class="form__invalid-message">
                      {{ meetingInputError.emptyTime }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Start and end datetime error message -->
            <p v-if="dateAndTimeErrorMessage" class="error-message mt-2">
              {{ dateAndTimeErrorMessage }}
            </p>
          </div>

          <!-- Create room - Header -->
          <p class="main-text--bold d-inline-block me-3">Create Room</p>

          <!-- Create room - Toggle -->
          <div class="we-meet-form__create-room-toggle-group d-inline">
            <button
              type="button"
              class="d-inline-block"
              :class="{
                'we-meet-form__create-room-toggle--selected': isCreateRoom,
                'we-meet-form__create-room-toggle--unselected': !isCreateRoom
              }"
              @click="onToggleCreateRoom(true)">
              Yes
            </button>
            <button
              type="button"
              class="d-inline-block"
              :class="{
                'we-meet-form__create-room-toggle--selected': !isCreateRoom,
                'we-meet-form__create-room-toggle--unselected': isCreateRoom
              }"
              @click="onToggleCreateRoom(false)">
              No
            </button>
          </div>

          <p class="sub-text--light mb-2">Settings</p>

          <!-- Settings container -->
          <div class="we-meet-form__settings-container p-3">
            <!-- Settings container - Access select menu -->
            <label
              for="we-meet-form__access-value"
              class="form-label sub-text--light mb-2 me-2">
              Access
            </label>
            <select
              class="form-select select d-inline-block mb-2 py-1"
              id="we-meet-form__access-value"
              v-model="isRoomLocked">
              <option
                v-for="option in accessOptions"
                :value="option.boolean"
                :key="option.name">
                {{ option.name }}
              </option>
            </select>

            <!-- Settings container - Require approval switch -->
            <div class="form-check form-switch mb-2">
              <input
                type="checkbox"
                role="switch"
                class="form-check-input"
                id="we-meet-form__require-approval-switch"
                v-model="isApprovalRequired" />
              <label
                class="form-check-label sub-text"
                for="we-meet-form__require-approval-switch">
                Require approval to join
              </label>
            </div>

            <!-- Settings container - Mute users switch -->
            <div class="form-check form-switch mb-2">
              <input
                type="checkbox"
                role="switch"
                class="form-check-input"
                id="we-meet-form__mute-users-switch"
                v-model="areUsersMuted" />
              <label
                class="form-check-label sub-text"
                for="we-meet-form__mute-users-switch">
                Mute all users
              </label>
            </div>

            <!-- Settings container - Record meetings switch -->
            <div class="form-check form-switch">
              <input
                type="checkbox"
                role="switch"
                class="form-check-input"
                id="we-meet-form__record-meetings-switch"
                v-model="isMeetingRecorded" />
              <label
                class="form-check-label sub-text"
                for="we-meet-form__record-meetings-switch">
                Record Meetings
              </label>
            </div>
          </div>

          <!-- Schedule meeting error message -->
          <p v-if="scheduleMeetingErrorMessage" class="error-message mt-4">
            {{ scheduleMeetingErrorMessage }}
          </p>
        </div>

        <!-- Confirm and cancel buttons -->
        <div class="mb-2 mt-4">
          <button type="submit" class="button-primary mb-2 me-3">
            Confirm
          </button>

          <button
            type="button"
            class="button-outline"
            :data-close="modalPopupId"
            @click="onCloseModalForm">
            Cancel
          </button>
        </div>
      </form>
    </template>
  </modal-form>
</template>

<script>
import {
  createRoom,
  scheduleOneTimeMeeting,
  scheduleMeetingUnderRoom
} from '@/services/api/we-meet.js'
import {
  weMeetMessage,
  roomInputError,
  meetingInputError
} from '@/services/message/we-meet.js'
import {
  getIsoStr,
  getCurrentHtmlDate,
  getCurrentHtmlTime
} from '@/services/utils/date.js'
import {
  handleFormElementValidity,
  areValidInputs,
  resetInputsValidation
} from '@/services/utils/form.js'
import { closeModalPopup } from '@/services/utils/modal.js'
import {
  accessOptions,
  recurringOptions,
  getRecurringOptionsEncoding
} from '@/services/utils/select-menu.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import {
  validateParticipantEmail,
  validateStartAndEndDateAndTime
} from '@/services/utils/we-meet.js'
import ModalForm from '@/components/ModalForm.vue'

export default {
  name: 'MeetingSchedule',

  components: {
    ModalForm
  },

  props: {
    modalPopupId: {
      type: String,
      require: true
    }
  },

  emits: ['confirmModalForm'],

  data() {
    return {
      meetingName: '',
      welcomeMessage: '',
      participant: '',
      participants: [],
      requireEmailInvitation: false,
      roomName: '',
      isRecurring: false,
      recurring: '',
      recurringOptions: recurringOptions,
      startDate: getCurrentHtmlDate(),
      startTime: getCurrentHtmlTime(),
      endDate: getCurrentHtmlDate(),
      endTime: '',
      isCreateRoom: false,
      isRoomLocked: false,
      accessOptions: accessOptions,
      isApprovalRequired: false,
      areUsersMuted: false,
      isMeetingRecorded: false,
      dateAndTimeErrorMessage: '',
      scheduleMeetingErrorMessage: '',
      weMeetMessage: weMeetMessage,
      roomInputError: roomInputError,
      meetingInputError: meetingInputError
    }
  },

  watch: {
    isRecurring: {
      handler(newBool) {
        if (!newBool) {
          // Reset recurring value if isRecurring is false
          this.recurring = ''
        }
      }
    },

    startDate: {
      handler(newDate) {
        this.endDate = newDate
        this.onValidateStartAndEndDateAndTime()
      }
    },

    startTime() {
      this.onValidateStartAndEndDateAndTime()
    },

    endDate: {
      handler(newDate) {
        this.startDate = newDate
        this.onValidateStartAndEndDateAndTime()
      }
    },

    endTime() {
      this.onValidateStartAndEndDateAndTime()
    },

    isCreateRoom: {
      handler(newBool) {
        if (!newBool) {
          // Reset recurring Boolean and value and room name if isCreateRoom is false
          this.isRecurring = false
          this.recurring = ''
          this.roomName = ''
        }
      }
    }
  },

  methods: {
    onAddParticipant(event) {
      if (
        this.participant.length &&
        validateParticipantEmail(event, this.modalPopupId)
      ) {
        // Add participant's email
        this.participants.push(this.participant)
        this.participant = ''
      }
    },

    onDeleteParticipant(index) {
      this.participants.splice(index, 1)
    },

    onValidateStartAndEndDateAndTime() {
      this.dateAndTimeErrorMessage = validateStartAndEndDateAndTime(
        this.modalPopupId,
        this.startDate,
        this.startTime,
        this.endDate,
        this.endTime
      )
    },

    onToggleCreateRoom(newBool) {
      this.isCreateRoom = newBool
    },

    async onScheduleMeeting(event) {
      // Reset schedule meeting error message
      this.scheduleMeetingErrorMessage = ''

      // Validate recurring select menu
      const recurringSelectElement = document.getElementById(
        'we-meet-form__recurring-value'
      )
      recurringSelectElement.setCustomValidity('')
      if (this.isRecurring && !this.recurring) {
        recurringSelectElement.setCustomValidity('error')
      }
      handleFormElementValidity(recurringSelectElement)

      // Validate form
      if (!areValidInputs(this.modalPopupId)) {
        event.stopPropagation()
        return
      }

      try {
        showSpinner()
        if (this.isCreateRoom) {
          // Create meeting under a new room
          const res = await createRoom(
            this.roomName,
            this.welcomeMessage,
            this.isRoomLocked,
            this.isApprovalRequired,
            this.areUsersMuted,
            this.isMeetingRecorded
          )
          await scheduleMeetingUnderRoom(
            this.meetingName,
            this.participants,
            this.requireEmailInvitation,
            this.isRecurring,
            getRecurringOptionsEncoding(this.recurring),
            getIsoStr(this.startDate, this.startTime),
            getIsoStr(this.endDate, this.endTime),
            res.data.roomID
          )
        } else {
          // Create one-time meeting without a room
          await scheduleOneTimeMeeting(
            this.meetingName,
            this.participants,
            this.requireEmailInvitation,
            getIsoStr(this.startDate, this.startTime),
            getIsoStr(this.endDate, this.endTime),
            this.welcomeMessage,
            this.isRoomLocked,
            this.isApprovalRequired,
            this.areUsersMuted,
            this.isMeetingRecorded
          )
        }
        hideSpinner()

        // Close modal, remove validated class from form
        // and emit event to WeMeetLobby component
        this.onCloseModalForm()
        closeModalPopup(this.modalPopupId)
        document
          .getElementById(this.modalPopupId)
          .querySelector('.form')
          .classList.remove('validated')
        this.$emit('confirmModalForm')
      } catch (e) {
        hideSpinner()
        this.scheduleMeetingErrorMessage = e.response.data.message
      }
    },

    onCloseModalForm() {
      // Reset form when user submits form
      // Reset form when user clicks on cross icon or "Cancel" button
      resetInputsValidation(this.modalPopupId)
      this.meetingName = ''
      this.welcomeMessage = ''
      this.participant = ''
      this.participants = []
      this.requireEmailInvitation = false
      this.roomName = ''
      this.isRecurring = false
      this.recurring = ''
      this.startDate = getCurrentHtmlDate()
      this.startTime = getCurrentHtmlTime()
      this.endDate = getCurrentHtmlDate()
      this.endTime = ''
      this.isCreateRoom = false
      this.isRoomLocked = false
      this.isApprovalRequired = false
      this.areUsersMuted = false
      this.isMeetingRecorded = false
      this.dateAndTimeErrorMessage = ''
      this.scheduleMeetingErrorMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
