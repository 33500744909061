const state = () => ({
  userId: '',
  beyondToken: '',
  loginFlag: false
})

const getters = {
  getUserId: (state) => state.userId,

  getBeyondToken: (state) => state.beyondToken,

  getLoginFlag: (state) => state.loginFlag
}

const mutations = {
  setUserId(state, userId) {
    state.userId = userId
  },

  setBeyondToken(state, beyondToken) {
    state.beyondToken = beyondToken
  },

  setLoginFlag(state, loginFlag) {
    state.loginFlag = loginFlag
  },

  clearAuthStore(state) {
    state.userId = ''
    state.beyondToken = ''
    state.loginFlag = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
