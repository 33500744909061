<template>
  <modal-form
    :modalPopupId="modalPopupId"
    heading="Share Meeting"
    @closeModalForm="onCloseModalForm">
    <template #form>
      <form
        class="form"
        @keypress.enter.prevent
        @keyup.enter.prevent
        @submit.prevent="onSendEmailInvitation($event)"
        novalidate>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-6">
              <!-- Meeting share URL disabled input  -->
              <div class="input-container mb-4">
                <label
                  for="we-meet-form__meeting-share-url-input"
                  class="form-label sub-text--light">
                  Meeting Share URL
                </label>
                <img
                  src="@/assets/icons/copy-link.svg"
                  class="input-icon img-fluid icon d-inline-block"
                  @click="onCopyMeetingInfo(meetingUrlStr)" />
                <input
                  type="text"
                  class="form-control input text-truncate"
                  id="we-meet-form__meeting-share-url-input"
                  v-model="meetingUrlStr"
                  disabled />
              </div>

              <!-- Meeting access code disabled input  -->
              <div class="input-container mb-4">
                <label
                  for="we-meet-form__meeting-access-code-input"
                  class="form-label sub-text--light">
                  Meeting Access Code
                </label>
                <img
                  src="@/assets/icons/copy.svg"
                  class="input-icon img-fluid icon d-inline-block"
                  @click="onCopyMeetingInfo(meetingAccessCodeStr)" />
                <input
                  type="text"
                  class="form-control input text-truncate"
                  id="we-meet-form__meeting-access-code-input"
                  v-model="meetingAccessCodeStr"
                  disabled />
              </div>

              <div class="border-top py-3">
                <!-- Copy share info button -->
                <button
                  type="button"
                  class="we-meet-form__copy-share-info button-outline button--small"
                  @click="onShareMeetingInfo">
                  <img
                    src="@/assets/icons/copy.svg"
                    class="img-fluid icon d-inline-block me-1" />
                  Copy share info
                </button>
              </div>
            </div>

            <div class="col-12 col-lg-6">
              <!-- Invite participants - Header -->
              <label
                for="we-meet-form__participants-input"
                class="form-label sub-text--light">
                Invite Participants
              </label>

              <!-- Invite participants container -->
              <div class="we-meet-form__participants-container p-2">
                <!-- Invite participants container - Input -->
                <input
                  type="email"
                  class="form-control input--dark mb-2"
                  id="we-meet-form__participants-input"
                  v-model="participant"
                  pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
                  placeholder="Add more participants"
                  @keyup.enter.capture="onAddParticipant($event)" />
                <div class="form__invalid-message">
                  {{ meetingInputError.invalidParticipantEmail }}
                </div>

                <div class="we-meet-form__participants-list scroll-view">
                  <!-- No participants message -->
                  <p v-if="!participantsArr.length" class="message">
                    {{ weMeetMessage.noParticipants }}
                  </p>

                  <!-- Invite participants container - Participants list -->
                  <div
                    v-for="(participant, index) in participantsArr"
                    :key="participant">
                    <div>
                      <p class="main-text d-inline-block mb-0">
                        {{ participant }}
                      </p>
                      <img
                        src="@/assets/icons/close.svg"
                        class="we-meet-form__delete-participant img-fluid icon d-inline-block float-end"
                        @click="onDeleteParticipant(index)" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Invite participants - Send invite switch -->
              <div
                v-if="participantsArr.length"
                class="form-check form-switch mb-0 mt-2">
                <input
                  type="checkbox"
                  role="switch"
                  class="form-check-input"
                  id="we-meet-form__invite-participants-switch"
                  v-model="requireEmailInvitation" />
                <label
                  class="form-check-label sub-text"
                  for="we-meet-form__invite-participants-switch">
                  Send email invite to new participants
                </label>
              </div>
            </div>
          </div>

          <!-- Share meeting error message -->
          <p v-if="shareMeetingErrorMessage" class="error-message">
            {{ shareMeetingErrorMessage }}
          </p>
        </div>

        <!-- Confirm and cancel buttons -->
        <div class="mb-2 mt-4">
          <button type="submit" class="button-primary mb-2 me-3">
            Confirm
          </button>

          <button
            type="button"
            class="button-outline"
            :data-close="modalPopupId"
            @click="onCloseModalForm">
            Cancel
          </button>
        </div>
      </form>

      <!-- Toast - Copy meeting info -->
      <toast-popup
        toastId="copyMeetingInfoToast"
        :toastMessage="toastMessage.copied" />
    </template>
  </modal-form>
</template>

<script>
import { Toast } from 'bootstrap'
import { sendEmailInvitation } from '@/services/api/we-meet.js'
import { toastMessage } from '@/services/message/general.js'
import { weMeetMessage, meetingInputError } from '@/services/message/we-meet.js'
import { resetInputsValidation } from '@/services/utils/form.js'
import { closeModalPopup } from '@/services/utils/modal.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import { validateParticipantEmail } from '@/services/utils/we-meet.js'
import ModalForm from '@/components/ModalForm.vue'
import ToastPopup from '@/components/ToastPopup.vue'

export default {
  name: 'MeetingShare',

  components: {
    ModalForm,
    ToastPopup
  },

  props: {
    modalPopupId: {
      type: String,
      require: true
    },

    roomId: {
      type: String,
      require: true
    },

    meetingUrl: {
      type: String,
      require: true
    },

    meetingAccessCode: {
      type: String,
      require: false
    },

    participants: {
      // String or Array depending on whether it is an empty value
      require: false
    }
  },

  emits: ['confirmModalForm'],

  data() {
    return {
      meetingUrlStr: this.meetingUrl,
      meetingAccessCodeStr: this.meetingAccessCode,
      participant: '',
      participantsArr: this.participants,
      requireEmailInvitation: false,
      shareMeetingErrorMessage: '',
      toastMessage: toastMessage,
      weMeetMessage: weMeetMessage,
      meetingInputError: meetingInputError
    }
  },

  methods: {
    onCopyMeetingInfo(str) {
      navigator.clipboard.writeText(str)
      var toast = new Toast(
        document
          .getElementById(this.modalPopupId)
          .querySelector('#copyMeetingInfoToast')
      )
      toast.show()
    },

    onShareMeetingInfo() {
      this.onCopyMeetingInfo(
        'Meeting URL: ' +
          this.meetingUrlStr +
          '\nMeeting Access Code: ' +
          this.meetingAccessCodeStr
      )
    },

    onAddParticipant(event) {
      if (
        this.participant.length &&
        validateParticipantEmail(event, this.modalPopupId)
      ) {
        // Add participant's email
        this.participantsArr.push(this.participant)
        this.participant = ''
      }
    },

    onDeleteParticipant(index) {
      this.participantsArr.splice(index, 1)
    },

    async onSendEmailInvitation() {
      // Reset share meeting error message
      this.shareMeetingErrorMessage = ''

      try {
        // Call send email invite API
        showSpinner()
        await sendEmailInvitation(
          this.roomId,
          this.participantsArr,
          this.requireEmailInvitation
        )
        hideSpinner()

        // Close modal, remove validated class from form
        // and emit event to WeMeetLobby component
        closeModalPopup(this.modalPopupId)
        document
          .getElementById(this.modalPopupId)
          .querySelector('.form')
          .classList.remove('validated')
        this.$emit('confirmModalForm')
      } catch (e) {
        hideSpinner()
        this.shareMeetingErrorMessage = e.response.data.message
      }
    },

    onCloseModalForm() {
      // Reset form when user clicks on cross icon or "Cancel" button
      resetInputsValidation(this.modalPopupId)
      this.participant = ''
      this.participantsArr = this.participants
      this.requireEmailInvitation = false
      this.shareMeetingErrorMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
