import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'

import application from './modules/application.js'
import auth from './modules/auth.js'
import rpm from './modules/rpm.js'
import user from './modules/user.js'

export default createStore({
  mutations: {
    clearStore() {
      this.commit('application/clearApplicationStore')
      this.commit('auth/clearAuthStore')
      this.commit('rpm/clearRpmStore')
      this.commit('user/clearUserStore')
    }
  },

  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        setItem: (key, value) =>
          Cookies.set(key, value, { expires: 0.1, secure: true }),
        removeItem: (key) => Cookies.remove(key)
      }
    })
  ],

  modules: {
    application,
    auth,
    rpm,
    user
  }
})
