<template>
  <modal-form
    :modalPopupId="modalPopupId"
    heading="Filter Aggregated Data"
    @closeModalForm="onResetFilters">
    <template #form>
      <form class="form" @submit.prevent="onApplyFilters($event)" novalidate>
        <div class="container-fluid">
          <!-- Filter category select menu -->
          <div class="row mb-3 mt-1">
            <div class="col-12 col-md-2">
              <p class="main-text my-2">Filter by:</p>
            </div>
            <div class="col-12 col-md-10">
              <select class="form-select select" v-model="filterCategory">
                <option
                  v-for="option in aggregatedDataFilterCategories"
                  :value="option"
                  :key="option">
                  {{ option }}
                </option>
              </select>
            </div>
          </div>

          <!-- Department select menu -->
          <div
            v-show="filterCategory == aggregatedDataFilterCategories[1]"
            class="row mb-3 mt-1">
            <div class="col-12 col-md-2">
              <p class="main-text my-2">Department:</p>
            </div>
            <div class="col-12 col-md-10">
              <select
                class="form-select select"
                id="filter__department"
                v-model="departmentFilter"
                :required="filterCategory == aggregatedDataFilterCategories[1]">
                <option
                  v-for="option in departments"
                  :value="option"
                  :key="option">
                  {{ option }}
                </option>
              </select>
              <div class="form__invalid-message">
                {{ formError.emptyDepartment }}
              </div>
            </div>
          </div>

          <!-- Project select menu -->
          <div
            v-show="filterCategory == aggregatedDataFilterCategories[2]"
            class="row mb-3 mt-1">
            <div class="col-12 col-md-2">
              <p class="main-text my-2">Project:</p>
            </div>
            <div class="col-12 col-md-10">
              <select
                class="form-select select"
                id="filter__project"
                v-model="projectFilter"
                :required="filterCategory == aggregatedDataFilterCategories[2]">
                <option
                  v-for="option in projects"
                  :value="option.name"
                  :key="option.name">
                  {{ option.name }}
                </option>
              </select>
              <div class="form__invalid-message">
                {{ formError.emptyProject }}
              </div>
            </div>
          </div>

          <!-- Apply and clear buttons -->
          <div class="mb-2 mt-4">
            <button type="submit" class="button-primary mb-2 me-3">
              Apply
            </button>

            <button
              type="button"
              class="button-outline"
              @click="onClearFilters">
              Clear
            </button>
          </div>
        </div>
      </form>
    </template>
  </modal-form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { formError } from '@/services/message/rpm.js'
import {
  handleInvalidFormElement,
  handleValidFormElement
} from '@/services/utils/form.js'
import { closeModalPopup } from '@/services/utils/modal.js'
import ModalForm from '@/components/ModalForm.vue'

export default {
  name: 'FilterAggregatedDataModal',

  components: {
    ModalForm
  },

  props: {
    modalPopupId: {
      type: String,
      require: true
    },

    departments: {
      type: Array,
      require: true
    },

    projects: {
      type: Array,
      require: true
    }
  },

  emits: ['applyFilters'],

  data() {
    return {
      filterCategory: '',
      departmentFilter: '',
      projectFilter: '',
      formError: formError
    }
  },

  computed: {
    ...mapGetters({
      aggregatedDataFilterCategories: 'rpm/getAggregatedDataFilterCategories',
      aggregatedDataFilterCategory: 'rpm/getAggregatedDataFilterCategory',
      aggregatedDataFilters: 'rpm/getAggregatedDataFilters'
    })
  },

  watch: {
    filterCategory(newFilterCategory) {
      handleValidFormElement(document.getElementById('filter__department'))
      handleValidFormElement(document.getElementById('filter__project'))
      switch (newFilterCategory) {
        case this.aggregatedDataFilterCategories[0]:
          this.departmentFilter = ''
          this.projectFilter = ''
          break
        case this.aggregatedDataFilterCategories[1]:
          this.projectFilter = ''
          break
        case this.aggregatedDataFilterCategories[2]:
          this.departmentFilter = ''
          break
      }
    },

    departmentFilter() {
      handleValidFormElement(document.getElementById('filter__department'))
    },

    projectFilter() {
      handleValidFormElement(document.getElementById('filter__project'))
    }
  },

  mounted() {
    this.onResetFilters()
  },

  methods: {
    ...mapMutations('rpm', [
      'setAggregatedDataFilterCategory',
      'setAggregatedDataFilters'
    ]),

    onResetFilters() {
      // Reset filters to original values when user clicks on cross icon
      handleValidFormElement(document.getElementById('filter__department'))
      handleValidFormElement(document.getElementById('filter__project'))
      this.filterCategory = this.aggregatedDataFilterCategory
      this.departmentFilter = this.aggregatedDataFilters[0]
      this.projectFilter = this.aggregatedDataFilters[1]
    },

    onApplyFilters(event) {
      // Validate form
      if (
        this.filterCategory !== this.aggregatedDataFilterCategories[0] &&
        this.departmentFilter == '' &&
        this.projectFilter == ''
      ) {
        // Filter category is selected but no filter is selected
        event.stopPropagation()
        handleInvalidFormElement(document.getElementById('filter__department'))
        handleInvalidFormElement(document.getElementById('filter__project'))
        return
      }
      handleValidFormElement(document.getElementById('filter__department'))
      handleValidFormElement(document.getElementById('filter__project'))

      // Save filter category and filters to Vuex RPM store
      this.setAggregatedDataFilterCategory(this.filterCategory)
      this.setAggregatedDataFilters([this.departmentFilter, this.projectFilter])

      // Close modal and emit apply filters event to RPMDashboard component
      closeModalPopup(this.modalPopupId)
      this.$emit('applyFilters')
    },

    onClearFilters() {
      this.filterCategory = this.aggregatedDataFilterCategories[0]
      this.departmentFilter == ''
      this.projectFilter == ''
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_rpm.scss';
</style>
