<template>
  <spinner />

  <!-- Save and cancel buttons -->
  <button
    v-show="!isEditPassword"
    type="button"
    @click="onChangePassword"
    class="button-primary">
    Change password
  </button>

  <div v-show="isEditPassword" id="settings__form-container">
    <form class="form" @submit.prevent="onUpdatePassword($event)" novalidate>
      <!-- Password input -->
      <div class="mb-2">
        <label for="password-input" class="form-label sub-text--light">
          Password
        </label>
        <input
          type="password"
          class="form-control input"
          id="password-input"
          v-model="password"
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$"
          placeholder="Enter new password"
          required />
        <div class="form__invalid-message" />
      </div>

      <!-- Re-enter password input -->
      <div class="mb-2">
        <input
          type="password"
          class="form-control input"
          id="re-enter-password-input"
          v-model="reEnterPassword"
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$"
          placeholder="Re-enter new password"
          required />
        <div class="form__invalid-message" />
      </div>

      <!-- Update password error message -->
      <p v-if="updatePasswordErrorMessage" class="error-message">
        {{ updatePasswordErrorMessage }}
      </p>

      <!-- Save and cancel buttons -->
      <button type="submit" class="button-primary mb-2 me-3 mt-4">Save</button>

      <button class="button-outline" type="button" @click="onCancelAction">
        Cancel
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { updateUserPassword } from '@/services/api/user.js'
import { userInputError } from '@/services/message/admin.js'
import {
  handleInvalidFormElement,
  handleValidFormElement,
  initialiseInputElements,
  cleanUpInputElements,
  areValidInputs,
  resetInputsValidation
} from '@/services/utils/form.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'Profile',

  components: {
    Spinner
  },

  data() {
    return {
      isEditPassword: false,
      password: '',
      reEnterPassword: '',
      userInputError: userInputError,
      updatePasswordErrorMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      userId: 'auth/getUserId'
    })
  },

  mounted() {
    initialiseInputElements()
  },

  beforeUnmount() {
    cleanUpInputElements()
  },

  methods: {
    onChangePassword() {
      this.isEditPassword = true
    },

    async onUpdatePassword(event) {
      // Reset update password error message
      this.updatePasswordErrorMessage = ''

      // Reset validity message for password input elements
      const passwordInputElement = document.getElementById('password-input')
      const reEnterPasswordInputElement = document.getElementById(
        're-enter-password-input'
      )
      handleValidFormElement(passwordInputElement)
      handleValidFormElement(reEnterPasswordInputElement)

      // Check both passwords are the same
      if (this.password !== this.reEnterPassword) {
        handleInvalidFormElement(passwordInputElement)
        handleInvalidFormElement(reEnterPasswordInputElement)
        this.updatePasswordErrorMessage = userInputError.differentPasswords
        return
      }

      // Validate form
      if (!areValidInputs('settings__form-container')) {
        this.updatePasswordErrorMessage = userInputError.invalidPassword
        event.stopPropagation()
        return
      }

      try {
        // Call update user password API
        showSpinner()
        await updateUserPassword(this.userId, this.password)
        hideSpinner()
        this.isEditPassword = false
      } catch (e) {
        hideSpinner()
        this.updatePasswordErrorMessage = e.response.data.message
      }
    },

    onCancelAction() {
      this.isEditPassword = false

      // Reset form
      resetInputsValidation('settings__form-container')
      this.password = ''
      this.reEnterPassword = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_user.scss';
</style>
