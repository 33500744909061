<template>
  <spinner />

  <!-- Heading -->
  <p class="back-nav main-heading d-inline-block mb-4" @click="onCancelAction">
    &lt; Update User
  </p>

  <div class="user-form scroll-view" :id="`user-${userId}__form-container`">
    <!-- Form -->
    <form class="form" @submit.prevent="onUpdateUser($event)" novalidate>
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-lg-2">
          <!-- Form - First name input -->
          <div class="col mb-4">
            <label
              for="user__first-name-input"
              class="form-label sub-text--light">
              First name
            </label>
            <input
              type="text"
              class="form-control input"
              id="user__first-name-input"
              v-model="firstNameInput"
              pattern="^[a-zA-Z]+( [a-zA-Z]+)*$"
              placeholder="First name"
              required />
            <div class="form__invalid-message">
              {{ userInputError.invalidFirstName }}
            </div>
          </div>

          <!-- Form - Last name input -->
          <div class="col mb-4">
            <label
              for="user__last-name-input"
              class="form-label sub-text--light">
              Last name
            </label>
            <input
              type="text"
              class="form-control input"
              id="user__last-name-input"
              v-model="lastNameInput"
              pattern="^[a-zA-Z]+( [a-zA-Z]+)*$"
              placeholder="Last name"
              required />
            <div class="form__invalid-message">
              {{ userInputError.invalidLastName }}
            </div>
          </div>

          <!-- Form - Username input -->
          <div class="col mb-4">
            <label
              for="user__username-input"
              class="form-label sub-text--light">
              Username
            </label>
            <input
              type="text"
              class="form-control input"
              id="user__username-input"
              v-model="usernameInput"
              disabled />
          </div>

          <!-- Form - Password input -->
          <div class="col mb-4">
            <label
              for="user__password-input"
              class="form-label sub-text--light">
              Password (optional)
            </label>
            <input
              type="password"
              class="form-control input"
              id="user__password-input"
              v-model="passwordInput"
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$"
              placeholder="Password" />
            <div class="form__invalid-message">
              {{ userInputError.invalidPassword }}
            </div>
          </div>

          <!-- Form - Email input -->
          <div class="col mb-4">
            <label for="user__email-input" class="form-label sub-text--light">
              Email
            </label>
            <input
              type="email"
              class="form-control input"
              id="user__email-input"
              v-model="emailInput"
              pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
              placeholder="Email"
              required />
            <div class="form__invalid-message">
              {{ userInputError.invalidEmail }}
            </div>
          </div>
        </div>
      </div>

      <!-- Application access checkboxes -->
      <p class="sub-text--light mb-2">Grant access to</p>
      <div
        v-for="(applicationName, index) in nonDashboardApplicationNames"
        :key="applicationName">
        <div class="form-check mb-2">
          <input
            type="checkbox"
            class="form-check-input checkbox mt-0"
            :id="`user__${applicationName}-checkbox`"
            v-model="applicationAccessFlagsArr[index]" />
          <label
            :for="`user__${applicationName}-checkbox`"
            class="form-check-label main-text ms-3">
            {{ applicationName }}
          </label>
        </div>
      </div>

      <!-- Update user error message -->
      <p v-if="updateUserErrorMessage" class="error-message mt-4">
        {{ updateUserErrorMessage }}
      </p>

      <!-- Update user and cancel buttons -->
      <button type="submit" class="button-primary mb-2 me-3 mt-4">
        Update User
      </button>

      <button class="button-outline" type="button" @click="onCancelAction">
        Cancel
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { updateUser } from '@/services/api/admin.js'
import { userInputError } from '@/services/message/admin.js'
import {
  initialiseInputElements,
  cleanUpInputElements,
  areValidInputs
} from '@/services/utils/form.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import { capitaliseFirstLetterOfEachWord } from '@/services/utils/string.js'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'UserUpdate',

  components: {
    Spinner
  },

  props: {
    userId: {
      type: String,
      require: true
    },

    firstName: {
      type: String,
      require: true
    },

    lastName: {
      type: String,
      require: true
    },

    username: {
      type: String,
      require: true
    },

    email: {
      type: String,
      require: true
    },

    applicationAccessFlags: {
      type: Array,
      require: true
    }
  },

  data() {
    return {
      isCurrentUser: false, // whether edited user is current user
      firstNameInput: this.firstName,
      lastNameInput: this.lastName,
      usernameInput: this.username,
      passwordInput: '',
      emailInput: this.email,
      applicationAccessFlagsArr: [],
      userInputError: userInputError,
      updateUserErrorMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      currentUserId: 'auth/getUserId',
      nonDashboardApplicationNames:
        'application/getNonDashboardApplicationNames'
    })
  },

  mounted() {
    initialiseInputElements()

    // Check if edited user is current user
    if (this.currentUserId === this.userId) {
      this.isCurrentUser = true
    }

    this.applicationAccessFlags.forEach((flag) => {
      // Convert String to Boolean as Vue passes array props as Sarray of Strings
      this.applicationAccessFlagsArr.push(flag === 'true')
    })
  },

  beforeUnmount() {
    cleanUpInputElements()
  },

  methods: {
    ...mapMutations('application', ['toggleApplicationAccessibility']),

    ...mapMutations('user', ['setFirstName', 'setLastName']),

    onCancelAction() {
      this.$router.replace({ name: 'Admin' })
    },

    async onUpdateUser(event) {
      // Reset update user error message
      this.updateUserErrorMessage = ''

      // Validate form
      if (!areValidInputs('user-' + this.userId + '__form-container')) {
        event.stopPropagation()
        return
      }

      // Sanitise first and last name
      this.firstNameInput = capitaliseFirstLetterOfEachWord(this.firstNameInput)
      this.lastNameInput = capitaliseFirstLetterOfEachWord(this.lastNameInput)

      try {
        // Call update user API
        showSpinner()
        await updateUser(
          this.userId,
          this.firstNameInput,
          this.lastNameInput,
          this.passwordInput,
          this.emailInput,
          this.applicationAccessFlagsArr
        )
        hideSpinner()

        // Update user information in Vuex application and user stores
        // if edited user is current user
        if (this.isCurrentUser) {
          this.toggleApplicationAccessibility(this.applicationAccessFlagsArr)
          this.setFirstName(this.firstNameInput)
          this.setLastName(this.lastNameInput)
        }

        // Go back to list user page
        this.$router.go(-1)
      } catch (e) {
        hideSpinner()
        this.updateUserErrorMessage = e.response.data.message
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_admin.scss';
</style>
