<template>
  <spinner />

  <div class="container-fluid">
    <div class="row m-0 vh-100 vw-100">
      <div class="login__form-col col-12 col-lg-6 m-auto">
        <!-- Logo -->
        <img
          src="@/assets/images/tds-logo-colored.png"
          class="img-fluid mb-5 mt-3" />

        <!-- Headings -->
        <p class="login__header main-heading m-0">Sign In</p>
        <p class="main-text--light mb-5">
          Enter your email and password to login
        </p>

        <!-- Form -->
        <form @submit.prevent="onLogin">
          <!-- Form - Email input -->
          <div class="mb-3">
            <label for="login__email-input" class="form-label sub-text--light">
              Email
            </label>
            <input
              type="email"
              class="form-control input"
              id="login__email-input"
              v-model="email"
              placeholder="Email" />
          </div>

          <!-- Form - Password input -->
          <div class="mb-3">
            <label
              for="login__password-input"
              class="form-label sub-text--light">
              Password
            </label>
            <input
              type="password"
              class="form-control input"
              id="login__password-input"
              v-model="password"
              placeholder="Password"
              aria-label="Password" />
          </div>

          <!-- Form - Forget password link -->
          <p
            class="login__forget-password sub-text--light text-decoration-underline"
            @click="onForgetPassword">
            Forgot your password?
          </p>

          <!-- Form - Input error message -->
          <p v-if="inputErrorMessage" class="error-message mt-3">
            {{ inputErrorMessage }}
          </p>

          <!-- Form - Login button -->
          <button type="submit" class="input-group button-primary my-3">
            Login
          </button>
        </form>
      </div>

      <!-- Image (at rightmost side) -->
      <div class="login__image-col col-lg-6">
        <img
          src="@/assets/images/login-image.png"
          class="login__image img-fluid" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { loginUser } from '@/services/api/auth.js'
import { inputError } from '@/services/message/auth.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'Login',

  components: {
    Spinner
  },

  data() {
    return {
      email: '',
      password: '',
      inputErrorMessage: '',
      inputError: inputError
    }
  },

  methods: {
    ...mapMutations('auth', ['setUserId', 'setBeyondToken', 'setLoginFlag']),

    ...mapMutations('application', [
      'setInitialApplications',
      'toggleApplicationAccessibility'
    ]),

    ...mapMutations('user', ['setUsername', 'setFirstName', 'setLastName']),

    async onLogin() {
      // Reset input error message
      this.inputErrorMessage = ''

      // Input error message: Empty email
      if (!this.email) {
        this.inputErrorMessage = inputError.emptyEmail
        return
      }

      // Input error message: Empty password
      if (!this.password) {
        this.inputErrorMessage = inputError.emptyPassword
        return
      }

      try {
        // Call login user API
        showSpinner()
        const res = await loginUser(this.email, this.password)
        hideSpinner()

        // Store user information in Vuex auth and user stores
        this.setUserId(res.data.user._id)
        this.setBeyondToken(res.data.user.beyondToken)
        this.setLoginFlag(true)
        this.setUsername(res.data.user.username)
        this.setFirstName(res.data.user.firstName)
        this.setLastName(res.data.user.lastName)

        // Toggle applications accessibility in Vuex application store
        this.setInitialApplications()
        const accessibilityArr = [
          true, // dashboard application accessibility
          res.data.user.admin,
          res.data.user.rpm,
          res.data.user.WeLearn,
          res.data.user.WeMeet,
          res.data.user.WePlan,
          res.data.user.WeShare
        ]
        this.toggleApplicationAccessibility(accessibilityArr)

        // Redirect user to intended destination page (if any)
        const redirectUrl = this.$route.query.redirect
        if (redirectUrl) {
          this.$router.push(redirectUrl)
        } else {
          this.$router.push({ name: 'Dashboard' })
        }
      } catch (e) {
        hideSpinner()

        // Input error message: Invalid login credential
        this.inputErrorMessage = inputError.invalidEmailOrPassword
      }
    },

    onForgetPassword() {
      this.$router.push({ name: 'ForgetPassword' })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_auth.scss';
</style>
