const accessOptions = [
  { name: 'Public', boolean: false },
  { name: 'Private', boolean: true }
]

const perPageOptions = [10, 20, 30, 40, 50]

const recurringOptions = ['Daily', 'Weekly', 'Bi-weekly', 'Monthly', 'Yearly']

function getRecurringOptionsEncoding(recurringOption) {
  return [
    recurringOption === recurringOptions[0],
    recurringOption === recurringOptions[1],
    recurringOption === recurringOptions[2],
    recurringOption === recurringOptions[3],
    recurringOption === recurringOptions[4]
  ]
}

const visibilityOptions = [
  { name: 'Public', boolean: true },
  { name: 'Private', boolean: false }
]

export {
  accessOptions,
  perPageOptions,
  recurringOptions,
  getRecurringOptionsEncoding,
  visibilityOptions
}
