<template>
  <modal-view
    :modalPopupId="modalPopupId"
    image="trash-bin.svg"
    mainHeading="Confirm Delete?"
    :subHeading="getSubHeading()"
    :errorMessage="deleteRoomErrorMessage" />
</template>

<script>
import { deleteRoom } from '@/services/api/we-meet.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import ModalView from '@/components/ModalView.vue'

export default {
  name: 'RoomDelete',

  components: {
    ModalView
  },

  props: {
    modalPopupId: {
      type: String,
      require: true
    },

    roomId: {
      type: String,
      require: true
    },

    roomName: {
      type: String,
      require: true
    }
  },

  emits: ['confirmDelete'],

  data() {
    return {
      deleteRoomErrorMessage: ''
    }
  },

  mounted() {
    document
      .getElementById(this.modalPopupId)
      .querySelector('.modal-view__confirm')
      .addEventListener('click', () => {
        this.onDeleteRoom()
      })
  },

  beforeUnmount() {
    document
      .getElementById(this.modalPopupId)
      .querySelector('.modal-view__confirm')
      .removeEventListener('click', () => {
        this.onDeleteRoom()
      })
  },

  methods: {
    getSubHeading() {
      return 'You are deleting ' + this.roomName + '.'
    },

    async onDeleteRoom() {
      try {
        // Call delete room API
        showSpinner()
        await deleteRoom(this.roomId)
        hideSpinner()

        // Emit event to WeMeetLobby component
        this.$emit('confirmDelete')
      } catch (e) {
        hideSpinner()
        this.deleteRoomErrorMessage = e.response.data.message
      }
    }
  }
}
</script>
