<template>
  <modal-view
    :modalPopupId="modalPopupId"
    image="trash-bin.svg"
    mainHeading="Confirm Delete?"
    :subHeading="getSubHeading()"
    :errorMessage="deleteUserErrorMessage" />
</template>

<script>
import { deleteUser } from '@/services/api/admin.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import ModalView from '@/components/ModalView.vue'

export default {
  name: 'DeleteIndividualModal',

  components: {
    ModalView
  },

  props: {
    modalPopupId: {
      type: String,
      require: true
    },

    userId: {
      type: String,
      require: true
    },

    userName: {
      type: String,
      require: true
    },

    userIndex: {
      type: Number,
      require: true
    }
  },

  emits: ['confirmDelete'],

  data() {
    return {
      deleteUserErrorMessage: ''
    }
  },

  mounted() {
    document
      .getElementById(this.modalPopupId)
      .querySelector('.modal-view__confirm')
      .addEventListener('click', () => {
        this.onDeleteUser()
      })
  },

  beforeUnmount() {
    document
      .getElementById(this.modalPopupId)
      .querySelector('.modal-view__confirm')
      .removeEventListener('click', () => {
        this.onDeleteUser()
      })
  },

  methods: {
    getSubHeading() {
      return 'You are deleting the user profile of ' + this.userName + '.'
    },

    async onDeleteUser() {
      try {
        // Call delete user API
        showSpinner()
        var numOfUsersDeleted = 0
        await deleteUser(this.userId)
        numOfUsersDeleted++
        hideSpinner()

        // Emit event to UserList component
        this.$emit('confirmDelete', numOfUsersDeleted, Array.of(this.userIndex))
      } catch (e) {
        hideSpinner()
        this.deleteUserErrorMessage = e.response.data.message
      }
    }
  }
}
</script>
