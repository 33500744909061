<template>
  <div>
    <spinner />

    <nav-bar />

    <div class="page-view">
      <div>
        <img
          class="user-profile__image"
          src="@/assets/images/user-profile-illustration.svg" />

        <p class="user-profile__header main-heading mb-0">
          {{ firstName }} {{ lastName }}
        </p>
      </div>

      <p v-if="getProfileErrorMessage" class="error-message">
        {{ getProfileErrorMessage }}
      </p>

      <!-- Edit profile for profile tab -->
      <p
        class="user-profile__edit main-text text-decoration-underline"
        :class="{ invisible: selectedTab !== tabs[0] }"
        @click="onEditProfile">
        Edit Profile
      </p>

      <div class="user-profile-scroll scroll-view">
        <div class="user-profile card">
          <!-- Tabs -->
          <div class="tabs">
            <div
              class="tab d-inline-block p-3"
              :class="{ 'tab--selected': selectedTab === tab }"
              @click="onSelectTab(index)"
              v-for="(tab, index) in tabs"
              :key="tab">
              <p
                class="tab-text mb-0"
                :class="{ 'tab-text--selected': selectedTab === tab }">
                {{ tab }}
              </p>
            </div>
          </div>

          <div class="card-body">
            <!-- Tab - Profile -->
            <div v-if="selectedTab === tabs[0]" class="tab__profile">
              <profile
                :isEditProfile="isEditProfile"
                :firstName="firstName"
                :lastName="lastName"
                :username="username"
                :email="email"
                @editedProfile="onEditedProfile"
                @cancelEdit="onCancelEdit" />
            </div>

            <!-- Tab - Settings -->
            <div v-if="selectedTab === tabs[1]" class="tab__settings">
              <settings />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentUser } from '@/services/api/admin.js'
import { profileMessage } from '@/services/message/user.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import NavBar from '@/components/NavBar.vue'
import Profile from '@/views/user/profile-tabs/Profile.vue'
import Settings from '@/views/user/profile-tabs/Settings.vue'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'UserProfile',

  components: {
    NavBar,
    Profile,
    Settings,
    Spinner
  },

  props: {
    destinationTab: {
      type: String,
      reuqire: false,
      default(value) {
        // props have no access to data
        return ['Profile', 'Settings'].includes(value) ? value : 'Profile'
      }
    }
  },

  data() {
    return {
      tabs: ['Profile', 'Settings'], // update destinationTab prop if this changes
      selectedTab: '',
      isEditProfile: false,
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      profileMessage: profileMessage,
      getProfileErrorMessage: ''
    }
  },

  async mounted() {
    this.selectedTab = this.destinationTab
    await this.getCurrentUser()
  },

  methods: {
    async getCurrentUser() {
      try {
        showSpinner()
        const res = await getCurrentUser()
        hideSpinner()
        this.firstName = res.data.user.firstName
        this.lastName = res.data.user.lastName
        this.username = res.data.user.username
        this.email = res.data.user.email
      } catch (e) {
        hideSpinner()
        this.getProfileErrorMessage = profileMessage.retrievalError
      }
    },

    onEditProfile() {
      this.isEditProfile = true
    },

    onSelectTab(index) {
      this.selectedTab = this.tabs[index]
      this.isEditProfile = false
    },

    async onEditedProfile() {
      await this.getCurrentUser()
      this.isEditProfile = false
    },

    onCancelEdit() {
      this.isEditProfile = false
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_user.scss';
</style>
