<template>
  <div class="d-flex justify-content-center">
    <p class="month__prev d-inline-block" @click="getPrevMonth">&lt;</p>
    <input
      type="month"
      class="month form-control input d-inline-block"
      v-model="month" />
    <p class="month__next d-inline-block" @click="getNextMonth">&gt;</p>
  </div>
</template>

<script>
import { getCurrentHtmlMonth, getHtmlMonth } from '@/services/utils/date.js'

export default {
  name: 'MonthToggle',

  data() {
    return {
      month: ''
    }
  },

  watch: {
    month(newMonth) {
      this.$emit('changeMonth', newMonth)
    }
  },

  mounted() {
    this.month = getCurrentHtmlMonth()
  },

  methods: {
    getPrevMonth() {
      const prevMonth = new Date(this.month)
      prevMonth.setMonth(prevMonth.getMonth() - 1)
      this.month = getHtmlMonth(prevMonth)
    },

    getNextMonth() {
      const nextMonth = new Date(this.month)
      nextMonth.setMonth(nextMonth.getMonth() + 1)
      this.month = getHtmlMonth(nextMonth)
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/components/_month-toggle.scss';
</style>
