<template>
  <div class="recording card">
    <div class="card-body">
      <div class="mb-2">
        <!-- Header -->
        <p
          class="recording__header main-text--bold d-inline-block text-truncate mb-0">
          {{ recordingObj.meetingName }}
        </p>

        <!-- Visibility select menu -->
        <select
          v-if="recordingObj.owner"
          class="recording__visibility form-select select--dark d-inline-block m-0 px-2 py-1"
          v-model="visibility">
          <option
            v-for="option in visibilityOptions"
            :value="option.boolean"
            :key="option.name">
            {{ option.name }}
          </option>
        </select>
      </div>

      <div class="row g-0">
        <!-- Date and time -->
        <div class="recording__date-time col-12 col-sm-5">
          <p class="sub-text--light mb-0">Date &#38; Time</p>
          <p class="sub-text mb-0">
            {{ getDate(recordingObj.startTime, recordingObj.endTime) }}
          </p>
          <p class="sub-text mb-0">
            {{ getTime(recordingObj.startTime, recordingObj.endTime) }}
          </p>
        </div>

        <!-- Duration -->
        <div class="col-12 col-sm-5">
          <p class="sub-text--light mb-0">Duration</p>
          <p class="sub-text mb-0">
            {{ getDuration(recordingObj.recordingDuration) }}
          </p>
        </div>
      </div>

      <!-- Copy recording URL button -->
      <img
        src="@/assets/icons/copy-link.svg"
        class="recording__copy-url img-fluid icon d-inline-block"
        @click="onCopyUrl(recordingObj.recordingURL)" />

      <!-- Watch button -->
      <button
        type="button"
        class="recording__watch button-secondary button--small"
        @click="onWatchRecording(recordingObj.recordingURL)">
        Watch
      </button>
    </div>
  </div>
</template>

<script>
import { updateRecordingVisibility } from '@/services/api/we-meet.js'
import {
  getDateLocaleStr,
  getTimeLocaleStr,
  getDuration
} from '@/services/utils/date.js'
import { visibilityOptions } from '@/services/utils/select-menu.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'

export default {
  name: 'Recording',

  props: {
    recording: {
      type: Object,
      require: true
    }
  },

  emits: ['displayCopyUrlToast', 'displayUpdateVisibilityToast'],

  data() {
    return {
      recordingObj: this.recording,
      visibility: null,
      visibilityOptions: visibilityOptions,
      hasMounted: false
    }
  },

  watch: {
    visibility: {
      handler(newBool) {
        if (this.hasMounted) {
          // Prevent watcher from updating visibility
          // if component is not mounted yet
          this.onUpdateVisibility(newBool)
        }
      }
    }
  },

  beforeMount() {
    this.visibility = this.recordingObj.recordingVisibility
    this.hasMounted = true
  },

  methods: {
    async onUpdateVisibility(newVisibility) {
      try {
        // Call update recording visibility API
        showSpinner()
        await updateRecordingVisibility(
          this.recordingObj.meetingLogDatabaseID,
          newVisibility
        )
        hideSpinner()
      } catch (e) {
        hideSpinner()

        // Emit event to Meeting Recordings component to display error message
        this.$emit('displayUpdateVisibilityToast', e.response.data.message)
      }
    },

    onCopyUrl(str) {
      // Copy text to clipboard
      navigator.clipboard.writeText(str)

      // Emit event to MeetingRecordings component to display copied message
      this.$emit('displayCopyUrlToast')
    },

    getDate(startDateStr, endDateStr) {
      const start = getDateLocaleStr(startDateStr)
      const end = getDateLocaleStr(endDateStr)
      if (start === end) {
        return start
      } else {
        return start + ' - ' + end
      }
    },

    getTime(startDateStr, endDateStr) {
      return (
        getTimeLocaleStr(startDateStr) + ' - ' + getTimeLocaleStr(endDateStr)
      )
    },

    getDuration(durationArr) {
      return getDuration(durationArr)
    },

    onWatchRecording(recordingUrl) {
      window.open(recordingUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
