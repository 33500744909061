import { instance } from './global.js'

function loginUser(email, password) {
  return instance.post('/user/login', {
    email: email,
    password: password
  })
}

function forgetPassword(email) {
  return instance.put('/user/forgetPassword', {
    email: email
  })
}

export { loginUser, forgetPassword }
