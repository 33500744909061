<template>
  <div class="upcoming-meeting card">
    <div class="card-body">
      <div class="border-bottom mb-2 pb-1">
        <!-- Header -->
        <p class="upcoming-meeting__heading heading-two d-inline-block mb-0">
          {{ upcomingMeeting.meetingName }}
        </p>

        <!-- Settings dropdown -->
        <div v-if="upcomingMeeting.owner" class="dropdown float-end">
          <img
            src="@/assets/icons/settings.svg"
            class="dropdown-toggle img-fluid icon"
            id="settingsDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false" />

          <ul class="dropdown-menu" aria-labelledby="settingsDropdown">
            <!-- Settings dropdown - Edit meeting -->
            <li>
              <a
                class="dropdown-item"
                :data-open="`editMeetingModalUpcoming${upcomingMeeting.meetingID}`">
                Edit Meeting
              </a>
            </li>

            <!-- Settings dropdown - Delete this meeting -->
            <li>
              <a
                class="dropdown-item"
                :data-open="`deleteMeetingModalUpcoming${upcomingMeeting.meetingID}`">
                Delete This Meeting
              </a>
            </li>

            <!-- Settings dropdown - Delete this and future meetings -->
            <li v-if="upcomingMeeting.recurring">
              <a
                class="dropdown-item"
                :data-open="`deleteMeetingModalUpcomingRecurring${upcomingMeeting.meetingID}${upcomingMeeting.recurringMeetingID}`">
                Delete This & Future Meetings
              </a>
            </li>
          </ul>

          <!-- Modal - Edit one-time meeting (Upcoming) -->
          <meeting-edit
            v-if="upcomingMeeting.meetingType === 'One Off'"
            :modalPopupId="`editMeetingModalUpcoming${upcomingMeeting.meetingID}`"
            :isOneTimeMeeting="true"
            :isRecurring="false"
            :roomId="upcomingMeeting.roomID"
            :meetingId="upcomingMeeting.meetingID"
            :meetingName="upcomingMeeting.meetingName"
            :meetingStartDateStr="upcomingMeeting.startTime"
            :meetingEndDateStr="upcomingMeeting.endTime"
            :isRoomLocked="upcomingMeeting.lockedRoom"
            :isApprovalRequired="upcomingMeeting.approvalRequired"
            :areUsersMuted="upcomingMeeting.muteOnStart"
            :isMeetingRecorded="upcomingMeeting.autoStartRecording"
            @confirmModalForm="onUpdateCard" />

          <!-- Modal - Edit room's meeting (Upcoming) -->
          <meeting-edit
            v-else
            :modalPopupId="`editMeetingModalUpcoming${upcomingMeeting.meetingID}`"
            :isOneTimeMeeting="false"
            :isRecurring="upcomingMeeting.recurring"
            :roomId="upcomingMeeting.roomID"
            :meetingId="upcomingMeeting.meetingID"
            :meetingName="upcomingMeeting.meetingName"
            :meetingStartDateStr="upcomingMeeting.startTime"
            :meetingEndDateStr="upcomingMeeting.endTime"
            @confirmModalForm="onUpdateCard" />

          <!-- Modal - Delete this meeting (Upcoming) -->
          <meeting-delete
            :modalPopupId="`deleteMeetingModalUpcoming${upcomingMeeting.meetingID}`"
            :meetingId="upcomingMeeting.meetingID"
            :meetingName="upcomingMeeting.meetingName"
            :isDeleteRecurring="false"
            @confirmDelete="onUpdateCard" />

          <!-- Modal - Delete this and future meetings (Upcoming) -->
          <meeting-delete
            v-if="upcomingMeeting.recurring"
            :modalPopupId="`deleteMeetingModalUpcomingRecurring${upcomingMeeting.meetingID}${upcomingMeeting.recurringMeetingID}`"
            :meetingId="upcomingMeeting.recurringMeetingID"
            :meetingName="upcomingMeeting.meetingName"
            :isDeleteRecurring="true"
            @confirmDelete="onUpdateCard" />
        </div>
      </div>

      <div>
        <!-- Recurrence badge -->
        <div
          v-if="upcomingMeeting.meetingType !== 'One Off'"
          class="d-inline-block mb-2">
          <span class="badge rounded-pill me-2">
            {{ upcomingMeeting.meetingType }}
          </span>
        </div>

        <!-- Room name badge -->
        <div
          v-if="upcomingMeeting.meetingType !== 'One Off'"
          class="d-inline-block mb-2">
          <span class="badge rounded-pill">
            <p
              class="upcoming-meeting__room-badge d-inline-block text-truncate mb-0">
              {{ upcomingMeeting.roomName }}
            </p>
          </span>
        </div>
      </div>

      <!-- Meeting date and start and end time -->
      <div class="d-inline-block">
        <p class="sub-text mb-0">
          {{ getDate(upcomingMeeting.startTime) }}
        </p>
        <p class="sub-text mb-0">
          {{
            getStartAndEndTime(
              upcomingMeeting.startTime,
              upcomingMeeting.endTime
            )
          }}
        </p>
      </div>

      <br class="upcoming-meeting--narrow" />

      <!-- Share meeting button -->
      <img
        src="@/assets/icons/share.svg"
        class="upcoming-meeting__share-meeting img-fluid icon"
        :data-open="`shareMeetingModalUpcoming${upcomingMeeting.meetingID}`" />

      <!-- Modal - Share meeting (Upcoming) -->
      <meeting-share
        :modalPopupId="`shareMeetingModalUpcoming${upcomingMeeting.meetingID}`"
        :roomId="upcomingMeeting.roomID"
        :meetingUrl="upcomingMeeting.meetingURL"
        :meetingAccessCode="upcomingMeeting.accessCode"
        :participants="upcomingMeeting.participants"
        @confirmModalForm="onUpdateCard" />

      <!-- Start meeting button for own's meeting -->
      <button
        v-if="upcomingMeeting.owner"
        type="button"
        class="upcoming-meeting__start-meeting button-secondary button--small"
        @click="
          onStartMeeting(
            upcomingMeeting.roomID,
            upcomingMeeting.meetingID,
            upcomingMeeting.meetingType === 'One Off'
          )
        ">
        Start
      </button>

      <!-- Join meeting button for other user's meeting -->
      <button
        v-else
        type="button"
        class="upcoming-meeting__join-meeting button-secondary button--small"
        @click="onJoinMeeting(upcomingMeeting.meetingURL)">
        Join
      </button>
    </div>
  </div>
</template>

<script>
import { joinMeeting } from '@/services/api/we-meet.js'
import { getDateLocaleStr, getTimeLocaleStr } from '@/services/utils/date.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import MeetingDelete from '@/views/we-meet/delete-modals/MeetingDelete.vue'
import MeetingEdit from '@/views/we-meet/modal-forms/MeetingEdit.vue'
import MeetingShare from '@/views/we-meet/modal-forms/MeetingShare.vue'

export default {
  name: 'UpcomingMeeting',

  components: {
    MeetingDelete,
    MeetingEdit,
    MeetingShare
  },

  props: {
    upcomingMeeting: {
      type: Object,
      require: true
    }
  },

  emits: ['displayStartMeetingToast', 'updateCard'],

  methods: {
    onUpdateCard() {
      this.$emit('updateCard')
    },

    getDate(startDate) {
      return getDateLocaleStr(startDate)
    },

    getStartAndEndTime(startDatetime, endDatetime) {
      return (
        getTimeLocaleStr(startDatetime) + ' - ' + getTimeLocaleStr(endDatetime)
      )
    },

    async onStartMeeting(roomId, meetingId, isOneTimeMeeting) {
      try {
        showSpinner()
        const res = await joinMeeting(roomId, meetingId, isOneTimeMeeting)
        window.open(res.data.WeMeetRoomURL)
        hideSpinner()
      } catch (e) {
        this.$emit('displayStartMeetingToast', e.response.data.message)
      }
    },

    async onJoinMeeting(meetingUrl) {
      window.open(meetingUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
