<template>
  <div class="top-bar border-bottom mb-4">
    <!-- User's name -->
    <div class="top-bar__user">
      <p class="heading-one mb-0">{{ firstName }} {{ lastName }}</p>
    </div>

    <div class="top-bar__icons d-flex flex-row-reverse">
      <!-- Logout button -->
      <div class="icon__container ms-3" @click="onLogoutClick">
        <img
          src="@/assets/icons/logout.svg"
          class="img-fluid icon icon--expand" />
      </div>

      <!-- Profile button -->
      <div class="icon__container" @click="onProfileClick">
        <img
          src="@/assets/icons/profile.svg"
          class="img-fluid icon icon--expand" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TopBar',

  computed: {
    ...mapGetters({
      firstName: 'user/getFirstName',
      lastName: 'user/getLastName'
    })
  },

  methods: {
    onLogoutClick() {
      this.$router.push({ name: 'Logout' })
    },

    onProfileClick() {
      this.$router.push({ name: 'UserProfile' })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/components/_top-bar.scss';
</style>
