<template>
  <div class="toast-container translate-middle-x">
    <div
      role="alert"
      class="toast align-items-center"
      :id="`${toastId}`"
      aria-live="assertive"
      aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body">
          {{ toastMessage }}
        </div>
        <button
          type="button"
          class="btn-close me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToastPopup',

  props: {
    toastId: {
      type: String,
      require: true
    },

    toastMessage: {
      type: String,
      require: true
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/components/_toast.scss';
</style>
