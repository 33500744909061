<template>
  <spinner />

  <div id="profile__form-container">
    <form class="form" @submit.prevent="onUpdateProfile($event)" novalidate>
      <div class="row row-cols-1 row-cols-lg-2">
        <div class="col mb-4">
          <label for="first-name-input" class="form-label sub-text--light">
            First name
          </label>
          <p v-show="!isEditProfile" class="main-text mb-0">{{ firstName }}</p>
          <input
            v-show="isEditProfile"
            type="text"
            class="form-control input"
            id="first-name-input"
            v-model="firstNameInput"
            pattern="^[a-zA-Z]+( [a-zA-Z]+)*$"
            placeholder="First name"
            required />
          <div class="form__invalid-message">
            {{ userInputError.invalidFirstName }}
          </div>
        </div>

        <!-- Last name input -->
        <div class="col mb-4">
          <label for="last-name-input" class="form-label sub-text--light">
            Last name
          </label>
          <p v-show="!isEditProfile" class="main-text mb-0">{{ lastName }}</p>
          <input
            v-show="isEditProfile"
            type="text"
            class="form-control input"
            id="last-name-input"
            v-model="lastNameInput"
            pattern="^[a-zA-Z]+( [a-zA-Z]+)*$"
            placeholder="Last name"
            required />
          <div class="form__invalid-message">
            {{ userInputError.invalidLastName }}
          </div>
        </div>

        <!-- Username input -->
        <div class="col mb-4">
          <label for="username-input" class="form-label sub-text--light">
            Username
          </label>
          <p v-show="!isEditProfile" class="main-text mb-0">{{ username }}</p>
          <input
            v-show="isEditProfile"
            type="text"
            class="form-control input"
            id="username-input"
            v-model="usernameInput"
            disabled />
        </div>

        <!-- Email input -->
        <div class="col mb-4">
          <label for="email-input" class="form-label sub-text--light">
            Email
          </label>
          <p v-show="!isEditProfile" class="main-text mb-0">{{ email }}</p>
          <input
            v-show="isEditProfile"
            type="email"
            class="form-control input"
            id="email-input"
            v-model="emailInput"
            pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
            placeholder="Email"
            required />
          <div class="form__invalid-message">
            {{ userInputError.invalidEmail }}
          </div>
        </div>
      </div>

      <!-- Applications access -->
      <p class="sub-text--light mb-2">Applications Access</p>

      <p
        class="main-text mb-2"
        v-for="application in nonDashboardApplications"
        :key="application.name">
        {{ application.name }}
      </p>

      <!-- Update profile error message -->
      <p v-if="updateProfileErrorMessage" class="error-message mt-4">
        {{ updateProfileErrorMessage }}
      </p>

      <!-- Save and cancel buttons -->
      <button
        v-show="isEditProfile"
        type="submit"
        class="button-primary mb-2 me-3 mt-4">
        Save
      </button>

      <button
        v-show="isEditProfile"
        type="button"
        class="button-outline"
        @click="onCancelAction">
        Cancel
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { updateUserProfile } from '@/services/api/user.js'
import { userInputError } from '@/services/message/admin.js'
import {
  initialiseInputElements,
  cleanUpInputElements,
  areValidInputs,
  resetInputsValidation
} from '@/services/utils/form.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import { capitaliseFirstLetterOfEachWord } from '@/services/utils/string.js'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'Profile',

  components: {
    Spinner
  },

  props: {
    isEditProfile: {
      type: Boolean,
      require: true
    },

    firstName: {
      type: String,
      require: true
    },

    lastName: {
      type: String,
      require: true
    },

    username: {
      type: String,
      require: true
    },

    email: {
      type: String,
      require: true
    }
  },

  emits: ['cancelEdit', 'editedProfile'],

  data() {
    return {
      firstNameInput: '',
      lastNameInput: '',
      usernameInput: '',
      emailInput: '',
      userInputError: userInputError,
      updateProfileErrorMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      userId: 'auth/getUserId',
      nonDashboardApplications:
        'application/getAccessibleNonDashboardApplications'
    })
  },

  watch: {
    firstName: {
      handler(newStr) {
        this.firstNameInput = newStr
      }
    },

    lastName: {
      handler(newStr) {
        this.lastNameInput = newStr
      }
    },

    username: {
      handler(newStr) {
        this.usernameInput = newStr
      }
    },

    email: {
      handler(newStr) {
        this.emailInput = newStr
      }
    }
  },

  mounted() {
    initialiseInputElements()
    this.firstNameInput = this.firstName
    this.lastNameInput = this.lastName
    this.usernameInput = this.username
    this.emailInput = this.email
  },

  beforeUnmount() {
    cleanUpInputElements()
  },

  methods: {
    ...mapMutations('user', ['setFirstName', 'setLastName']),

    async onUpdateProfile(event) {
      // Reset update profile error message
      this.updateProfileErrorMessage = ''

      // Validate form
      if (!areValidInputs('profile__form-container')) {
        event.stopPropagation()
        return
      }

      // Sanitise first and last name
      this.firstNameInput = capitaliseFirstLetterOfEachWord(this.firstNameInput)
      this.lastNameInput = capitaliseFirstLetterOfEachWord(this.lastNameInput)

      try {
        // Call update user profile API
        showSpinner()
        await updateUserProfile(
          this.userId,
          this.firstNameInput,
          this.lastNameInput,
          this.emailInput
        )
        hideSpinner()

        // Update user information in Vuex user store
        this.setFirstName(this.firstNameInput)
        this.setLastName(this.lastNameInput)

        // Emit edited profile event to UserProfile component
        this.$emit('editedProfile')
      } catch (e) {
        hideSpinner()
        this.updateProfileErrorMessage = e.response.data.message
      }
    },

    onCancelAction() {
      // Reset form
      resetInputsValidation('profile__form-container')
      this.firstNameInput = this.firstName
      this.lastNameInput = this.lastName
      this.emailInput = this.email
      this.updateProfileErrorMessage = ''

      // Emit cancel edit event to UserProfile component
      this.$emit('cancelEdit')
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_user.scss';
</style>
