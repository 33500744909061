<template>
  <div class="room card">
    <div class="card-body">
      <div class="border-bottom mb-3 pb-1">
        <!-- Header -->
        <p class="room__name heading-two d-inline-block text-truncate mb-0">
          {{ room.name }}
        </p>

        <!-- Settings dropdown -->
        <div class="dropstart float-end">
          <img
            src="@/assets/icons/settings.svg"
            class="dropdown-toggle img-fluid icon"
            id="settingsDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false" />

          <ul class="dropdown-menu" aria-labelledby="settingsDropdown">
            <!-- Settings dropdown - Room settings -->
            <li>
              <a
                class="dropdown-item"
                :data-open="`roomSettingsModal${room.roomID}`">
                Room Settings
              </a>
            </li>

            <!-- Settings dropdown - Delete room -->
            <li>
              <a
                class="dropdown-item"
                :data-open="`deleteRoomModal${room.roomID}`">
                Delete Room
              </a>
            </li>
          </ul>

          <!-- Modal - Room settings -->
          <room-settings
            :modalPopupId="`roomSettingsModal${room.roomID}`"
            :roomId="room.roomID"
            :roomName="room.name"
            :welcomeMessage="room.welcome"
            :isRoomLocked="room.lockedRoom"
            :isApprovalRequired="room.approvalRequired"
            :areUsersMuted="room.muteOnStart"
            :isMeetingRecorded="room.autoStartRecording"
            @confirmModalForm="onUpdateCard" />

          <!-- Modal - Delete room -->
          <room-delete
            :modalPopupId="`deleteRoomModal${room.roomID}`"
            :roomId="room.roomID"
            :roomName="room.name"
            @confirmDelete="onUpdateCard" />
        </div>
      </div>

      <!-- Next meeting bar (If next meeting exists) -->
      <div
        v-if="room.scheduledMeetings.length"
        class="room__next-meeting-bar mb-2 p-2">
        <!-- Next meeting bar - Next meeting name, date and time -->
        <div class="room__next-meeting-info d-inline-block">
          <p class="sub-text mb-0">Next meeting:</p>

          <p class="main-text--bold mb-1">
            {{
              getUpcomingMeetingDateAndTime(
                room.scheduledMeetings[0].startTime,
                room.scheduledMeetings[0].endTime
              )
            }}
          </p>

          <p class="sub-text text-truncate mb-0">
            {{ room.scheduledMeetings[0].meetingName }}
          </p>
        </div>

        <!-- Next meeting bar - Share meeting button -->
        <img
          src="@/assets/icons/share.svg"
          class="room__share-meeting img-fluid icon"
          :data-open="`shareMeetingModalRoom${room.roomID}`" />

        <!-- Modal - Share meeting (Room) -->
        <meeting-share
          :modalPopupId="`shareMeetingModalRoom${room.roomID}`"
          :roomId="room.roomID"
          :meetingUrl="room.scheduledMeetings[0].meetingURL"
          :meetingAccessCode="room.accessCode"
          :participants="room.participants"
          @confirmModalForm="onUpdateCard" />

        <!-- Next meeting bar - Start meeting button -->
        <button
          type="button"
          class="room__start-meeting button-secondary button--small"
          @click="onStartMeeting(room.roomID, room.scheduledMeetings[0]._id)">
          Start Meeting
        </button>
      </div>

      <!-- No meeting bar (If next meeting does not exist) -->
      <div v-else class="room-no-meeting__meeting-bar mb-2 p-3">
        <!-- No meeting bar - No meeting description-->
        <div class="d-inline-block">
          <p class="room__meeting-desc sub-text mb-0">
            No meetings have been scheduled
          </p>
        </div>

        <!-- No meeting bar - Start meeting button -->
        <button
          type="button"
          class="room-no-meeting__start-meeting button-secondary button--small float-end"
          @click="onStartMeeting(room.roomID, '')">
          Start Meeting
        </button>

        <!-- No meeting bar - Share meeting button -->
        <img
          src="@/assets/icons/share.svg"
          class="room-no-meeting__share-meeting img-fluid icon float-end"
          :data-open="`shareMeetingModalRoom${room.roomID}`" />

        <!-- Modal - Share meeting (Room) -->
        <meeting-share
          :modalPopupId="`shareMeetingModalRoom${room.roomID}`"
          :roomId="room.roomID"
          :meetingUrl="`https://suite.8eyond.sg/we-meet/room-invite/${room.roomID}`"
          :meetingAccessCode="room.accessCode"
          participants=""
          @confirmModalForm="onUpdateCard" />
      </div>

      <!-- Last meeting date and time -->
      <p class="d-inline-block sub-text--light">
        {{ getLastMeetingDateAndTime(room.LastMeetingOccured) }}
      </p>

      <!-- Meeting history -->
      <p
        class="room__meeting-history d-inline-block sub-text--light text-decoration-underline mt-1 float-end"
        @click="onViewMeetingHistory(room.roomID, room.name)">
        Meeting history
      </p>

      <div class="border-bottom mb-3 pb-3">
        <!-- Add meeting button -->
        <button
          type="button"
          class="room__add-meeting button-primary button--small"
          :data-open="`addMeetingModal${room.roomID}`">
          &#43; Add Meeting
        </button>

        <!-- Modal - Add meeting -->
        <meeting-add
          :modalPopupId="`addMeetingModal${room.roomID}`"
          :roomId="room.roomID"
          @confirmModalForm="onUpdateCard" />
      </div>

      <!-- Upcoming meetings button -->
      <!-- Toggle whether upcoming meetings are displayed below the button -->
      <button
        type="button"
        class="room__upcoming-meetings button-primary button--small mb-2 me-3"
        @click="onToggleUpcomingMeetings">
        <p v-if="displayUpcomingMeetings" class="mb-0">
          Upcoming Meetings &#160;&#8744;
        </p>
        <p v-else class="mb-0">Upcoming Meetings &#160;&#8743;</p>
      </button>

      <!-- View recordings button -->
      <button
        type="button"
        class="room__view-recordings button-primary button--small"
        @click="onViewRecordings(room.roomID, room.name)">
        View Recordings
      </button>

      <!-- Upcoming meetings -->
      <div class="mt-3" :class="{ 'd-none': displayUpcomingMeetings }">
        <!-- No upcoming meetings message -->
        <p v-if="!room.scheduledMeetings.length" class="message mb-0">
          {{ weMeetMessage.noRoomUpcomingMeeting }}
        </p>

        <div
          v-for="upcomingMeeting in room.scheduledMeetings"
          class="border-top mt-2 pt-2"
          :key="upcomingMeeting._id">
          <div>
            <!-- Upcoming meeting - Delete non-recurring meeting button -->
            <img
              v-show="!upcomingMeeting.recurring"
              src="@/assets/icons/trash.svg"
              class="room__upcoming-meeting__action img-fluid icon ms-3 float-end"
              :data-open="`deleteMeetingModalRoom${upcomingMeeting._id}`" />

            <!-- Upcoming meeting - Delete recurring meeting dropdown -->
            <div v-show="upcomingMeeting.recurring" class="dropstart">
              <img
                src="@/assets/icons/trash.svg"
                class="room__upcoming-meeting__action dropdown-toggle img-fluid icon ms-3 float-end"
                id="deleteDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false" />

              <ul class="dropdown-menu" aria-labelledby="deleteDropdown">
                <!-- Upcoming meeting - Delete recurring meeting dropdown - Delete this meeting -->
                <li>
                  <a
                    class="dropdown-item"
                    :data-open="`deleteMeetingModalRoom${upcomingMeeting._id}`">
                    Delete This Meeting
                  </a>
                </li>

                <!-- Upcoming meeting - Delete recurring meeting dropdown - Delete this and future meetings -->
                <li>
                  <a
                    class="dropdown-item"
                    :data-open="`deleteMeetingModalRoomRecurring${upcomingMeeting._id}${upcomingMeeting.recurringMeetingID}`">
                    Delete This & Future Meetings
                  </a>
                </li>
              </ul>
            </div>

            <!-- Upcoming meeting - Edit meeting button -->
            <img
              src="@/assets/icons/edit.svg"
              class="room__upcoming-meeting__action img-fluid icon float-end"
              :data-open="`editMeetingModalRoom${upcomingMeeting._id}`" />
          </div>

          <!-- Modal - Delete meeting (Room) -->
          <meeting-delete
            :modalPopupId="`deleteMeetingModalRoom${upcomingMeeting._id}`"
            :meetingId="upcomingMeeting._id"
            :meetingName="upcomingMeeting.meetingName"
            :isDeleteRecurring="false"
            @confirmDelete="onUpdateCard" />

          <!-- Modal - Delete this and future meetings (Room) -->
          <meeting-delete
            :modalPopupId="`deleteMeetingModalRoomRecurring${upcomingMeeting._id}${upcomingMeeting.recurringMeetingID}`"
            :meetingId="upcomingMeeting.recurringMeetingID"
            :meetingName="upcomingMeeting.meetingName"
            :isDeleteRecurring="true"
            @confirmDelete="onUpdateCard" />

          <!-- Modal - Edit meeting (Room) -->
          <meeting-edit
            :modalPopupId="`editMeetingModalRoom${upcomingMeeting._id}`"
            :isOneTimeMeeting="false"
            :isRecurring="upcomingMeeting.recurring"
            :roomId="room.roomID"
            :meetingId="upcomingMeeting._id"
            :meetingName="upcomingMeeting.meetingName"
            :meetingStartDateStr="upcomingMeeting.startTime"
            :meetingEndDateStr="upcomingMeeting.endTime"
            @confirmModalForm="onUpdateCard" />

          <!-- Upcoming meeting - Details (Name, date and time) -->
          <p class="sub-text--light mb-0">
            {{ upcomingMeeting.meetingName }}
          </p>
          <p class="sub-text--light mb-0">
            {{
              getUpcomingMeetingDateAndTime(
                upcomingMeeting.startTime,
                upcomingMeeting.endTime
              )
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { joinMeeting } from '@/services/api/we-meet.js'
import { weMeetMessage } from '@/services/message/we-meet.js'
import { getDateLocaleStr, getTimeLocaleStr } from '@/services/utils/date.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import MeetingAdd from '@/views/we-meet/modal-forms/MeetingAdd.vue'
import MeetingDelete from '@/views/we-meet/delete-modals/MeetingDelete.vue'
import MeetingEdit from '@/views/we-meet/modal-forms/MeetingEdit.vue'
import MeetingShare from '@/views/we-meet/modal-forms/MeetingShare.vue'
import RoomDelete from '@/views/we-meet/delete-modals/RoomDelete.vue'
import RoomSettings from '@/views/we-meet/modal-forms/RoomSettings.vue'

export default {
  name: 'Room',

  components: {
    MeetingAdd,
    MeetingDelete,
    MeetingEdit,
    MeetingShare,
    RoomDelete,
    RoomSettings
  },

  props: {
    room: {
      type: Object,
      require: true
    }
  },

  emits: ['displayStartMeetingToast', 'updateCard'],

  data() {
    return {
      displayUpcomingMeetings: true, // set to true initially to load modal popups
      weMeetMessage: weMeetMessage
    }
  },

  methods: {
    onUpdateCard() {
      this.$emit('updateCard')
    },

    getUpcomingMeetingDateAndTime(startDateStr, endDateStr) {
      return (
        getDateLocaleStr(startDateStr) +
        ', ' +
        getTimeLocaleStr(startDateStr) +
        ' - ' +
        getTimeLocaleStr(endDateStr)
      )
    },

    async onStartMeeting(roomId, meetingId) {
      try {
        showSpinner()
        const res = await joinMeeting(roomId, meetingId, false)
        window.open(res.data.WeMeetRoomURL)
        hideSpinner()
      } catch (e) {
        this.$emit('displayStartMeetingToast', e.response.data.message)
      }
    },

    getLastMeetingDateAndTime(lastMeeting) {
      if (!lastMeeting) {
        return weMeetMessage.noRoomLastMeeting
      }
      return (
        'Last Meeting on ' +
        getDateLocaleStr(lastMeeting.startTime) +
        ', ' +
        getTimeLocaleStr(lastMeeting.endTime)
      )
    },

    onViewMeetingHistory(roomId, roomName) {
      this.$router.push({
        name: 'MeetingLogs',
        params: { roomId: roomId, roomName: roomName }
      })
    },

    onToggleUpcomingMeetings() {
      this.displayUpcomingMeetings = !this.displayUpcomingMeetings
    },

    onViewRecordings(roomId, roomName) {
      this.$router.push({
        name: 'MeetingRecordings',
        params: { roomId: roomId, roomName: roomName }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
