<template>
  <div class="home-room card">
    <div class="card-body p-0">
      <div class="home-room__header mb-3 pt-3 px-3">
        <!-- Header -->
        <p class="main-heading d-inline-block mb-0 ms-2 mt-3">
          My
          <br />
          Homeroom
        </p>

        <img
          class="home-room__background"
          src="@/assets/images/home-room-bg.svg" />

        <img
          class="home-room__image"
          src="@/assets/images/home-room-illustration.svg" />

        <!-- Room settings button -->
        <img
          src="@/assets/icons/settings.svg"
          class="home-room__settings img-fluid icon float-end"
          :data-open="`roomSettingsModal${room.roomID}`" />

        <!-- Modal - Room settings -->
        <room-settings
          :modalPopupId="`roomSettingsModal${room.roomID}`"
          :roomId="room.roomID"
          :roomName="room.name"
          :welcomeMessage="room.welcome"
          :isRoomLocked="room.lockedRoom"
          :isApprovalRequired="room.approvalRequired"
          :areUsersMuted="room.muteOnStart"
          :isMeetingRecorded="room.autoStartRecording"
          @confirmModalForm="onUpdateCard" />
      </div>

      <div class="pb-3 px-3">
        <!-- New meeting bar -->
        <div class="home-room__meeting-bar mb-2 px-2 py-3">
          <!-- New meeting bar - No meeting description-->
          <div class="d-inline-block">
            <p class="room__meeting-desc sub-text mb-0">
              Start your meeting anytime
            </p>
          </div>

          <!-- New meeting bar - Start meeting button -->
          <button
            type="button"
            class="home-room__start-meeting button-secondary button--small float-end"
            @click="onStartMeeting(room.roomID)">
            Start Meeting
          </button>

          <!-- New meeting bar - Share meeting button -->
          <img
            src="@/assets/icons/share.svg"
            class="home-room__share-meeting img-fluid icon float-end"
            data-open="homeRoomShareModal" />

          <!-- Modal - Share home room -->
          <home-room-share
            modalPopupId="homeRoomShareModal"
            :meetingUrl="`https://suite.8eyond.sg/we-meet/room-invite/${room.roomID}`"
            :meetingAccessCode="room.accessCode" />
        </div>

        <!-- Last meeting date and time -->
        <p class="d-inline-block sub-text--light">
          {{ getLastMeetingDateAndTime(room.LastMeetingOccured) }}
        </p>

        <!-- Meeting history -->
        <p
          class="home-room__meeting-history d-inline-block sub-text--light text-decoration-underline mt-1 float-end"
          @click="onViewMeetingHistory(room.roomID, room.name)">
          Meeting history
        </p>

        <br />

        <!-- View recordings button -->
        <button
          type="button"
          class="home-room__view-recordings button-primary button--small"
          @click="onViewRecordings(room.roomID, room.name)">
          View Recordings
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { joinMeeting } from '@/services/api/we-meet.js'
import { weMeetMessage } from '@/services/message/we-meet.js'
import { getDateLocaleStr, getTimeLocaleStr } from '@/services/utils/date.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import HomeRoomShare from '@/views/we-meet/modal-forms/HomeRoomShare.vue'
import RoomSettings from '@/views/we-meet/modal-forms/RoomSettings.vue'

export default {
  name: 'HomeRoom',

  components: {
    HomeRoomShare,
    RoomSettings
  },

  props: {
    room: {
      type: Object,
      require: true
    }
  },

  emits: ['displayStartMeetingToast', 'updateCard'],

  data() {
    return {
      displayUpcomingMeetings: true, // set to true initially to load modal popups
      weMeetMessage: weMeetMessage
    }
  },

  methods: {
    onUpdateCard() {
      this.$emit('updateCard')
    },

    async onStartMeeting(roomId) {
      try {
        showSpinner()
        const res = await joinMeeting(roomId, '', false)
        window.open(res.data.WeMeetRoomURL)
        hideSpinner()
      } catch (e) {
        this.$emit('displayStartMeetingToast', e.response.data.message)
      }
    },

    getLastMeetingDateAndTime(lastMeeting) {
      if (!lastMeeting) {
        return weMeetMessage.noRoomLastMeeting
      }
      return (
        'Last Meeting on ' +
        getDateLocaleStr(lastMeeting.startTime) +
        ', ' +
        getTimeLocaleStr(lastMeeting.endTime)
      )
    },

    onViewMeetingHistory(roomId, roomName) {
      this.$router.push({
        name: 'MeetingLogs',
        params: { roomId: roomId, roomName: roomName }
      })
    },

    onViewRecordings(roomId, roomName) {
      this.$router.push({
        name: 'MeetingRecordings',
        params: { roomId: roomId, roomName: roomName }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
