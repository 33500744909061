import { instance } from './global.js'
import store from '@/store/index.js'

function listUsers(perPage, currentPage) {
  return instance.get('/users', {
    params: {
      perPage: perPage,
      currentPage: currentPage,
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

function createUser(
  firstName,
  lastName,
  username,
  password,
  email,
  applicationAccessFlags
) {
  return instance.post('/user/create', {
    firstName: firstName,
    lastName: lastName,
    username: username,
    password: password,
    email: email,
    WeMeetAllowance: 0,
    admin: applicationAccessFlags[0],
    rpm: applicationAccessFlags[1],
    WeLearn: applicationAccessFlags[2],
    WeMeet: applicationAccessFlags[3],
    WePlan: applicationAccessFlags[4],
    WeShare: applicationAccessFlags[5],
    beyondToken: store.getters['auth/getBeyondToken']
  })
}

function getCurrentUser() {
  return instance.get('/user/info', {
    params: {
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

function updateUser(
  userId,
  firstName,
  lastName,
  password,
  email,
  applicationAccessFlags
) {
  return instance.put(`/user/${userId}`, {
    firstName: firstName,
    lastName: lastName,
    ...(password ? { password: password } : {}),
    email: email,
    admin: applicationAccessFlags[0],
    rpm: applicationAccessFlags[1],
    WeLearn: applicationAccessFlags[2],
    WeMeet: applicationAccessFlags[3],
    WePlan: applicationAccessFlags[4],
    WeShare: applicationAccessFlags[5],
    beyondToken: store.getters['auth/getBeyondToken']
  })
}

function deleteUser(userId) {
  return instance.delete(`/user/${userId}`, {
    params: {
      beyondToken: store.getters['auth/getBeyondToken']
    }
  })
}

export { listUsers, createUser, getCurrentUser, updateUser, deleteUser }
