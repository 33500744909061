<template>
  <spinner />

  <!-- Toast - Start meeting error message -->
  <toast-popup
    toastId="startMeetingToast"
    :toastMessage="startMeetingErrorMessage" />

  <div class="we-meet-lobby container-fluid scroll-view">
    <div class="row gx-3">
      <!-- Left container -->
      <div class="col col-12 col-xl-9">
        <!-- weMEET logo -->
        <img class="mb-4" src="@/assets/images/we-meet-logo.svg" />

        <div class="row gx-4 mb-2">
          <div class="col col-12 col-lg-6">
            <!-- Time quota card -->
            <time-quota
              :usedTimeQuota="usedTimeQuota"
              :remainingTimeQuota="remainingTimeQuota" />
          </div>

          <!-- Meeting buttons -->
          <div class="col col-12 col-lg-6">
            <!-- Meeting buttons - Schedule meeting button -->
            <button
              type="button"
              class="meeting-button__schedule text-start mb-2 me-3 px-2"
              data-open="scheduleMeetingModal">
              Schedule
              <br />
              Meeting
            </button>

            <!-- Modal - Schedule meeting -->
            <meeting-schedule
              modalPopupId="scheduleMeetingModal"
              @confirmModalForm="onRefreshPage" />

            <!-- Meeting buttons - View meeting recordings button -->
            <button
              type="button"
              class="meeting-button__recording text-start mb-2 me-3 px-2"
              @click="onViewMeetingRecordings">
              View
              <br />
              Recordings
            </button>

            <!-- Meeting buttons - View meeting history button -->
            <button
              type="button"
              class="meeting-button__history text-start mb-2 px-2"
              @click="onViewMeetingHistory">
              Meeting
              <br />
              History
            </button>
          </div>
        </div>

        <!-- Rooms -->
        <div>
          <!-- Rooms - Header -->
          <p class="border-bottom heading-one mb-4 pb-1">Meeting Rooms</p>

          <!-- List room error message -->
          <p v-if="listRoomErrorMessage" class="error-message">
            {{ listRoomErrorMessage }}
          </p>

          <div class="rooms scroll-view">
            <div v-for="(room, index) in rooms" :key="room.roomID">
              <!-- Home room card -->
              <home-room
                v-if="index === 0"
                :room="room"
                @updateCard="onRefreshPage"
                @displayStartMeetingToast="onDisplayStartMeetingToast" />

              <!-- Room card -->
              <room
                v-else
                :room="room"
                @updateCard="onRefreshPage"
                @displayStartMeetingToast="onDisplayStartMeetingToast" />
            </div>
          </div>
        </div>
      </div>

      <!-- Right container (Upcoming meetings) -->
      <div class="col col-12 col-xl-3">
        <div class="upcoming-meetings__container">
          <!-- Upcoming meetings - Header -->
          <p class="heading-one">Upcoming Meetings</p>

          <div class="border-bottom mb-3 pb-3">
            <!-- Upcoming meetings - Date filter dropdown -->
            <div class="dropdown d-inline-block">
              <button
                type="button"
                class="upcoming-meetings__date-filter-dropdown dropdown-toggle button-primary button--small"
                id="upcomingMeetingsDateFilterDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                {{ upcomingMeetingDateFilter.option }}
              </button>
              <ul
                class="dropdown-menu"
                aria-labelledby="upcomingMeetingsDateFilterDropdown">
                <li v-for="filter in upcomingMeetingDateFilters" :key="filter">
                  <a
                    class="dropdown-item"
                    @click="onToggleUpcomingMeetingsDateFilter(filter)">
                    {{ filter.option }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="upcoming-meetings scroll-view scrollbar--dark">
            <!-- List upcoming meetings error message -->
            <p v-if="listUpcomingMeetingsErrorMessage" class="error-message">
              {{ listUpcomingMeetingsErrorMessage }}
            </p>

            <div
              v-for="upcomingMeeting in upcomingMeetings"
              :key="upcomingMeeting">
              <!-- Upcoming meeting card -->
              <upcoming-meeting
                :upcomingMeeting="upcomingMeeting"
                @updateCard="onRefreshPage"
                @displayStartMeetingToast="onDisplayStartMeetingToast" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'bootstrap'
import {
  listRooms,
  getUpcomingMeetingsBetweenDates
} from '@/services/api/we-meet.js'
import {
  weMeetMessage,
  getNoUpcomingMeetingMessage
} from '@/services/message/we-meet.js'
import {
  initialiseInputElements,
  cleanUpInputElements
} from '@/services/utils/form.js'
import {
  initialiseModalPopups,
  cleanUpModalPopups
} from '@/services/utils/modal.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import { upcomingMeetingDateFilters } from '@/services/utils/we-meet.js'
import HomeRoom from '@/views/we-meet/cards/HomeRoom.vue'
import MeetingSchedule from '@/views/we-meet/modal-forms/MeetingSchedule.vue'
import Room from '@/views/we-meet/cards/Room.vue'
import Spinner from '@/components/Spinner.vue'
import ToastPopup from '@/components/ToastPopup.vue'
import TimeQuota from '@/views/we-meet/cards/TimeQuota.vue'
import UpcomingMeeting from '@/views/we-meet/cards/UpcomingMeeting.vue'

export default {
  name: 'WeMeetLobby',

  components: {
    HomeRoom,
    MeetingSchedule,
    Room,
    Spinner,
    ToastPopup,
    TimeQuota,
    UpcomingMeeting
  },

  data() {
    return {
      usedTimeQuota: [0, 0, 0],
      remainingTimeQuota: [0, 0, 0],
      rooms: [],
      upcomingMeetingDateFilters: upcomingMeetingDateFilters,
      upcomingMeetingDateFilter: upcomingMeetingDateFilters[0],
      upcomingMeetings: [],
      listRoomErrorMessage: '',
      listUpcomingMeetingsErrorMessage: '',
      startMeetingErrorMessage: '',
      weMeetMessage: weMeetMessage
    }
  },

  async mounted() {
    await this.listRoomsAndMeetings()
    initialiseModalPopups()
    initialiseInputElements()
  },

  beforeUpdate() {
    // Added because data provided by listRoomsAndMeetings may change
    cleanUpModalPopups()
    cleanUpInputElements()
  },

  updated() {
    // Added because data provided by listRoomsAndMeetings may change
    initialiseModalPopups()
    initialiseInputElements()
  },

  beforeUnmount() {
    cleanUpModalPopups()
    cleanUpInputElements()
  },

  methods: {
    async listRoomsAndMeetings() {
      // Reset list room error message
      this.listRoomErrorMessage = ''

      try {
        // Call list rooms API
        showSpinner()
        const res = await listRooms()
        hideSpinner()

        // Populate weMEET time quota statistics
        this.usedTimeQuota = res.data.totalUseageOn8eyond
        this.remainingTimeQuota = res.data.totalRemainingTimeLeft

        this.rooms = res.data.WeMeetRooms

        // Check for no room
        if (!this.rooms.length) {
          this.listRoomErrorMessage = weMeetMessage.noRoom
        }
      } catch (e) {
        hideSpinner()
        this.listRoomErrorMessage = e.response.data.message
      }

      // Get upcoming meetings
      await this.onToggleUpcomingMeetingsDateFilter(
        this.upcomingMeetingDateFilter
      )
    },

    async onRefreshPage() {
      await this.listRoomsAndMeetings()
    },

    onDisplayStartMeetingToast(errorMessage) {
      this.startMeetingErrorMessage = errorMessage
      var toast = new Toast(document.getElementById('startMeetingToast'))
      toast.show()
    },

    onViewMeetingRecordings() {
      this.$router.push({ name: 'MeetingRecordings' })
    },

    onViewMeetingHistory() {
      this.$router.push({ name: 'MeetingLogs' })
    },

    async onToggleUpcomingMeetingsDateFilter(filter) {
      this.upcomingMeetingDateFilter = filter

      // Reset list upcoming meetings error message
      this.listUpcomingMeetingsErrorMessage = ''

      try {
        // Call get upcoming meetings API for selected date filter
        const upcomingRes = await getUpcomingMeetingsBetweenDates(
          this.upcomingMeetingDateFilter.startDateStr,
          this.upcomingMeetingDateFilter.endDateStr
        )
        this.upcomingMeetings = upcomingRes.data.schedules

        // Check for no upcoming meeting
        if (!this.upcomingMeetings) {
          this.listUpcomingMeetingsErrorMessage = getNoUpcomingMeetingMessage(
            this.upcomingMeetingDateFilter.option.toLowerCase()
          )
        }
      } catch (e) {
        this.listUpcomingMeetingsErrorMessage = e.response.data.message
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';

.meeting-button {
  &__schedule {
    background-image: url('../../assets/images/schedule-meeting-button-bg.svg');
  }

  &__recording {
    background-image: url('../../assets/images/view-recordings-button-bg.svg');
  }

  &__history {
    background-image: url('../../assets/images/meeting-history-button-bg.svg');
  }
}
</style>
