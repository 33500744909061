<template>
  <div>
    <nav-bar />

    <div class="page-view">
      <top-bar />

      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import TopBar from '@/components/TopBar.vue'

export default {
  name: 'Admin',

  components: {
    NavBar,
    TopBar
  }
}
</script>
