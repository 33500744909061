<template>
  <spinner />

  <!-- Heading -->
  <p class="back-nav main-heading d-inline-block mb-4" @click="onCancelAction">
    &lt; Create User
  </p>

  <div class="user-form scroll-view" id="user__form-container">
    <!-- Form -->
    <form class="form" @submit.prevent="onCreateUser($event)" novalidate>
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-lg-2">
          <!-- Form - First name input -->
          <div class="col mb-4">
            <label
              for="user__first-name-input"
              class="form-label sub-text--light">
              First name
            </label>
            <input
              type="text"
              class="form-control input"
              id="user__first-name-input"
              v-model="firstName"
              pattern="^[a-zA-Z]+( [a-zA-Z]+)*$"
              placeholder="First name"
              required />
            <div class="form__invalid-message">
              {{ userInputError.invalidFirstName }}
            </div>
          </div>

          <!-- Form - Last name input -->
          <div class="col mb-4">
            <label
              for="user__last-name-input"
              class="form-label sub-text--light">
              Last name
            </label>
            <input
              type="text"
              class="form-control input"
              id="user__last-name-input"
              v-model="lastName"
              pattern="^[a-zA-Z]+( [a-zA-Z]+)*$"
              placeholder="Last name"
              required />
            <div class="form__invalid-message">
              {{ userInputError.invalidLastName }}
            </div>
          </div>

          <!-- Form - Username input -->
          <div class="col mb-4">
            <label
              for="user__username-input"
              class="form-label sub-text--light">
              Username
            </label>
            <input
              type="text"
              class="form-control input"
              id="user__username-input"
              v-model="username"
              pattern="^[a-z]+$"
              placeholder="Username"
              required />
            <div class="form__invalid-message">
              {{ userInputError.invalidUsername }}
            </div>
          </div>

          <!-- Form - Password input -->
          <div class="col mb-4">
            <label
              for="user__password-input"
              class="form-label sub-text--light">
              Password
            </label>
            <input
              type="password"
              class="form-control input"
              id="user__password-input"
              v-model="password"
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$"
              placeholder="Password"
              required />
            <div class="form__invalid-message">
              {{ userInputError.invalidPassword }}
            </div>
          </div>

          <!-- Form - Email input -->
          <div class="col mb-4">
            <label for="user__email-input" class="form-label sub-text--light">
              Email
            </label>
            <input
              type="email"
              class="form-control input"
              id="user__email-input"
              v-model="email"
              pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
              placeholder="Email"
              required />
            <div class="form__invalid-message">
              {{ userInputError.invalidEmail }}
            </div>
          </div>
        </div>
      </div>

      <!-- Application access checkboxes -->
      <p class="sub-text--light mb-2">Grant access to</p>
      <div
        v-for="(applicationName, index) in nonDashboardApplicationNames"
        :key="applicationName">
        <div class="form-check mb-2">
          <input
            type="checkbox"
            class="form-check-input checkbox mt-0"
            :id="`user__${applicationName}-checkbox`"
            v-model="applicationAccessFlags[index]" />
          <label
            :for="`user__${applicationName}-checkbox`"
            class="form-check-label main-text ms-3">
            {{ applicationName }}
          </label>
        </div>
      </div>

      <!-- Create user error message -->
      <p v-if="createUserErrorMessage" class="error-message mt-4">
        {{ createUserErrorMessage }}
      </p>

      <!-- Create user and cancel buttons -->
      <button type="submit" class="button-primary mb-2 me-3 mt-4">
        Create User
      </button>

      <button class="button-outline" type="button" @click="onCancelAction">
        Cancel
      </button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createUser } from '@/services/api/admin.js'
import { userInputError } from '@/services/message/admin.js'
import {
  initialiseInputElements,
  cleanUpInputElements,
  areValidInputs
} from '@/services/utils/form.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import { capitaliseFirstLetterOfEachWord } from '@/services/utils/string.js'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'UserCreate',

  components: {
    Spinner
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      email: '',
      applicationAccessFlags: [],
      userInputError: userInputError,
      createUserErrorMessage: ''
    }
  },

  computed: {
    ...mapGetters({
      nonDashboardApplicationNames:
        'application/getNonDashboardApplicationNames'
    })
  },

  created() {
    this.applicationAccessFlags = this.nonDashboardApplicationNames.map(
      () => false
    )
  },

  mounted() {
    initialiseInputElements()
  },

  beforeUnmount() {
    cleanUpInputElements()
  },

  methods: {
    onCancelAction() {
      this.$router.replace({ name: 'Admin' })
    },

    async onCreateUser(event) {
      // Reset create user error message
      this.createUserErrorMessage = ''

      // Validate form
      if (!areValidInputs('user__form-container')) {
        event.stopPropagation()
        return
      }

      // Sanitise first and last name
      this.firstName = capitaliseFirstLetterOfEachWord(this.firstName)
      this.lastName = capitaliseFirstLetterOfEachWord(this.lastName)

      try {
        // Call create user API
        showSpinner()
        await createUser(
          this.firstName,
          this.lastName,
          this.username,
          this.password,
          this.email,
          this.applicationAccessFlags
        )
        hideSpinner()

        // Redirect to admin home page
        this.$router.replace({ name: 'Admin' })
      } catch (e) {
        hideSpinner()
        this.createUserErrorMessage = e.response.data.message
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_admin.scss';
</style>
