<script>
import router from '@/router'

export default {
  name: 'WePlan',

  async beforeRouteEnter() {
    window.open('https://dev.weplan.8eyond.sg/')
    router.push({ name: 'Dashboard' })
  }
}
</script>
