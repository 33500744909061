<template>
  <spinner />

  <div class="container-fluid">
    <div class="row vh-100 vw-100 m-0">
      <div class="we-meet-invite-form__col col-12 m-auto">
        <!-- weMEET Logo -->
        <img
          class="d-block mb-4 mx-auto"
          src="@/assets/images/we-meet-logo.svg" />

        <div
          class="we-meet-invite-form__container p-5"
          id="we-meet-invite-form__container">
          <!-- Meeting details -->
          <div v-show="hasMeetingDetails" class="mb-3">
            <p class="main-text text-center mx-auto">
              Join {{ meetingHostName }}'s Meeting
            </p>
            <hr class="we-meet-invite-form__divider my-4" />
            <p class="heading-one text-center mb-1">{{ meetingName }}</p>
            <p class="main-text text-center mb-0">{{ meetingDateAndTime }}</p>
          </div>

          <!-- Form -->
          <form class="form" @submit.prevent="onJoinMeeting($event)" novalidate>
            <!-- Form - Display name input -->
            <div class="mb-4">
              <label
                for="we-meet-invite-form__display-name-input"
                class="form-label sub-text--light">
                Display Name
              </label>
              <input
                type="text"
                class="form-control input"
                id="we-meet-invite-form__display-name-input"
                v-model="displayName"
                pattern="^[a-zA-Z]+( [a-zA-Z]+)*$"
                placeholder="Enter your Display Name"
                required />
              <div class="form__invalid-message">
                {{ inviteMeetingError.invalidDisplayName }}
              </div>
            </div>

            <!-- Form - Join meeting button -->
            <div class="we-meet-invite-form__join-meeting-container">
              <button type="submit" class="input-group button-primary">
                Join Meeting
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store/index.js'
import { getInviteDetails, joinMeetingAsGuest } from '@/services/api/we-meet.js'
import { inviteMeetingError } from '@/services/message/we-meet.js'
import { getDateLocaleStr, getTimeLocaleStr } from '@/services/utils/date.js'
import {
  initialiseInputElements,
  cleanUpInputElements,
  areValidInputs
} from '@/services/utils/form.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'MeetingInvite',

  components: {
    Spinner
  },

  props: {
    roomId: {
      type: String,
      require: true
    }
  },

  async beforeRouteEnter(to) {
    const loginFlag = store.getters['auth/getLoginFlag']
    const userId = store.getters['auth/getUserId']

    // Guest is logged in and has user ID
    if (loginFlag && userId) {
      const username = store.getters['user/getUsername']

      try {
        // Call join meeting as guest API
        const res = await joinMeetingAsGuest(
          to.params.roomId,
          userId,
          username,
          ''
        )

        // Open meeting URL
        window.location.replace(res.data.WeMeetRoomURL)
      } catch (e) {
        const statusCode = e.response.status
        if (statusCode === 406) {
          router.push({
            name: 'MeetingLobby',
            params: {
              roomId: to.params.roomId,
              userId: userId,
              displayName: username,
              hasMeetingStarted: false
            }
          })
        } else if (statusCode === 403) {
          router.push({
            name: 'MeetingLobby',
            params: {
              roomId: to.params.roomId,
              userId: userId,
              displayName: username,
              isAccessCodeRequired: true
            }
          })
        } else {
          router.push({
            name: 'MeetingLobby',
            params: {
              roomId: to.params.roomId,
              userId: userId,
              displayName: username,
              hasOtherErrors: true
            }
          })
        }
      }
    }
  },

  data() {
    return {
      hasMeetingDetails: false,
      meetingHostName: '',
      meetingName: '',
      meetingDateAndTime: '',
      displayName: '',
      inviteMeetingError: inviteMeetingError
    }
  },

  async mounted() {
    initialiseInputElements()

    try {
      // Call get meeting invite details API
      showSpinner()
      const res = await getInviteDetails(this.roomId, '')
      hideSpinner()
      this.meetingHostName = res.data.host
      this.meetingName = res.data.meetingName
      this.meetingDateAndTime =
        getDateLocaleStr(res.data.startTime) +
        ', ' +
        getTimeLocaleStr(res.data.startTime) +
        ' - ' +
        getTimeLocaleStr(res.data.endTime)
      if (this.meetingHostName && this.meetingName && this.meetingDateAndTime) {
        this.hasMeetingDetails = true // display meeting details
      }
    } catch (e) {
      hideSpinner()
      this.hasMeetingDetails = false
    }
  },

  beforeUnmount() {
    cleanUpInputElements()
  },

  methods: {
    // Guest is not logged in
    async onJoinMeeting(event) {
      // Validate form
      if (!areValidInputs('we-meet-invite-form__container')) {
        event.stopPropagation()
        return
      }

      try {
        // Call join meeting as guest API
        showSpinner()
        const res = await joinMeetingAsGuest(
          this.roomId,
          '',
          this.displayName,
          ''
        )
        hideSpinner()

        // Open meeting URL
        window.open(res.data.WeMeetRoomURL, '_self')
      } catch (e) {
        hideSpinner()
        const statusCode = e.response.status
        if (statusCode == 406) {
          this.$router.push({
            name: 'MeetingLobby',
            params: {
              roomId: this.roomId,
              displayName: this.displayName,
              meetingHostName: this.meetingHostName,
              meetingName: this.meetingName,
              meetingDateAndTime: this.meetingDateAndTime,
              hasMeetingStarted: false
            }
          })
        } else if (statusCode === 403) {
          this.$router.push({
            name: 'MeetingLobby',
            params: {
              roomId: this.roomId,
              displayName: this.displayName,
              meetingHostName: this.meetingHostName,
              meetingName: this.meetingName,
              meetingDateAndTime: this.meetingDateAndTime,
              isAccessCodeRequired: true
            }
          })
        } else {
          this.$router.push({
            name: 'MeetingLobby',
            params: {
              roomId: this.roomId,
              displayName: this.displayName,
              meetingHostName: this.meetingHostName,
              meetingName: this.meetingName,
              meetingDateAndTime: this.meetingDateAndTime,
              hasOtherErrors: true
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
