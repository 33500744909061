<template>
  <modal-form :modalPopupId="modalPopupId" heading="Share Meeting">
    <template #form>
      <!-- Meeting share URL disabled input  -->
      <div class="input-container mb-4">
        <label
          for="we-meet-form__meeting-share-url-input"
          class="form-label sub-text--light">
          Meeting Share URL
        </label>
        <img
          src="@/assets/icons/copy-link.svg"
          class="input-icon img-fluid icon d-inline-block"
          @click="onCopyMeetingInfo(meetingUrlStr)" />
        <input
          type="text"
          class="form-control input text-truncate"
          id="we-meet-form__meeting-share-url-input"
          v-model="meetingUrlStr"
          disabled />
      </div>

      <!-- Meeting access code disabled input  -->
      <div class="input-container mb-4">
        <label
          for="we-meet-form__meeting-access-code-input"
          class="form-label sub-text--light">
          Meeting Access Code
        </label>
        <img
          src="@/assets/icons/copy.svg"
          class="input-icon img-fluid icon d-inline-block"
          @click="onCopyMeetingInfo(meetingAccessCodeStr)" />
        <input
          type="text"
          class="form-control input text-truncate"
          id="we-meet-form__meeting-access-code-input"
          v-model="meetingAccessCodeStr"
          disabled />
      </div>

      <div class="py-3">
        <!-- Copy share info button -->
        <button
          type="button"
          class="we-meet-form__copy-share-info button-outline button--small"
          @click="onShareMeetingInfo">
          <img
            src="@/assets/icons/copy.svg"
            class="img-fluid icon d-inline-block me-1" />
          Copy share info
        </button>
      </div>

      <!-- Toast - Copy meeting info -->
      <toast-popup
        toastId="copyMeetingInfoToast"
        :toastMessage="toastMessage.copied" />
    </template>
  </modal-form>
</template>

<script>
import { Toast } from 'bootstrap'
import { toastMessage } from '@/services/message/general.js'
import ModalForm from '@/components/ModalForm.vue'
import ToastPopup from '@/components/ToastPopup.vue'

export default {
  name: 'HomeRoomShare',

  components: {
    ModalForm,
    ToastPopup
  },

  props: {
    modalPopupId: {
      type: String,
      require: true
    },

    meetingUrl: {
      type: String
    },

    meetingAccessCode: {
      type: String
    }
  },

  data() {
    return {
      meetingUrlStr: this.meetingUrl,
      meetingAccessCodeStr: this.meetingAccessCode,
      toastMessage: toastMessage
    }
  },

  methods: {
    onCopyMeetingInfo(str) {
      navigator.clipboard.writeText(str)
      var toast = new Toast(
        document
          .getElementById(this.modalPopupId)
          .querySelector('#copyMeetingInfoToast')
      )
      toast.show()
    },

    onShareMeetingInfo() {
      this.onCopyMeetingInfo(
        'Meeting URL: ' +
          this.meetingUrlStr +
          '\nMeeting Access Code: ' +
          this.meetingAccessCodeStr
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
