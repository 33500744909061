const state = () => ({
  username: '',
  firstName: '',
  lastName: ''
})

const getters = {
  getUsername: (state) => state.username,

  getFirstName: (state) => state.firstName,

  getLastName: (state) => state.lastName
}

const mutations = {
  setUsername(state, username) {
    state.username = username
  },

  setFirstName(state, firstName) {
    state.firstName = firstName
  },

  setLastName(state, lastName) {
    state.lastName = lastName
  },

  clearUserStore(state) {
    state.username = ''
    state.firstName = ''
    state.lastName = ''
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
