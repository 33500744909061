<template>
  <div id="spinner" class="spinner-overlay">
    <div class="spinner-container">
      <div class="spinner-border text-light" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spinner'
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/components/_spinner.scss';
</style>
