<template>
  <modal-form
    :modalPopupId="modalPopupId"
    heading="Add Meeting"
    @closeModalForm="onCloseModalForm">
    <template #form>
      <form
        class="form"
        @keypress.enter.prevent
        @keyup.enter.prevent
        @submit.prevent="onAddMeeting($event)"
        novalidate>
        <div class="container-fluid">
          <div class="row row-cols-1 row-cols-lg-2">
            <!-- Meeting name input -->
            <div class="row mb-4">
              <div class="col">
                <label
                  for="we-meet-form__meeting-name-input"
                  class="form-label sub-text--light">
                  Meeting Name
                </label>
                <input
                  type="text"
                  class="form-control input"
                  id="we-meet-form__meeting-name-input"
                  v-model="meetingName"
                  required />
                <div class="form__invalid-message">
                  {{ meetingInputError.emptyMeetingName }}
                </div>
              </div>
            </div>

            <!-- Set recurring switch and select menu -->
            <div class="col mb-4">
              <div class="form-check form-switch mb-2">
                <input
                  type="checkbox"
                  role="switch"
                  class="form-check-input"
                  id="we-meet-form__recurring-switch"
                  v-model="isRecurring" />
                <label
                  class="form-check-label"
                  :class="{
                    'sub-text': isRecurring,
                    'sub-text--light': !isRecurring
                  }"
                  for="we-meet-form__recurring-switch">
                  Set Recurring
                </label>
              </div>

              <select
                class="form-select select--dark"
                id="we-meet-form__recurring-value"
                v-model="recurring"
                :disabled="!isRecurring">
                <option
                  v-for="option in recurringOptions"
                  :value="option"
                  :key="option">
                  {{ option }}
                </option>
              </select>
              <div class="form__invalid-message">
                {{ meetingInputError.emptyRecurringValue }}
              </div>
            </div>

            <!-- Meeting start date and time inputs -->
            <div class="col mb-2">
              <label
                for="we-meet-form__start-date-input"
                class="form-label sub-text--light">
                Meeting Start
              </label>

              <div class="row">
                <div class="col-12 col-sm-6 mb-2">
                  <input
                    type="date"
                    class="form-control input--dark"
                    id="we-meet-form__start-date-input"
                    v-model="startDate"
                    required />
                  <div class="form__invalid-message">
                    {{ meetingInputError.emptyDate }}
                  </div>
                </div>

                <div class="col-12 col-sm-6 mb-2">
                  <input
                    type="time"
                    class="form-control input--dark"
                    id="we-meet-form__start-time-input"
                    v-model="startTime"
                    required />
                  <div class="form__invalid-message">
                    {{ meetingInputError.emptyTime }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Meeting end date and time inputs -->
            <div class="col mb-2">
              <label
                for="we-meet-form__end-date-input"
                class="form-label sub-text--light">
                Meeting End
              </label>

              <div class="row">
                <div class="col-12 col-sm-6 mb-2">
                  <input
                    type="date"
                    class="form-control input--dark"
                    id="we-meet-form__end-date-input"
                    v-model="endDate"
                    required />
                  <div class="form__invalid-message">
                    {{ meetingInputError.emptyDate }}
                  </div>
                </div>

                <div class="col-12 col-sm-6 mb-2">
                  <input
                    type="time"
                    class="form-control input--dark"
                    id="we-meet-form__end-time-input"
                    v-model="endTime"
                    aria-label="Meeting end time"
                    required />
                  <div class="form__invalid-message">
                    {{ meetingInputError.emptyTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Start and end datetime error message -->
          <p v-if="dateAndTimeErrorMessage" class="error-message">
            {{ dateAndTimeErrorMessage }}
          </p>

          <!-- Add meeting error message -->
          <p v-if="addMeetingErrorMessage" class="error-message mt-2">
            {{ addMeetingErrorMessage }}
          </p>
        </div>

        <!-- Confirm and cancel buttons -->
        <div class="mb-2 mt-4">
          <button type="submit" class="button-primary mb-2 me-3">
            Confirm
          </button>

          <button
            type="button"
            class="button-outline"
            :data-close="modalPopupId"
            @click="onCloseModalForm">
            Cancel
          </button>
        </div>
      </form>
    </template>
  </modal-form>
</template>

<script>
import { scheduleMeetingUnderRoom } from '@/services/api/we-meet.js'
import { meetingInputError } from '@/services/message/we-meet.js'
import {
  getIsoStr,
  getCurrentHtmlDate,
  getCurrentHtmlTime
} from '@/services/utils/date.js'
import {
  handleFormElementValidity,
  areValidInputs,
  resetInputsValidation
} from '@/services/utils/form.js'
import { closeModalPopup } from '@/services/utils/modal.js'
import {
  recurringOptions,
  getRecurringOptionsEncoding
} from '@/services/utils/select-menu.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import { validateStartAndEndDateAndTime } from '@/services/utils/we-meet.js'
import ModalForm from '@/components/ModalForm.vue'

export default {
  name: 'MeetingAdd',

  components: {
    ModalForm
  },

  props: {
    modalPopupId: {
      type: String,
      require: true
    },

    roomId: {
      type: String
    }
  },

  emits: ['confirmModalForm'],

  data() {
    return {
      meetingName: '',
      isRecurring: false,
      recurring: '',
      recurringOptions: recurringOptions,
      startDate: getCurrentHtmlDate(),
      startTime: getCurrentHtmlTime(),
      endDate: getCurrentHtmlDate(),
      endTime: '',
      dateAndTimeErrorMessage: '',
      addMeetingErrorMessage: '',
      meetingInputError: meetingInputError
    }
  },

  watch: {
    isRecurring: {
      handler(newBool) {
        if (!newBool) {
          // Reset recurring value if isRecurring is false
          this.recurring = ''
        }
      }
    },

    startDate: {
      handler(newDate) {
        this.endDate = newDate
        this.onValidateStartAndEndDateAndTime()
      }
    },

    startTime() {
      this.onValidateStartAndEndDateAndTime()
    },

    endDate: {
      handler(newDate) {
        this.startDate = newDate
        this.onValidateStartAndEndDateAndTime()
      }
    },

    endTime() {
      this.onValidateStartAndEndDateAndTime()
    }
  },

  methods: {
    onValidateStartAndEndDateAndTime() {
      this.dateAndTimeErrorMessage = validateStartAndEndDateAndTime(
        this.modalPopupId,
        this.startDate,
        this.startTime,
        this.endDate,
        this.endTime
      )
    },

    async onAddMeeting(event) {
      // Reset add meeting error message
      this.addMeetingErrorMessage = ''

      // Validate recurring select menu
      const recurringSelectElement = document.getElementById(
        'we-meet-form__recurring-value'
      )
      recurringSelectElement.setCustomValidity('')
      if (this.isRecurring && !this.recurring) {
        recurringSelectElement.setCustomValidity('error')
      }
      handleFormElementValidity(recurringSelectElement)

      // Validate form
      if (!areValidInputs(this.modalPopupId)) {
        event.stopPropagation()
        return
      }

      try {
        // Call schedule meeting under existing room API
        showSpinner()
        await scheduleMeetingUnderRoom(
          this.meetingName,
          [],
          false,
          this.isRecurring,
          getRecurringOptionsEncoding(this.recurring),
          getIsoStr(this.startDate, this.startTime),
          getIsoStr(this.endDate, this.endTime),
          this.roomId
        )
        hideSpinner()

        // Close modal, remove validated class from form
        // and emit event to WeMeetLobby component
        this.onCloseModalForm()
        closeModalPopup(this.modalPopupId)
        document
          .getElementById(this.modalPopupId)
          .querySelector('.form')
          .classList.remove('validated')
        this.$emit('confirmModalForm')
      } catch (e) {
        hideSpinner()
        this.addMeetingErrorMessage = e.response.data.message
      }
    },

    onCloseModalForm() {
      // Reset form when user submits form
      // Reset form when user clicks on cross icon or "Cancel" button
      resetInputsValidation(this.modalPopupId)
      this.meetingName = ''
      this.isRecurring = false
      this.recurring = ''
      this.startDate = getCurrentHtmlDate()
      this.startTime = getCurrentHtmlTime()
      this.endDate = getCurrentHtmlDate()
      this.endTime = ''
      this.dateAndTimeErrorMessage = ''
      this.addMeetingErrorMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/assets/scss/views/_we-meet.scss';
</style>
