// Toggle estimated forecast between graph and table
const forecastViews = [
  {
    view: 'Graph',
    icon: 'graph.svg',
    whiteIcon: 'graph-white.svg'
  },
  {
    view: 'Table',
    icon: 'table.svg',
    whiteIcon: 'table-white.svg'
  }
]

// Toggle detailed tasks between calendar and table
const tasksViews = [
  {
    view: 'Calendar',
    icon: 'calendar.svg',
    whiteIcon: 'calendar-white.svg'
  },
  {
    view: 'Table',
    icon: 'table.svg',
    whiteIcon: 'table-white.svg'
  }
]

export { forecastViews, tasksViews }
