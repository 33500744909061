<template>
  <modal-view
    :modalPopupId="modalPopupId"
    image="trash-bin.svg"
    mainHeading="Confirm Delete?"
    :subHeading="getSubHeading()"
    :errorMessage="deleteMeetingErrorMessage" />
</template>

<script>
import { deleteMeeting } from '@/services/api/we-meet.js'
import { showSpinner, hideSpinner } from '@/services/utils/spinner.js'
import ModalView from '@/components/ModalView.vue'

export default {
  name: 'MeetingDelete',

  components: {
    ModalView
  },

  props: {
    modalPopupId: {
      type: String,
      require: true
    },

    meetingId: {
      type: String,
      require: true
    },

    meetingName: {
      type: String,
      require: true
    },

    isDeleteRecurring: {
      type: Boolean,
      require: false,
      default: true
    }
  },

  emits: ['confirmDelete'],

  data() {
    return {
      deleteMeetingErrorMessage: ''
    }
  },

  mounted() {
    document
      .getElementById(this.modalPopupId)
      .querySelector('.modal-view__confirm')
      .addEventListener('click', () => {
        this.onDeleteMeeting()
      })
  },

  beforeUnmount() {
    document
      .getElementById(this.modalPopupId)
      .querySelector('.modal-view__confirm')
      .removeEventListener('click', () => {
        this.onDeleteMeeting()
      })
  },

  methods: {
    getSubHeading() {
      return this.isDeleteRecurring
        ? 'You are deleting ' + this.meetingName + ' and all future meetings.'
        : 'You are deleting ' + this.meetingName + '.'
    },

    async onDeleteMeeting() {
      try {
        // Call delete meeting API
        showSpinner()
        await deleteMeeting(this.meetingId, this.isDeleteRecurring)
        hideSpinner()

        // Emit event to WeMeetLobby component
        this.$emit('confirmDelete')
      } catch (e) {
        hideSpinner()
        this.deleteMeetingErrorMessage = e.response.data.message
      }
    }
  }
}
</script>
